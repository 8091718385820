<template>
    <Modal @close="closeModal">
        <template v-slot:title>{{ t(title, {}, { locale: $store.getters.getLang }) }}</template>
        <template v-slot:body>
            <form>
                <div class="form-group row my-1">
                    <ModalInput
                            v-if="!bpId"
                            v-model="bPlace.name"
                            v-bind:placeholder="'Enter Name'"
                            v-bind:label="'Name'"
                            v-bind:id="'name'"
                            v-bind:type="'text'"
                    >
                        <template v-slot:error>
                            <ModalError v-if="errors.name" v-bind:error="errors.name"/>
                        </template>
                    </ModalInput>
                    <ModalInput
                            v-else
                            v-model="bPlace.newName"
                            v-bind:placeholder="'Enter Name'"
                            v-bind:label="'Name'"
                            v-bind:id="'name'"
                            v-bind:type="'text'"
                    >
                        <template v-slot:error>
                            <ModalError v-if="errors.name" v-bind:error="errors.name"/>
                        </template>
                    </ModalInput>
                </div>

                <div class="form-group row my-1">
                    <label class="col-sm-3 col-form-label"></label>
                    <div class="col-sm-9">
                        <input
                                v-if="!bpId"
                                type="submit"
                                class="mt-3 py-2 submit_button font_detail float-end"
                                @click.prevent="addBoardingPlace"
                        />
                        <input
                                v-else
                                type="submit"
                                class="mt-3 py-2 submit_button font_detail float-end"
                                @click.prevent="updateBoardingPlace"
                        />
                    </div>
                </div>
            </form>
        </template>
    </Modal>
</template>

<script>
import dropdownVariables from "@/modules/dropdownVariables";
import useInputValidation from "@/modules/useInputValidations";
import {inject, onBeforeMount, ref, watch} from "vue";
import {reactive} from "@vue/reactivity";

import store from "../../../../../../store";
import { useI18n } from "vue-i18n";
export default {
    name: "BoardingPlaceAddUpdateModal",
    props: {
        boardingPlace: String,
        route: String,
        boardingPlaceName: String,
    },
    setup(props, {emit}) {
        let {requiredValidation, errors} = useInputValidation();
        let bPlace = reactive({
            routeId: "",
            name: "",
            previousName: "",
            newName: "",
        });
        const bpId = ref(props.boardingPlace);
        const sId = ref(props.route);
        const bpName = ref(props.boardingPlaceName);

        const status = dropdownVariables.status;
        const title = ref("Add Boarding Place");
        const showLoader = inject("showLoader");
        const hideLoader = inject("hideLoader");
const { t, locale } = useI18n();
        watch(
            () => bPlace.name,
            () => {
                requiredValidation("name", bPlace.name);
            }
        );
        watch(
            () => bPlace.newName,
            () => {
                requiredValidation("name", bPlace.newName);
            }
        );

        const validate = () => {
            Object.keys(errors).forEach(function (key) {
                errors[key] = "";
            });
            if (!bpId.value) {

                requiredValidation("name", bPlace.name);
            } else {
                requiredValidation("name", bPlace.newName);

            }
            return !(
                Object.values(errors).length <= 0 ||
                !Object.values(errors).every((x) => x === null || x === "")
            );
        };
        const closeModal = () => {
            emit("update:modelValue", false);
        };
        const addBoardingPlace = () => {
            if (validate()) {
                showLoader();
                store
                    .dispatch("addOwnerServicesBoardingPlace", bPlace)
                    .then(() => {
                        hideLoader();
                        emit("getAllBoardingPlaces");
                        closeModal();
                    })
                    .catch((error) => {
                        hideLoader();
                        if (error.status === 401) {
                            addBoardingPlace();
                        }
                    });
            }
        };
        const updateBoardingPlace = () => {
            if (validate()) {
                showLoader();
                // bPlace.newName = bPlace.name;

                store
                    .dispatch("updateOwnerServicesBoardingPlace", bPlace)
                    .then(() => {
                        hideLoader();
                        emit("getAllBoardingPlaces");
                        closeModal();
                    })
                    .catch((error) => {
                        hideLoader();
                        if (error.status === 401) {
                            updateBoardingPlace();
                        }
                    });
            }
        };

        onBeforeMount(() => {
            Object.keys(errors).forEach(function (key) {
                delete errors[key];
            });

            bPlace.routeId = sId.value;

            if (bpId.value !== "") {
                title.value = "Update Boarding Place";
                bPlace.previousName = bpName.value;
                bPlace.newName = bpName.value;
            }
        });

        return {
            t,locale,
            bpId,
            bPlace,
            errors,
            status,
            title,
            addBoardingPlace,
            updateBoardingPlace,
            closeModal,
        };
    },
};
</script>

<style scoped>
</style>