<template>
  <li class="list-group-item">
    <div class="row my-2">
      <!-- <div class="col-md-1">
        <Dropdown
          v-model="seatDesign.classSelect"
          v-bind:id="''"
          v-bind:label="''"
          v-bind:defaultOption="'Select Class'"
          v-bind:defaultValue="''"
          v-bind:dropdown="seatClassMain"
          v-bind:hideLabel="''"
        />
      </div> -->
    </div>
    <div class="row my-2">
      <span v-if="error" class="text-danger err_msg">* {{ error }}</span>
      <div class="col-md-1">
        <Input
          v-model="seatDesign.prefix"
          v-bind:placeholder="'Prefix'"
          v-bind:id="'Prefix'"
          v-bind:type="'text'"
        />
        <!--      <Error v-if="errors.prefix" v-bind:error="errors.prefix"/>-->
      </div>
      <div class="col-md-1">
        <Dropdown
          v-if="seatDesign.classSelect === 'Mixed'"
          v-model="seatDesign.seat_1_class"
          v-bind:id="''"
          v-bind:label="''"
          v-bind:defaultOption="'Select Class'"
          v-bind:defaultValue="''"
          v-bind:dropdown="seatClass"
          v-bind:hideLabel="''"
        />
      </div>
      <div class="col-md-1">
        <Dropdown
          v-if="seatDesign.classSelect === 'Mixed'"
          v-model="seatDesign.seat_2_class"
          v-bind:id="''"
          v-bind:label="''"
          v-bind:defaultOption="'Select Class'"
          v-bind:defaultValue="''"
          v-bind:dropdown="seatClass"
          v-bind:hideLabel="''"
        />
      </div>
      <div class="col-md-1">
        <Dropdown
          v-if="seatDesign.classSelect === 'Mixed'"
          v-model="seatDesign.seat_3_class"
          v-bind:id="''"
          v-bind:label="''"
          v-bind:defaultOption="'Select Class'"
          v-bind:defaultValue="''"
          v-bind:dropdown="seatClass"
          v-bind:hideLabel="''"
        />
      </div>

      <div class="col-md-1">
        <Dropdown
          v-if="seatDesign.classSelect === 'Mixed'"
          v-model="seatDesign.seat_4_class"
          v-bind:id="''"
          v-bind:label="''"
          v-bind:defaultOption="'Select Class'"
          v-bind:defaultValue="''"
          v-bind:dropdown="seatClass"
          v-bind:hideLabel="''"
        />
      </div>

      <div class="col-md-1">
        <Dropdown
          v-if="seatDesign.classSelect === 'Mixed'"
          v-model="seatDesign.seat_5_class"
          v-bind:id="''"
          v-bind:label="''"
          v-bind:defaultOption="'Select Class'"
          v-bind:defaultValue="''"
          v-bind:dropdown="seatClass"
          v-bind:hideLabel="''"
        />
      </div>
      <div class="col-md-1">
        <Input
          v-model="seatDesign.suffix"
          v-bind:placeholder="'Suffix'"
          v-bind:id="'Suffix'"
          v-bind:type="'text'"
        />
        <!--      <Error v-if="errors.prefix" v-bind:error="errors.prefix"/>-->
      </div>
      <div class="col-md-1">
        <button
          :id="'add' + spId"
          class="add_button mt-1"
          @click="addSeatPlan()"
        >
          <i class="fas fa-plus"></i>
        </button>
      </div>
    </div>
    <div class="row my-2">
      <span v-if="error" class="text-danger err_msg">* {{ error }}</span>
      <div class="col-md-1"></div>
      <div class="col-md-1">
        <Input
          v-if="
            seatDesign.seat_1_class !== '' || seatDesign.classSelect !== 'Mixed'
          "
          v-model="seatDesign.seat_1"
          v-bind:placeholder="'Seat-1'"
          v-bind:id="'Seat-1'"
          v-bind:type="'text'"
        />
        <!--      <Error v-if="errors.prefix" v-bind:error="errors.prefix"/>-->
      </div>
      <div class="col-md-1">
        <Input
          v-if="
            seatDesign.seat_2_class !== '' || seatDesign.classSelect !== 'Mixed'
          "
          v-model="seatDesign.seat_2"
          v-bind:placeholder="'Seat-2'"
          v-bind:id="'Seat-2'"
          v-bind:type="'text'"
        />
      </div>

      <div class="col-md-1">
        <Input
          v-if="
            seatDesign.seat_3_class !== '' || seatDesign.classSelect !== 'Mixed'
          "
          v-model="seatDesign.seat_3"
          v-bind:placeholder="'Seat-3'"
          v-bind:id="'Seat-3'"
          v-bind:type="'text'"
        />
      </div>

      <div class="col-md-1">
        <Input
          v-if="
            seatDesign.seat_4_class !== '' || seatDesign.classSelect !== 'Mixed'
          "
          v-model="seatDesign.seat_4"
          v-bind:placeholder="'Seat-4'"
          v-bind:id="'Seat-4'"
          v-bind:type="'text'"
        />
        <!-- <div v-if="errorId === spId">
          <Error v-if="errors.to" v-bind:error="errors.to" />
        </div> -->
      </div>
      <div class="col-md-1">
        <Input
          v-if="
            seatDesign.seat_5_class !== '' || seatDesign.classSelect !== 'Mixed'
          "
          v-model="seatDesign.seat_5"
          v-bind:placeholder="'Seat-5'"
          v-bind:id="'Seat-5'"
          v-bind:type="'text'"
        />
        <!--      <Error v-if="errors.suffix" v-bind:error="errors.suffix"/>-->
      </div>
      <div class="col-md-1"></div>
      <div class="col-md-1"></div>
      <div class="col-md-4"></div>
    </div>
  </li>
</template>

<script>
import { reactive } from "@vue/reactivity";
import Input from "@/components/layouts/Inputs/Input";
// import Error from "@/components/layouts/Inputs/Error";
import useInputValidation from "@/modules/useInputValidations";
import { inject, ref, watch, onBeforeMount } from "vue";
import store from "@/store";

export default {
  name: "AddSeatRow",
  props: {
    seatPlanId: String,
    company: String,
    seatClassProps: String,
  },
  components: { Input },
  setup(props, { emit }) {
    let spId = ref(props.seatPlanId);
    let companyId = ref(props.company);
    let seatClassProps = ref(props.seatClassProps);
    let seatClassMain = reactive({
      Mixed: "Mixed",
    });
    let seatClass = reactive({});
    let seatDesign = reactive({
      prefix: "",
      suffix: "",
      seat_1: "",
      seat_2: "",
      seat_3: "",
      seat_4: "",
      seat_5: "",
      seat_1_class: "",
      seat_2_class: "",
      seat_3_class: "",
      seat_4_class: "",
      seat_5_class: "",
      customSeats: "",
      seatClass: "",
      classSelect: "",
    });
    let row = reactive({
      id: spId.value,
      companyId: companyId.value,
      seatRow: [],
    });

    let errorId = ref("");
    let error = ref("");
    let { errors } = useInputValidation();
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    watch(
      () => seatDesign.classSelect,
      (value) => {
        if (value !== "Mixed") {
          seatDesign.seat_1_class = value;
          seatDesign.seat_2_class = value;
          seatDesign.seat_3_class = value;
          seatDesign.seat_4_class = value;
          seatDesign.seat_5_class = value;
        }
      }
    );
    watch(
      () => seatDesign.seat_1_class,
      (value) => {
        seatDesign.seat_1_class = value;
      }
    );
    watch(
      () => seatDesign.seat_2_class,
      (value) => {
        seatDesign.seat_2_class = value;
      }
    );
    watch(
      () => seatDesign.seat_3_class,
      (value) => {
        seatDesign.seat_3_class = value;
      }
    );
    watch(
      () => seatDesign.seat_4_class,
      (value) => {
        seatDesign.seat_4_class = value;
      }
    );
    watch(
      () => seatDesign.seat_5_class,
      (value) => {
        seatDesign.seat_5_class = value;
      }
    );

    const clear = () => {
      row.seatRow = "";
      seatDesign.customSeats = "";
      seatDesign.prefix = "";
      seatDesign.suffix = "";
      seatDesign.seat_1 = "";
      seatDesign.seat_2 = "";
      seatDesign.seat_3 = "";
      seatDesign.seat_4 = "";
      seatDesign.seat_5 = "";
    };

    const seatDesignArray = () => {
      const seatDesignArrayObject = [];
      if (seatDesign.seat_1 === "") {
        seatDesignArrayObject.push({
          seat_no: seatDesign.seat_1,
          class_id: seatDesign.seat_1_class,
        });
      } else {
        seatDesignArrayObject.push({
          seat_no: seatDesign.prefix + seatDesign.seat_1 + seatDesign.suffix,
          class_id: seatDesign.seat_1_class,
        });
      }

      if (seatDesign.seat_2 === "") {
        seatDesignArrayObject.push({
          seat_no: seatDesign.seat_2,
          class_id: seatDesign.seat_2_class,
        });
      } else {
        seatDesignArrayObject.push({
          seat_no: seatDesign.prefix + seatDesign.seat_2 + seatDesign.suffix,
          class_id: seatDesign.seat_2_class,
        });
      }

      if (seatDesign.seat_3 === "") {
        seatDesignArrayObject.push({
          seat_no: seatDesign.seat_3,
          class_id: seatDesign.seat_3_class,
        });
      } else {
        seatDesignArrayObject.push({
          seat_no: seatDesign.prefix + seatDesign.seat_3 + seatDesign.suffix,
          class_id: seatDesign.seat_3_class,
        });
      }

      if (seatDesign.seat_4 === "") {
        seatDesignArrayObject.push({
          seat_no: seatDesign.seat_4,
          class_id: seatDesign.seat_4_class,
        });
      } else {
        seatDesignArrayObject.push({
          seat_no: seatDesign.prefix + seatDesign.seat_4 + seatDesign.suffix,
          class_id: seatDesign.seat_4_class,
        });
      }

      if (seatDesign.seat_5 === "") {
        seatDesignArrayObject.push({
          seat_no: seatDesign.seat_5,
          class_id: seatDesign.seat_5_class,
        });
      } else {
        seatDesignArrayObject.push({
          seat_no: seatDesign.prefix + seatDesign.seat_5 + seatDesign.suffix,
          class_id: seatDesign.seat_5_class,
        });
      }

      return seatDesignArrayObject;
    };

    const addSeatPlan = () => {
      const seatDesignArrayObject = seatDesignArray();
      showLoader();
      row.seatRow = seatDesignArrayObject;
      store
        .dispatch("addOwnerServicesSeatRow", row)
        .then((res) => {
          seatDesign.classSelect = res.seatPlan.seatClass;
          hideLoader();
          emit("getSeatPlan");
          clear();
        })
        .catch((error) => {
          clear();
          emit("getSeatPlan");
          hideLoader();
          if (error.status === 401) {
            addSeatPlan();
          }
        });
    };

    onBeforeMount(() => {
      seatDesign.classSelect = seatClassProps.value;
      if (store.getters.getSeatClass) {
        let busClass = store.getters.getSeatClass;
        busClass.forEach((item) => {
          seatClassMain[item._id] = item.name;
          seatClass[item._id] = item.name;
        });
      }
    });

    return {
      seatDesign,
      companyId,
      spId,
      errors,
      errorId,
      error,
      seatClass,
      seatClassMain,
      addSeatPlan,
    };
  },
};
</script>

<style scoped>
.add_button {
  border: 2px solid #17a2b8;
  border-radius: 20px;
  background-color: #17a2b8;
  padding: 5px 10px;
  color: #ffffff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.add_button:hover,
.add_button:focus {
  border: 2px solid #17a2b8;
  background-color: #fff;
  color: #17a2b8;
  transition: 0.4s;
  outline: 0;
}

.block_button {
  border: 2px solid #ff0000;
  border-radius: 20px;
  background-color: #ff0000;
  padding: 5px 10px;
  color: #ffffff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.block_button:hover,
.block_button:focus {
  border: 2px solid #ff0000;
  background-color: #fff;
  color: #ff0000;
  transition: 0.4s;
  outline: 0;
}
</style>
