<template>
  <div class="container">
    <div class="d-flex justify-content-center align-items-center login_panel">
      <div class="col-lg-4 col-md-6 col-sm-12 col-10">
        <div class="login_logo my-4">
          <img
            src="@/assets/images/logo.png"
            alt=""
            class="img-responsive m-auto d-block"
          />
        </div>
        <div class="shadow login_area px-4 pt-4 pb-3">
          <h2 class="text-center">Log In</h2>
          <form @submit.prevent>
            <Input
              v-model="login.username"
              v-bind:placeholder="'01XXXXXXXXX'"
              v-bind:label="'Phone Number'"
              v-bind:id="'phone'"
              v-bind:type="'text'"
            />
            <Error v-if="errors.username" v-bind:error="errors.username" />
            <Input
              v-model="login.password"
              v-bind:placeholder="'Enter Password'"
              v-bind:label="'Password'"
              v-bind:id="'password'"
              v-bind:type="'password'"
            />
            <Error v-if="errors.password" v-bind:error="errors.password" />
            <button
              type="submit"
              class="login_btn rounded-pill text-white w-100 mt-3 py-2"
              @click.prevent="submitLogin"
            >
              LOG IN
            </button>
          </form>

          <div class="mt-3 text-center text-muted" v-if="isDevelopment">
            <strong>Development Mode</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useInputValidation from "@/modules/useInputValidations";
import { useRouter } from "vue-router";
import { reactive } from "@vue/reactivity";
import store from "@/store";
import web from "@/urls/web";
import { inject, watch, computed } from "vue";

export default {
  name: "Login",
  setup() {
    const router = useRouter();
    const {
      requiredValidation,
      passwordValidation,
      errors,
    } = useInputValidation();
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    let login = reactive({
      username: "",
      password: "",
    });

    const isDevelopment = computed(() =>
      process.env.VUE_APP_API.includes("dev") ? true : false
    );
    watch(
      () => login.username,
      () => {
        requiredValidation("username", login.username);
      }
    );
    watch(
      () => login.password,
      () => {
        passwordValidation("password", login.password);
      }
    );

    const validate = () => {
      requiredValidation("username", login.username);
      passwordValidation("password", login.password);
      return !(
        Object.values(errors).length <= 0 ||
        !Object.values(errors).every((x) => x === null || x === "")
      );
    };
    const submitLogin = () => {
      if (validate()) {
        showLoader();
        store
          .dispatch("login", login)
          .then(() => {
            hideLoader();
            if (store.getters.userType === "Owner") {
              router
                .push(web.Owner.path + "/" + web.Owner.DASHBOARD_URL.path)
                .catch(() => {});
            }  else {
              router.push(web.Default.LOGIN_URL.path).catch(() => {});
            }
          })
          .catch(() => {
            hideLoader();
          });
      }
    };

    return {
      login,
      errors,
      submitLogin,
      isDevelopment,
    };
  },
};
</script>

<style scoped></style>
