<template>
  <Modal @close="closeModal">
    <template v-slot:title>{{
      t("removeSeatClass", {}, { locale: $store.getters.getLang })
    }}</template>
    <template v-slot:body>
      {{
        t(
          "removeSeatClassConfirmMessage",
          {},
          { locale: $store.getters.getlang }
        )
      }}
      <div class="form-group row my-1">
        <label class="col-sm-3 col-form-label"></label>
        <div class="col-sm-9">
          <input
            type="submit"
            class="mt-3 py-2 submit_button float-end font_detail"
            value="Remove"
            @click.prevent="removeSeatClass"
          />
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/layouts/Modals/Modal";
import { inject, ref } from "vue";
import store from "@/store";
import { reactive } from "@vue/reactivity";
import { onBeforeMount } from "vue";
import { useI18n } from "vue-i18n";
export default {
  name: "SeatClassDeleteModal",
  components: { Modal },
  props: {
    ship: String,
    company: String,
    seatClass: String,
  },
  setup(props, { emit }) {
    let seatClassData = reactive({
      id: "",
      companyId: "",
      shipId: "",
    });
    let seatClassId = ref(props.seatClass);
    let companyId = ref(props.company);
    let shipId = ref(props.ship);
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const { t, locale } = useI18n();
    const closeModal = () => {
      emit("update:modelValue", false);
    };
    const removeSeatClass = () => {
      showLoader();
      store
        .dispatch("deleteSeatClass", seatClassData)
        .then(() => {
          hideLoader();
          closeModal();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            removeSeatClass();
          }
        });
    };

    onBeforeMount(() => {
      seatClassData.id = seatClassId.value;
      seatClassData.companyId = companyId.value;
      seatClassData.shipId = shipId.value;
    });

    return {
      t,
      locale,
      seatClassData,
      removeSeatClass,
      closeModal,
    };
  },
};
</script>

<style scoped></style>
