import SecureLS from "secure-ls";
import Vuex from 'vuex';
import { createStore } from 'vuex-extensions';
import createPersistedState from "vuex-persistedstate";
import Auth from './modules/auth';
import OwnerDashBoard from './modules/Owner/ownerDashboard';
import OwnerOwners from './modules/Owner/ownerOwners';
import OwnerReport from './modules/Owner/ownerReport';
import OwnerServices from "./modules/Owner/ownerServices";
var ls = new SecureLS({encodingType: 'base64', isCompression: false});

const store = createStore(Vuex.Store, {
    modules: {
        auth: Auth,
        ownerDashBoard: OwnerDashBoard,
        ownerReport: OwnerReport,
        ownerOwners: OwnerOwners,
        ownerServices: OwnerServices,
    },
    plugins: [createPersistedState({
        key:'intercity',
        storage: {
            getItem: (key) => ls.get(key),
            setItem: (key, value) => ls.set(key, value),
            removeItem: (key) => ls.remove(key),
        }
    })]
})

export default store;
