export default {
  "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "From Stoppage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From Stoppage"])},
  "To Stoppage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To Stoppage"])},
  "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
  "Trips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trips"])},
  "Coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coach"])},
  "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "Bangla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bangla"])},
  "Filter By": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter By"])},
  "Filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
  "--select stoppage--": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--select stoppage--"])},
  "--select coach--": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--select coach--"])},
  "sl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SL"])},
  "TRIP TIME & DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRIP TIME & DATE"])},
  "TRIP CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRIP CODE "])},
  "COACH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COACH"])},
  "COACH NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COACH NAME"])},
  "ROUTE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ROUTE"])},
  "ROUTE NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ROUTE NAME"])},
  "SEAT-PLAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEAT-PLAN"])},
  "SEATPLAN NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEATPLAN NAME"])},
  "SEAT-CLASS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEAT-CLASS"])},
  "SEATCLASS NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEATCLASS NAME"])},
  "CLASS NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CLASS NAME"])},
  "DIRECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DIRECTION"])},
  "Direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction"])},
  "STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STATUS"])},
  "ACTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACTION"])},
  "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACTIVE"])},
  "View": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
  "View Seat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Seat"])},
  "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "TICKET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKET"])},
  "Sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sold"])},
  "M": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["M"])},
  "F": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["F"])},
  "SEAT INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEAT INFORMATION"])},
  "Seat Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seat Information"])},
  "SEATS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEATS"])},
  "TOTAL SEAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOTAL SEAT"])},
  "Total Seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Seats"])},
  "FARE(TK)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FARE(TK)"])},
  "DISCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DISCOUNT"])},
  "Discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
  "PAID(TK)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAID(TK)"])},
  "Paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
  "PAID AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAID AMOUNT"])},
  "TOTAL AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOTAL AMOUNT"])},
  "PAYABLE(TK)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAYABLE(TK)"])},
  "Payable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payable"])},
  "DUE(TK)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DUE(TK)"])},
  "DUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DUE"])},
  "Dues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dues"])},
  "REFUND(TK)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REFUND(TK)"])},
  "REFUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REFUND"])},
  "Refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund"])},
  "EXTRA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EXTRA"])},
  "GOODS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GOODS"])},
  "PRINT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRINT"])},
  "Export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
  "TRIP REPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRIP REPORT"])},
  "LAST TICKET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LAST TICKET"])},
  "EXTRA TICKET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EXTRA TICKET"])},
  "GOODS TICKET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GOODS TICKET"])},
  "ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACTIONS"])},
  "UPDATE TRIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPDATE TRIP"])},
  "TRIP PASS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRIP PASS"])},
  "RESET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RESET"])},
  "REFRESH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REFRESH"])},
  "Couch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couch"])},
  "Booking Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking Information"])},
  "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "Passenger Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passenger Name"])},
  "Mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile"])},
  "Passenger Mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passenger Mobile"])},
  "Gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
  "Sex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sex"])},
  "--select gender --": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--select gender--"])},
  "--select a driver --": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--select a driver--"])},
  "--select a supervisor --": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--select a supervisor--"])},
  "Male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
  "Female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
  "NID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NID"])},
  "Passenger NID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passenger NID"])},
  "Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "Boarding place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boarding place"])},
  "---select a boarding place---": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["---select a boarding place---"])},
  "---select a stoppage---": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["---select a stoppage---"])},
  "Dropping place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropping place"])},
  "---select a dropping place---": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["---select a dropping place---"])},
  "CONFIRM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONFIRM"])},
  "Confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CANCEL"])},
  "Seat Ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seat Ticket"])},
  "Seat Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seat Amount"])},
  "Extra Ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra Ticket"])},
  "Extra Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra Amount"])},
  "Goods Ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goods Ticket"])},
  "Goods Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goods Amount"])},
  "Total Ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Ticket"])},
  "Total Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Amount"])},
  "Gross Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gross Amount"])},
  "Total Seat Ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Seat Ticket"])},
  "Total Seat Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Seat Amount"])},
  "Total Extra Ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Extra Ticket"])},
  "Total Extra Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Extra Amount"])},
  "Total Goods Ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Goods Ticket"])},
  "Total Goods Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Goods Amount "])},
  "Coach Registration Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coach Registration Number"])},
  "Driver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver"])},
  "Supervisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supervisor"])},
  "SL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SL"])},
  "Trip Code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trip Code"])},
  "From": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
  "To": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
  "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "FROM STOPPAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FROM STOPPAGE"])},
  "TO STOPPAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To STOPPAGE"])},
  "Ticket History": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket History"])},
  "Seat Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seat Type"])},
  "Go Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go Back"])},
  "GO BACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GO BACK"])},
  "STOPPAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STOPPAGE"])},
  "ISSUED BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISSUED BY"])},
  "ISSUED ON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issued On"])},
  "Issued By": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISSUED by"])},
  "COUNTER NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COUNTER NAME"])},
  "TICKET TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKET TYPE"])},
  "SEAT NUMBERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEAT NUMBERS"])},
  "SEAT CLASS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEAT CLASS"])},
  "Seat Class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seat Class"])},
  "Cancellation Cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation Cause"])},
  "Cancel reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel reason"])},
  "Select Reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Reason"])},
  "Mixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mixed"])},
  "Goods Id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goods Id"])},
  "Goods ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goods ID"])},
  "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "PNR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PNR"])},
  "PNR CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PNR CODE"])},
  "Pnr Ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pnr Ticket"])},
  "PNR_TICKET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PNR TICKET"])},
  "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAME"])},
  "REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REGISTRATION NUMBER"])},
  "SEAT_PLAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEAT PLAN"])},
  "Summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])},
  "Seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seats"])},
  "Class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class"])},
  "Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
  "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "Trip_Pass_Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure, you want to pass the trip? If so, you will not purchase any ticket!!"])},
  "GOODS_WEIGHT_IN_(KG)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GOODS WEIGHT IN (KG)"])},
  "UPDATE_TRIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPDATE TRIP"])},
  "Discount_Fare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount amount can't be greater than netfare"])},
  "UP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UP"])},
  "DOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOWN"])},
  "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select All"])},
  "ticket_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKET INFORMATION"])},
  "Trip_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trip Date"])},
  "Goods_Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goods Weight"])},
  "Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "Fare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fare"])},
  "Ticket_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket Date"])},
  "The droppingPoint field is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The droppingPoint field is required"])},
  "USER_TRIP_REPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USER TRIP REPORT"])},
  "Trip_Pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trip Pass"])},
  "Please insert fare and try again / May be, You did not Select any seat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please insert fare and try again / May be, You did not Select any seat"])},
  "Owners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owners"])},
  "Services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
  "Seat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seat"])},
  "Goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goods"])},
  "NON AC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NON AC"])},
  "Non AC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non AC"])},
  "AC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AC"])},
  "Extra_Seat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra Seat"])},
  "Chalan Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chalan Report"])},
  "Coach Wise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coach Wise"])},
  "Counter Wise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Counter Wise"])},
  "Agent Wise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent Wise"])},
  "Print Count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print Count"])},
  "SEAT_TICKET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEAT TICKET"])},
  "SEAT_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEAT AMOUNT"])},
  "GOODS_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GOODS AMOUNT"])},
  "EXTRA_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EXTRA AMOUNT"])},
  "DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DETAILS"])},
  "Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
  "Taka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taka"])},
  "Serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serial"])},
  "Vehicle_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Number"])},
  "Seat_Ticket_Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seat Ticket Details"])},
  "Goods_Ticket_Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goods Ticket Details"])},
  "Passenger_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passenger Info"])},
  "Boarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boarding"])},
  "Dropping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropping"])},
  "Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
  "Final_Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final Amount"])},
  "SubTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Total"])},
  "Free_Seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Seats"])},
  "Seat-and-Fare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seat & Fare"])},
  "Counter_Wise_Seat_Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Counter Wise Seat Report"])},
  "Counter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Counter"])},
  "User_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Name"])},
  "User_Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Details"])},
  "Sold_Seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sold Seats"])},
  "Receive_Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive Amount"])},
  "Received_Amount_Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive Amount Total"])},
  "Commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission"])},
  "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "Extra_Ticket_Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra Ticket Report"])},
  "Customer_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer info"])},
  "Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])},
  "Charge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charge"])},
  "Balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
  "Signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature"])},
  "Counter_Master": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Counter Master"])},
  "Guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide"])},
  "Checker_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checker 1"])},
  "Checker_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checker 2"])},
  "Extra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra"])},
  "Ticket_Date_Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket Date Time"])},
  "Total_payable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Payable"])},
  "Goods_tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GOODS TICKETS"])},
  "Cancelled_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled By"])},
  "Counterman_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Counterman Name"])},
  "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EMAIL"])},
  "User_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USER TYPE"])},
  "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "Update Owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Owner"])},
  "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "Roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles"])},
  "Give_all_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give All Permission"])},
  "Company_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Setup"])},
  "Recharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recharge"])},
  "Read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read"])},
  "Write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write"])},
  "Stoppage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stoppage"])},
  "Route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Route"])},
  "Seat-Plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seat-Plan"])},
  "Agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent"])},
  "Counterman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Counterman"])},
  "Add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New"])},
  "BoardingPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BOARDING PLACES"])},
  "DroppingPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DROPPING PLACES"])},
  "Sequence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEQUENCE"])},
  "Add Stoppage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Stoppage"])},
  "Update Stoppage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Stoppage"])},
  "Create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
  "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "Add Boarding Place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Boarding Place"])},
  "Add Dropping Place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Droping Place"])},
  "Update Boarding Place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Boarding Place"])},
  "Update Dropping Place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Dropping Place"])},
  "Do you want to delete boarding place?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete boarding place?"])},
  "Do you want to delete dropping place?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete dropping place?"])},
  "Update Counter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Counter"])},
  "Add Counter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Counter"])},
  "Preferred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferred"])},
  "--select preferred stoppage--": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--select preferred stoppage--"])},
  "routeSequence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Route Sequence"])},
  "--select direction--": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--select direction--"])},
  "Add Route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Route"])},
  "Update Route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Route"])},
  "Update Seat Class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Seat Class"])},
  "Add Seat Class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Seat Class"])},
  "addNewSeatPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Seat Plan"])},
  "Show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show"])},
  "Hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide"])},
  "Select Class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Class"])},
  "Update Seat Plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Seat Plan"])},
  "Edit Seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Seats"])},
  "removeSeatClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove Seat Class"])},
  "removeSeatClassConfirmMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to remove this seat class?"])},
  "Add Coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Coach"])},
  "Update Coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Coach"])},
  "Routes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Routes"])},
  "Seat-Fare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seat-Fare"])},
  "Update Trip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Trip"])},
  "Add Trips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Trips"])},
  "Trips Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trips Date"])},
  "Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
  "Seat Plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seat Plan"])},
  "Trip Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trip Cancel"])},
  "cancelTripMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to cancel this trip?"])},
  "coachWiseTrip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coach Wise Trip"])},
  "tripRoute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRIP ROUTE"])},
  "tripTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRIP Time"])},
  "Add Agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Agent"])},
  "ticketBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket Booking"])},
  "fareDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fare Discount"])},
  "ticketCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket Cancel"])},
  "extraTicketBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra Ticket Booking"])},
  "goodsTicketBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goods Ticket Booking"])},
  "tripPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trip Pass"])},
  "tripEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trip Edit"])},
  "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "Add Counterman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Counterman"])},
  "cancelOtherTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Other Ticket"])},
  "Recharge Agent Balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recharge Agent Balance"])},
  "Recharge Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recharge Amount"])},
  "Update Agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Agent"])},
  "Update Counterman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Counterman"])},
  "Add Supervisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Supervisor"])},
  "Update Supervisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Supervisor"])},
  "Add Driver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Driver"])},
  "Update Driver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Driver"])},
  "Edit Route Fare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Route Fare"])},
  "addFare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Fare"])},
  "--select route--": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--select route--"])},
  "--select sequence route--": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--select sequence route--"])},
  "Booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking"])},
  "Departure Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departure Date"])},
  "Departure Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departure Time"])},
  "Seat Cancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seat Cancellation"])},
  "Remaining Payable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining Payable"])},
  "Cancel Ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Ticket"])},
  "Pay Reserved Ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Reserved Ticket"])},
  "Pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
  "Log History": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log History"])},
  "Confirm Ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Ticket"])},
  "STUFF INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STUFF INFORMATION"])}
}