<template>
  <Modal @close="closeModal">
    <template v-slot:title>Update fare</template>
    <template v-slot:body>
      <form>
        <div class="form-group row my-1">
          <ModalDropdown
            v-model="farePayload.status"
            v-bind:id="'status'"
            v-bind:label="'Status'"
            v-bind:dropdown="status"
          />
        </div>

        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <input
              type="submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="updateSeatFare"
            />
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import dropdownVariables from "@/modules/dropdownVariables";
import { inject, onBeforeMount } from "vue";
import { reactive } from "@vue/reactivity";
import store from "@/store";

export default {
  name: "BoardingPlaceAddUpdateModal",
  props: {
    seatFare: Object,
    modelValue: [String, Number, Boolean],
  },
  setup(props, { emit }) {
    let farePayload = reactive({
      fare_id: props.seatFare._id,
      status: props.seatFare.status ? 1 : 0,
    });
    const status = dropdownVariables.status;

    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    const closeModal = () => {
      emit("update:modelValue", false);
    };

    const updateSeatFare = () => {
      showLoader();

      store
        .dispatch("updateOwnerServicesSeatFare", farePayload)
        .then(() => {
          hideLoader();
          closeModal();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            updateSeatFare();
          }
        });
    };

    onBeforeMount(() => {});

    return {
      status,
      closeModal,
      updateSeatFare,
      farePayload,
    };
  },
};
</script>

<style scoped></style>
