<template>
  <div
      v-if="
      hasPermission(Owner.ownerCompanySetupRead) &&
      hasPermission(Owner.ownerTripRead)
    "
  >
    <LogHistory
        v-if="logHistory"
        v-model="logHistory"
        :tripId="tripIdIndividual"
    />
    <div v-else>
      <FilterTab>
        <!-- <template v-slot:title><h6>Trips</h6></template> -->
        <template v-slot:body>
          <div class="col-sm-6 col-md-2">
            <DropdownFilter
                v-bind:id="'from'"
                v-bind:label="'From Stoppage'"
                v-bind:defaultOption="'--select stoppage--'"
                v-model="pagination.from"
                v-bind:defaultValue="''"
                v-bind:dropdown="
                $store.getters.getOwnerServicesStoppageListForFilterNew
              "
            />
          </div>
          <div class="col-sm-6 col-md-2">
            <DropdownFilter
                v-bind:id="'to'"
                v-bind:label="'To Stoppage'"
                v-bind:defaultOption="'--select stoppage--'"
                v-model="pagination.to"
                v-bind:defaultValue="''"
                v-bind:dropdown="
                $store.getters.getOwnerServicesStoppageListForFilterNew
              "
            />
          </div>
          <div class="col-sm-6 col-md-2">
            <DropdownFilter
                v-bind:id="'coach'"
                v-bind:label="'Coach'"
                v-bind:defaultOption="'--select coach--'"
                v-model="pagination.coach"
                v-bind:defaultValue="''"
                v-bind:dropdown="
                $store.getters.getOwnerServicesAllCoachListForFilter
              "
            />
          </div>
          <div class="col-sm-6 col-md-2">
            <DatePickerFilter
                v-bind:id="'datePickerDate'"
                v-bind:label="'Date'"
                v-model="pagination.date"
            />
          </div>
          <div class="col-sm-6 col-md-2">
            <DropdownFilter
                v-bind:id="'status'"
                v-bind:label="'Status'"
                v-bind:defaultOption="'--select status--'"
                v-model="pagination.status"
                v-bind:defaultValue="''"
                v-bind:dropdown="status"
            />
          </div>
        </template>
        <template
            v-if="hasPermission(Owner.ownerCompanySetupWrite)"
            v-slot:button1
        >
          <button class="edit_table_data font_detail" @click="addModal">
            <i class="fas fa-plus"></i
            ><span class="mx-1 bold">{{
              t("Add_new", {}, {locale: $store.getters.getLang})
            }}</span>
          </button>
        </template>
      </FilterTab>
      <div v-if="addUpdate">
        <TripsAddUpdateModal
            v-bind:trip="tripId"
            v-bind:pagination="pagination"
            @close="close"
            @getAllTrips="getAllTrips"
        />
      </div>
      <div>
        <Table v-if="$store.getters.getOwnerServicesTripsNew">
          <template v-slot:header>
            <tr>
              <td class="font_detail">
                {{ t("SL", {}, {locale: $store.getters.getLang}) }}
              </td>
              <td class="font_detail">
                {{
                  t("TRIP TIME & DATE", {}, {locale: $store.getters.getLang})
                }}
              </td>
              <td class="font_detail">
                {{ t("TRIP CODE", {}, {locale: $store.getters.getLang}) }}
              </td>
              <td class="font_detail">
                {{ t("ROUTE NAME", {}, {locale: $store.getters.getLang}) }}
              </td>
              <td class="font_detail">
                {{ t("COACH NAME", {}, {locale: $store.getters.getLang}) }}
              </td>
              <td class="font_detail">
                {{ t("FROM STOPPAGE", {}, {locale: $store.getters.getLang}) }}
              </td>
              <td class="font_detail">
                {{ t("TO STOPPAGE", {}, {locale: $store.getters.getLang}) }}
              </td>
              <td class="font_detail">
                {{ t("SEATPLAN NAME", {}, {locale: $store.getters.getLang}) }}
              </td>
              <td class="font_detail">
                {{ t("DT STATUS", {}, {locale: $store.getters.getLang}) }}
              </td>
              <td class="font_detail">
                {{ t("STATUS", {}, {locale: $store.getters.getLang}) }}
              </td>
              <td
                  v-if="hasPermission(Owner.ownerTripWrite)"
                  class="font_detail"
              >
                {{ t("ACTION", {}, {locale: $store.getters.getLang}) }}
              </td>
            </tr>
          </template>
          <template v-slot:body>
            <tr
                v-for="(trip, index) in $store.getters.getOwnerServicesTripsNew"
                :key="trip._id"
            >
              <td class="font_detail">{{ index + 1 }}</td>
              <td class="font_detail bold">
                {{ dateFormat(trip.tripDateTime) }}
              </td>
              <td class="font_detail">{{ trip.tripCode }}</td>
              <td class="font_detail">{{ trip.route.name }}</td>
              <td class="font_detail">{{ trip.coach.name }}</td>
              <td class="font_detail">
                <span
                    v-for="[key, value] of Object.entries(
                    $store.getters.getOwnerServicesStoppageListForFilterNew
                  )"
                    :key="key.index"
                >
                  <span v-if="key === trip.route.fromStoppageId">
                    {{ value }}
                  </span>
                </span>
              </td>
              <td class="font_detail">
                <span
                    v-for="[key, value] of Object.entries(
                    $store.getters.getOwnerServicesStoppageListForFilterNew
                  )"
                    :key="key.index"
                >
                  <span v-if="key === trip.route.toStoppageId">
                    {{ value }}
                  </span>
                </span>
              </td>
              <td class="font_detail">{{ trip.seatPlan.name }}</td>
              <td class="font_detail">{{ dropdownVariables.boolean[trip.dtStatus ? 1 : 0] }}</td>
              <!-- <td class="font_detail">{{ trip.tripCode }}</td>
                            <td class="font_detail bold">{{ trip.ship.name }}</td>
                            <td class="font_detail">{{ trip.route.name }}</td> -->
              <td>
                <span
                    class="badge"
                    :class="
                    'bg-' + dropdownVariables.tripStatus[trip.status].badge
                  "
                >{{
                    dropdownVariables.tripStatus[trip.status].value ===
                    "Cancelled"
                        ? `${
                            dropdownVariables.tripStatus[trip.status].value
                        } by ${trip.cancelledBy.name}`
                        : dropdownVariables.tripStatus[trip.status].value
                  }}</span
                >
              </td>
              <td v-if="hasPermission(Owner.ownerTripWrite)">
                <!--                <button-->
                <!--                    class="edit_table_data font_detail edit_table_button"-->
                <!--                    @click="handleLogHistory(trip._id)"-->
                <!--                >-->
                <!--                  <i></i><span class="mx-1">Log History</span>-->
                <!--                </button>-->
                <button
                    class="edit_table_data font_detail edit_table_button"
                    @click="updateModal(trip._id)"
                >
                  <i class="fas fa-edit"></i
                  ><span class="mx-1">{{
                    t("Edit", {}, {locale: $store.getters.getLang})
                  }}</span>
                </button>
                <button
                    v-if="trip.status === 'ACTIVE'"
                    class="edit_table_data font_detail edit_table_button_update"
                    @click="cancel(trip._id)"
                >
                  <i class="fas fa-trash"></i
                  ><span class="mx-1">{{
                    t("Cancel", {}, {locale: $store.getters.getLang})
                  }}</span>
                </button>
              </td>
            </tr>
          </template>
          <template v-slot:pagination>
            <pagination
                v-model="pagination.page"
                :records="$store.getters.getOwnerServicesTotalTrips"
                :per-page="pagination.limit"
                @paginate="getAllTrips"
                :options="options"
            />
          </template>
        </Table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {reactive} from "@vue/reactivity";
import {inject, onBeforeMount, ref, watch, computed} from "vue";
import store from "@/store";
import dropdownVariables from "@/modules/dropdownVariables";
import TripsAddUpdateModal from "./TripsAddUpdateModal";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import {useI18n} from "vue-i18n";

const {hasPermission} = permission();
const {Owner} = roles();
import LogHistory from "./LogHistory.vue";

export default {
  name: "Trips",
  components: {TripsAddUpdateModal, LogHistory},
  setup() {
    let pagination = reactive({
      page: 1,
      limit: 25,
      from: "",
      to: "",
      coach: "",
      date: moment(new Date()).format("yyyy-MM-DD"),
      status: "ACTIVE",
    });
    let tripIdIndividual = ref("");
    let logHistory = ref(false);
    const addUpdate = ref(false);
    const tripId = ref("");
    const options = {
      texts: {
        count: "",
      },
    };
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const {t, locale} = useI18n();
    // const routeList = reactive({});
    // let stoppageList = ref({});
    // const shipList = reactive({});

    watch(
        () => pagination.status,
        () => {
          getAllTrips();
        }
    );
    watch(
        () => pagination.from,
        () => {
          getAllTrips();
        }
    );
    watch(
        () => pagination.to,
        () => {
          getAllTrips();
        }
    );
    watch(
        () => pagination.date,
        () => {
          getAllTrips();
        }
    );
    watch(
        () => pagination.coach,
        () => {
          getAllTrips();
        }
    );

    const status = computed(() => {
      let allTripStatus = {};
      for (let [key, value] of Object.entries(dropdownVariables.tripStatus)) {
        allTripStatus[key] = value.value;
      }
      return allTripStatus;
    });

    const close = () => {
      addUpdate.value = false;
    };
    const addModal = () => {
      tripId.value = "";
      addUpdate.value = true;
    };
    const updateModal = (id) => {
      tripId.value = id;
      addUpdate.value = true;
    };
    const dateFormat = (date) => {
      return moment(String(date))
          .utcOffset(0, false)
          .format("DD-MMM-yyyy hh:mm:ss A");
    };
    const getAllRoutes = () => {
      showLoader();
      store
          .dispatch("getOwnerServicesRoutesByPageNew", {
            page: 1,
            limit: 100
          })
          .then(() => {
            hideLoader();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              getAllRoutes();
            }
          });
    };
    const getAllTrips = () => {
      showLoader();
      store
          .dispatch("getOwnerServicesTripsByPage", pagination)
          .then(() => {
            hideLoader();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              getAllTrips();
            }
          });
    };
    const getAllStoppages = () => {
      showLoader();
      store
          .dispatch("getOwnerServicesStoppagesByPageNew", {
            page: 1,
            limit: 100
          })
          .then(() => {
            hideLoader();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              getAllStoppages();
            }
          });
    };
    const getRouteListForFilter = () => {
      store.dispatch("getOwnerServicesRouteListFilterNew", {})
    }
    const getCoachListForFilter = () => {
      store.dispatch("getOwnerServicesAllCoachList", {})
    }
    const getStoppageListForFilter = () => {
      store
          .dispatch("getOwnerServicesStoppageList", {})
          .then(() => {
            hideLoader();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              getStoppageListForFilter();
            }
          });
    };
    const getAllCoach = () => {
      showLoader();
      store
          .dispatch("getOwnerServicesAllCoachByPage", {
            page: 1,
            limit: 100
          })
          .then(() => {
            hideLoader();
          })
          .catch(() => {
            hideLoader();
          });
    };
    const getSeatPlanListForFilter = () => {
      store.dispatch("getOwnerServicesAllSeatPlan", {});
    }
    const cancel = (id) => {
      showLoader();
      store
          .dispatch("cancelOwnerServicesTrip", {
            payload: {id: id},
          })
          .then(() => {
            getAllTrips();
            hideLoader();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              getAllTrips();
            }
          });
    };

    const handleLogHistory = (tripId) => {
      logHistory.value = true;
      tripIdIndividual.value = tripId;
    };

    onBeforeMount(() => {
      getAllTrips();
      getAllRoutes();
      getAllStoppages();
      getAllCoach();
      getRouteListForFilter()
      getStoppageListForFilter();
      getSeatPlanListForFilter();
      getCoachListForFilter();
    });

    return {
      t,
      locale,
      options,
      pagination,
      dropdownVariables,
      addUpdate,
      tripId,
      Owner,
      cancel,
      hasPermission,
      getAllTrips,
      addModal,
      updateModal,
      close,
      dateFormat,
      status,
      logHistory,
      tripIdIndividual,
      handleLogHistory,
    };
  },
};
</script>

<style scoped>
.bold {
  font-weight: bold;
}
</style>
