<template>
  <div v-if="hasPermission(Owner.ownerDashboard)">
    <Filter>
      <template v-slot:body>
        <div class="col-sm-12 col-md-4">
          <DateTimeRangePicker v-model="date.range" />
        </div>
        <div class="col-md-2 mt-auto">
          <button
            class="edit_table_data font_detail mt-2"
            @click="getDeckAndSeat"
          >
            <i class="fas fa-filter"></i><span class="mx-1 bold">{{ t("Filter", {}, { locale: $store.getters.getLang }) }}</span>
          </button>
        </div>
      </template>
    </Filter>
    <DashboardReports />
  </div>
</template>

<script>
import moment from "moment";
import store from "@/store";

import permission from "@/modules/permission";
import roles from "@/modules/roles";
import { inject, onBeforeMount, watch } from "vue";

import { reactive } from "@vue/reactivity";
import DateTimeRangePicker from "@/components/layouts/Filter/Inputs/DateTimeRangePicker";
import DashboardReports from "@/views/Owner/Dashboard/DashboardReports.vue";
import router from "@/router";
import web from "@/urls/web";
import { useI18n } from "vue-i18n";

const { hasPermission } = permission();
const { Owner } = roles();
export default {
  name: "AdminDashboard",
  components: { DateTimeRangePicker, DashboardReports },

  setup() {
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    let fromDate = moment(new Date()).format("yyyy-MM-DD" + " 00:00:00");
    let toDate = moment(new Date()).format("yyyy-MM-DD" + " 23:59:59");
    let date = reactive({
      range: {
        start: fromDate,
        end: toDate,
      },
    });
    watch(
      () => store.getters.userRoles,
      (value) => {
        if (!value?.includes(Owner.ownerDashboard)) {
          router
            .push(web.Default.LOGIN_URL.path)
            .catch((error) => console.log(error));
        }
      }
    );
    const { t, locale } = useI18n();
    const getDeckAndSeat = () => {
      showLoader();
      const payload = {
        toDate: date.range.end,
        fromDate: date.range.start,
      };
      store
        .dispatch("getDashboardTicketsOwner", payload)
        .then(() => {
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getDeckAndSeat();
          }
        });
    };

    onBeforeMount(() => {
      getDeckAndSeat();
    });

    return {
      t,
      locale,
      date,
      Owner,
      hasPermission,
      getDeckAndSeat,
    };
  },
};
</script>

<style scoped></style>
