<template>
  <Table
    v-if="$store.getters.getPrintTicketCountListOwner.length > 0"
    id="exportable_data"
  >
    {{ $store.getters.printTicketCountListOwner }}

    <template v-slot:header>
      <tr>
        <td class="font_detail">
          {{ t("SL", {}, { locale: $store.getters.getLang }) }}
        </td>
        <td class="font_detail">
          {{ t("Counterman_name", {}, { locale: $store.getters.getLang }) }}
        </td>
        <!-- <td class="font_detail">Trip Datetime</td> -->
        <td class="font_detail">
          {{ t("COACH NAME", {}, { locale: $store.getters.getLang }) }}
        </td>
        <td class="font_detail">
          {{ t("PNR CODE", {}, { locale: $store.getters.getLang }) }}
        </td>
        <td class="font_detail">
          {{ t("Seat Class", {}, { locale: $store.getters.getLang }) }}
        </td>
        <td class="font_detail">
          {{ t("SEAT NUMBERS", {}, { locale: $store.getters.getLang }) }}
        </td>
        <td class="font_detail">
          {{ t("Print Count", {}, { locale: $store.getters.getLang }) }}
        </td>
      </tr>
    </template>
    <template v-slot:body>
      <tr
        v-for="(item, index) in $store.getters.getPrintTicketCountListOwner"
        :key="index"
      >
        <td class="font_detail">{{ index + 1 }}</td>
        <td class="font_detail">{{ item.issuedBy?.name }}</td>
        <!-- <td class="font_detail">{{ dateFormat(item.tripDateTime) }}</td> -->
        <td class="font_detail">{{ item.coach?.name }}</td>
        <td class="font_detail">{{ item.pnrCode }}</td>
        <td class="font_detail">{{ item.seatClass }}</td>
        <td class="font_detail">
          {{ item.seatNumbers.toString() }}
        </td>
        <td class="font_detail">{{ item.printCount }}</td>
      </tr>
      <!-- <tr class="bg-dark text-white">
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td class="font_detail"></td>
      </tr> -->
    </template>
    <!-- <template v-slot:pagination>
      <Pagination
        v-model="shipWiseSeatReport.page"
        :records="$store.getters.getShipWiseSeatDetailsOwner.length"
        :per-page="shipWiseSeatReport.limit"
        @paginate="getSeatDetails"
        :options="options"
      />
    </template> -->
  </Table>
</template>

<script>
import moment from "moment";
import { useI18n } from "vue-i18n";
export default {
  name: "TicketPrintCountDetails",
  setup() {
    const { t, locale } = useI18n();
    const dateFormat = (date) => {
      return moment(String(date))
        .utcOffset(0, true)
        .format("DD-MMM-yyyy hh:mm:ss A");
    };
    return {
      t,
      locale,
      dateFormat,
    };
  },
};
</script>

<style></style>
