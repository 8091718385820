import DatePicker from "@/components/layouts/DateTime/DatePicker";
import TimePicker from "@/components/layouts/DateTime/TimePicker";
import Filter from "@/components/layouts/Filter/Filter";
import FilterTab from "@/components/layouts/Filter/FilterTab";
import DatePickerFilter from "@/components/layouts/Filter/Inputs/DatePicker";
import DateTimeRangePicker from "@/components/layouts/Filter/Inputs/DateTimeRangePicker";
import TimePickerFilter from "@/components/layouts/Filter/Inputs/TimePicker";
import DropdownFilter from "@/components/layouts/Filter/Inputs/Dropdown";
import Checkbox from "@/components/layouts/Inputs/Checkbox";
import Dropdown from "@/components/layouts/Inputs/Dropdown";
import Error from "@/components/layouts/Inputs/Error";
import Input from "@/components/layouts/Inputs/Input";
import TextArea from "@/components/layouts/Inputs/TextArea";
import ModalCheckCheck from "@/components/layouts/Modals/Inputs/CheckCheck";
import ModalCheckSwitch from "@/components/layouts/Modals/Inputs/CheckSwitch";
import ModalDropdown from "@/components/layouts/Modals/Inputs/Dropdown";
import ModalError from "@/components/layouts/Modals/Inputs/Error";
import ModalInput from "@/components/layouts/Modals/Inputs/Input";
import ModalMultiselect from "@/components/layouts/Modals/Inputs/MultiSelectDropdown";
import Modal from "@/components/layouts/Modals/Modal";
import Table from "@/components/layouts/Tables/Table";
import TableCard from "@/components/layouts/Tables/TableCard.vue";
import TableSimple from "@/components/layouts/Tables/TableSimple";

export default {
    install: (app) => {
        app.component("Table", Table);
        app.component("TableCard", TableCard);
        app.component("TableSimple", TableSimple);
        app.component("Modal", Modal);
        app.component("ModalInput", ModalInput);
        app.component("TextArea", TextArea);
        app.component("ModalError", ModalError);
        app.component("ModalDropdown", ModalDropdown);
        app.component("ModalCheckSwitch", ModalCheckSwitch);
        app.component("ModalCheckCheck", ModalCheckCheck);
        app.component("Checkbox", Checkbox);
        app.component("Input", Input);
        app.component("Error", Error);
        app.component("Dropdown", Dropdown);
        app.component("FilterTab", FilterTab);
        app.component("Filter", Filter);
        app.component("ModalMultiselect", ModalMultiselect);
        app.component("DropdownFilter", DropdownFilter);
        app.component("DatePickerFilter", DatePickerFilter);
        app.component("DatePicker", DatePicker);
        app.component("TimePicker", TimePicker);
        app.component("TimePickerFilter", TimePickerFilter)
        app.component("DateTimeRangePicker", DateTimeRangePicker);
    },
};
