import apiResponse from "@/modules/apiResponse";
import toast from "@/modules/Toast";
import tokenCookie from "@/modules/tokenCookie";
import Apis from "@/urls/apis";
import axios from "axios";

const { infoToast, successToast } = toast();
const { hasValidToken, getToken } = tokenCookie();
const { errorResponse, successResponse } = apiResponse();

const state = {
  filter: {
    shipOwner: {},
    counterOwner: {},
    coachOwner: {},
    counterOwnerPanel: {},
  },
  shipWiseReportListOwner: [],
  agentWiseReportListOwner: [],
  shipWiseDeckDetailsOwner: [],
  shipWiseGoodDetailsOwner: [],
  shipWiseSeatDetailsOwner: [],
  counterWiseSeatDetailsOwner: [],
  agentWiseSeatDetailsOwner: [],
  counterWiseReportListOwner: [],
  printTicketCountListOwner: [],
  printTripWiseCountListOwner: [],
  printDailySummaryWiseCountListOwner: [],
  coachwiseTripOwner: [],
  chalanTripList: [],
  chalanReport: {},
  coachWiseReportListOwner: [],
  coachWiseSeatDetailsOwner: [],
  coachWiseExtraDetailsOwner: [],
  coachWiseGoodsDetailsOwner: [],
  counterWiseReportListOWNER: [],
  agentWiseReportListOWNERPanel: [],
  agentWiseSeatDetailsOwnerPanel: [],
  agentWiseExtraDetailsOwnerPanel: [],
  agentWiseGoodsDetailsOwnerPanel: [],
  ownerReservationTripList: [],
};
const getters = {
  getOwnerReservationTripList: (state => state.ownerReservationTripList),
  getAgentWiseGoodsDetailsOwnerPanel: (state) =>
    state.agentWiseGoodsDetailsOwnerPanel,
  getAgentWiseExtraDetailsOwnerPanel: (state) =>
    state.agentWiseExtraDetailsOwnerPanel,
  getAgentWiseSeatDetailsOwnerPanel: (state) =>
    state.agentWiseSeatDetailsOwnerPanel,
  getCounterWiseGoodsDetailsOwner: (state) =>
    state.counterWiseGoodsDetailsOwner,
  getCounterListForFilterOwnerPanel: (state) => state.filter.counterOwnerPanel,
  getCoachWiseSeatDetailsOwner: (state) => state.coachWiseSeatDetailsOwner,
  getCoachWiseExtraDetailsOwner: (state) => state.coachWiseExtraDetailsOwner,
  getCoachWiseGoodsDetailsOwner: (state) => state.coachWiseGoodsDetailsOwner,
  getCoachWiseReportOwnerIntercity: (state) => state.coachWiseReportListOwner,
  getCoachListForFilterOwner: (state) => state.filter.coachOwner,
  getChalanReportDetails: (state) => state.chalanReport,
  getChalanCountermanSeatReport: (state) =>
    Object.values(state.chalanReport.countermanSeatReport),
  getChalanReportDetail: (state) => (id) =>
    state.chalanReport.filter(function(trip) {
      return trip._id === id;
    }),
  getShipListForFilterOwner: (state) => state.filter.shipOwner,
  getCounterListForFilterOwner: (state) => state.filter.counterOwner,
  getAllCoachwiseTripListOwner: (state) => state.coachwiseTripOwner.docs,
  // getTotalCoachwiseTripList:(state)=> state.coachwiseTrip.totalDocs,
  getCoachwiseTripOwner: (state) => (id) =>
    state.coachwiseTripOwner.docs.filter(function(trip) {
      return trip._id === id;
    }),
  getChalanTripList: (state) => state.chalanTripList,
  getShipWiseReportOwner: (state) => state.shipWiseReportListOwner,
  getAgentWiseReportOwner: (state) => state.agentWiseReportListOwner,
  getcounterWiseSeatDetailsOwner: (state) => state.counterWiseSeatDetailsOwner,
  getAgentWiseSeatDetailsOwner: (state) => state.agentWiseSeatDetailsOwner,
  getCounterWiseReportListOwner: (state) => state.counterWiseReportListOwner,
  getAgentWiseReportListOWNERPanel: (state) =>
    state.agentWiseReportListOWNERPanel,
  getCounterWiseReportOwner: (state) => state.counterWiseReportListOWNER,
  getShipWiseDeckDetailsOwner: (state) => state.shipWiseDeckDetailsOwner,
  getShipWiseGoodDetailsOwner: (state) => state.shipWiseGoodDetailsOwner,
  getShipWiseSeatDetailsOwner: (state) => state.shipWiseSeatDetailsOwner,
  getPrintTicketCountListOwner: (state) => state.printTicketCountListOwner,
  getPrintTripWiseCountListOwner: (state) => state.printTripWiseCountListOwner,
  getPrintDailySummaryWiseCountListOwner: (state) =>
    state.printDailySummaryWiseCountListOwner,
};
const actions = {
  async getOwnerReservationTripReportList({commit}, payload) {
    if (await hasValidToken()) {
      const header = {
        'Authorization': 'Bearer ' + getToken()
      };

      return new Promise((resolve, reject) => {
        axios.get(Apis.GET_OWNER_RESERVATION_HISTORY, {
          params: payload,
          headers: header
        })
            .then(({data, status}) => {
              if (status === 200) {
                commit("setOwnerReservationTripList", data.trips);
                resolve(data)
              }
            })
            .catch(error => {
              reject(errorResponse(error))
            });
      });
    }
  },

  async getOwnerReservationTripReportDetails(_, payload) {
    if (await hasValidToken()) {
      const header = {
        'Authorization': 'Bearer ' + getToken()
      };

      return new Promise((resolve, reject) => {
        axios.get(Apis.GET_OWNER_RESERVATION_HISTORY_DETAILS, {
          params: payload,
          headers: header
        })
            .then(({data, status}) => {
              if (status === 200) {
                resolve(data)
              }
            })
            .catch(error => {
              reject(errorResponse(error))
            });
      });
    }
  },

  async getPrintCountListOwnerPanel({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.OWNER_PRINT_COUNT_REPORT, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            successToast(data.message);
            if (status === 200) {
              commit("setCountReportOwnerPanel", { data, payload });
              resolve();
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async getAgentWiseGoodsReportDetailsOwnerPanel({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.AGENT_WISE_REPORT_OWNER_PANEL_DETAILS_API, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.ticket.length === 0) {
              infoToast("No Data Available");
            }
            if (status === 200) {
              commit("setAgentWiseGoodsDetailsOwnerPanel", data.ticket);
            }
            resolve(data.ticket);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async getAgetnWiseExtraReportDetailsOwnerPanel({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.AGENT_WISE_REPORT_OWNER_PANEL_DETAILS_API, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.ticket.length === 0) {
              infoToast("No Data Available");
            }
            if (status === 200) {
              commit("setAgentWiseExtraDetailsOwnerPanel", data.ticket);
            }
            resolve(data.ticket);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async getAgetnWiseSeatReportDetailsOwnerPANEL({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.AGENT_WISE_REPORT_OWNER_PANEL_DETAILS_API, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.ticket.length === 0) {
              infoToast("No Data Available");
            }
            if (status === 200) {
              commit("setAgentWiseSeatDetailsOwnerPanel", data.ticket);
            }
            resolve(data.ticket);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async getfilterAgentWiseReportOwnerAgentWiseReport({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };
      return new Promise((resolve, reject) => {
        axios
          .get(Apis.AGENT_WISE_REPORT_OWNER_PANEL, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            successToast(data.message);
            if (status === 200) {
              commit("setFilterAgentWiseReportOwnerPANEL", data.ticket);
            }
            resolve(data.ticket);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async getCounterWiseGoodsDetailsOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.COUNTERMAN_WISE_REPORT_DETAILS_OWNER, {
            params: payload,
            headers: header,
          })
          .then((response) => {
            response.data.ticket.length === 0 ? infoToast(response.data.message) : successResponse(response)

            commit("setCounterWiseGoodsDetailsOwner", response.data.ticket);
      
            resolve(response.data.ticket);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async getCountermanWiseReportDetailsOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.COUNTERMAN_WISE_REPORT_DETAILS_OWNER, {
            params: payload,
            headers: header,
          })
          .then((response) => {
            response.data.ticket.length === 0 ? infoToast(response.data.message) : successResponse(response)
            
            commit("setCounterWiseSeatDetailsOwner", response.data.ticket);
            resolve(response.data.ticket);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async getCountermanWiseReportDetails({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.COUNTERMAN_WISE_REPORT_DETAILS, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.ticket.length === 0) {
              infoToast("No Data Available");
            }
            if (status === 200) {
              commit("setCoachWiseSeatDetails", data.ticket);
            }
            resolve(data.ticket);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async getCounterListForFilterOwnerPanel({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setCounterListInFilter", {});
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_COUNTERS_IN_FILTER_OWNER_PANEL, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              let counterList = {};
              data.counter.forEach((counter) => {
                counterList[counter._id] = counter.name;
              });
              commit("setCounterListInFilterOwnerPanel", counterList);
              resolve(data.counter);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async getfilterCounterWiseReportOwnerPanel({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };
      return new Promise((resolve, reject) => {
        axios
          .get(Apis.COUNTER_WISE_REPORT_OWNER_PANEL, {
            params: payload,
            headers: header,
          })
          .then((response) => {
            response.data.ticket.length === 0 ? infoToast(response.data.message) : successResponse(response)
            commit("setFilterCounterWiseReportOWNER", response.data.ticket);
            resolve(response.data.ticket);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async getSeatDetailsOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.COACH_WISE_SEAT_REPORT_DETAILS_OWNER, {
            params: payload,
            headers: header,
          })
          .then((response) => {
            response.data.ticket.length === 0 ? infoToast(response.data.message) : successResponse(response)

            commit("setCoachWiseSeatDetailsOwner", response.data.ticket);
            resolve(response.data.ticket);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async getExtraDetailsOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.COACH_WISE_SEAT_REPORT_DETAILS_OWNER, {
            params: payload,
            headers: header,
          })
          .then((response) => {
            response.data.ticket.length === 0 ? infoToast(response.data.message) :   successResponse(response)

            commit("setCoachWiseExtraDetailsOwner", response.data.ticket);
            resolve(response.data.ticket);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async getGoodsDetailsOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.COACH_WISE_SEAT_REPORT_DETAILS_OWNER, {
            params: payload,
            headers: header,
          })
          .then((response) => {
            response.data.ticket.length === 0 ? infoToast(response.data.message) :   resolve(successResponse(response))

            commit("setCoachWiseGoodsDetailsOwner", response.data.ticket);
     
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async getAllCoachListOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_LIST_COACH_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              let coachList = {};
              data.coach.forEach((coach) => {
                coachList[coach._id] = coach.name;
              });
              commit("setCoachListInFilterOwner", coachList);
              resolve(data.coach);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async getAllCoachReportOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.COACH_WISE_REPORT_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              if(data.ticket.length === 0){
                infoToast(data.message)
              }
              commit("setCoachWiseListOwner", data.ticket);
              resolve(data.ticket);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async getChalanTripList({ commit }, payload) {
    if (await hasValidToken()) {
      commit("addChalanTripList", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.CHALAN_TRIP_WISE_REPORT_OWNER, {
            params: payload,
            headers: header,
          })
          .then((response) => {
            commit("addChalanTripList", response.data.trips);
            successToast(response.data.message);
            resolve(response);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getChalanDetails({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setChalanDetails", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_CHALAN_REPORT, {
            params: payload,
            headers: header,
          })
          .then((response) => {
            commit("setChalanDetails", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async getAllCoachwiseTripByPageOwner({ commit }, payload) {
    if (await hasValidToken()) {
      commit("addCoach", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_COACHWISE_TRIP_OWNER, {
            params: payload,
            headers: header,
          })
          .then((response) => {
            commit("addCoachwiseTripListOwner", response.data.coaches);
            resolve(response);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getShipListOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_SHIPS_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              let shipList = {};
              data.ship.forEach((ship) => {
                shipList[ship._id] = ship.name;
              });
              commit("setShipListInFilterOwner", shipList);
              resolve(data.ship);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getCounterListOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_COUNTER_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              let counterList = {};
              data.counter.forEach((counter) => {
                counterList[counter._id] = counter.name;
              });
              commit("setCounterListInFilterOwner", counterList);
              resolve(counterList);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  // async getCounterWiseSeatDetailsOwner({ commit }, payload) {
  //   if (await hasValidToken()) {
  //     const header = {
  //       Authorization: "Bearer " + getToken(),
  //     };

  //     return new Promise((resolve, reject) => {
  //       axios
  //         .get(Apis.COUNTER_WISE_DETAILS_REPORT_OWNER, {
  //           params: payload,
  //           headers: header,
  //         })
  //         .then(({ data, status }) => {
  //           if (data.ticket.length === 0) {
  //             infoToast("No Data Available");
  //           }
  //           if (status === 200) {
  //             commit("setCounterWiseSeatDetailsOwner", data.ticket);
  //           }
  //           resolve(data.ticket);
  //         })
  //         .catch((error) => {
  //           reject(errorResponse(error));
  //         });
  //     });
  //   }
  // },

  // async getSeatDetailsOwner({ commit }, payload) {
  //   if (await hasValidToken()) {
  //     const header = {
  //       Authorization: "Bearer " + getToken(),
  //     };

  //     return new Promise((resolve, reject) => {
  //       axios
  //         .get(Apis.SHIP_WISE_SEAT_REPORT_DETAILS_OWNER, {
  //           params: payload,
  //           headers: header,
  //         })
  //         .then(({ data, status }) => {
  //           if (data.ticket.length === 0) {
  //             infoToast("No Data Available");
  //           }
  //           if (status === 200) {
  //             commit("setShipWiseSeatDetailsOwner", data.ticket);
  //           }
  //           resolve(data.ticket);
  //         })
  //         .catch((error) => {
  //           reject(errorResponse(error));
  //         });
  //     });
  //   }
  // },

  async getDeckDetailsOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.SHIP_WISE_DECK_REPORT_DETAILS_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.ticket.length === 0) {
              infoToast("No Data Available");
            }
            if (status === 200) {
              commit("setShipWiseDeckDetailsOwner", data.ticket);
            }
            resolve(data.ticket);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getGoodDetailsOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.SHIP_WISE_GOOD_REPORT_DETAILS_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.ticket.length === 0) {
              infoToast("No Data Available");
            }
            if (status === 200) {
              commit("setShipWiseGoodDetailsOwner", data.ticket);
            }
            resolve(data.ticket);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getAgentWiseSeatDetailsOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.AGENT_WISE_REPORT_DETAILS_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.ticket.length === 0) {
              infoToast("No Data Available");
            }
            if (status === 200) {
              commit("setAgentWiseSeatDetailsOwner", data.ticket);
            }
            resolve(data.ticket);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getfilterAgentWiseReportOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };
      return new Promise((resolve, reject) => {
        axios
          .get(Apis.AGENT_WISE_REPORT_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.ticket.length === 0) {
              infoToast("No Data Available");
            }
            if (status === 200) {
              commit("setFilterAgentWiseReportOwner", data.ticket);
            }
            resolve(data.ticket);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getAllAgentListOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.AGENT_WISE_REPORT_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setAgentWiseListOwner", data.ticket);
              resolve(data.ticket);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getFilterShipWiseReportOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.SHIP_WISE_REPORT_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setFilterShipWiseListOwner", data.ticket);
              resolve(data.ticket);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getAllShipReportOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.SHIP_WISE_REPORT_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.ticket.length === 0) {
              infoToast("No Data Available");
            }
            if (status === 200) {
              commit("setShipWiseListOwner", data.ticket);
              resolve(data.ticket);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async getPrintCountListOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.PRINT_COUNT_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setCountReportOwner", { data, payload });
              resolve(data);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
};

const mutations = {
  setOwnerReservationTripList: ((state, list) => state.ownerReservationTripList = list),
  setAgentWiseGoodsDetailsOwnerPanel: (state, goodsDetails) =>
    (state.agentWiseGoodsDetailsOwnerPanel = goodsDetails),
  setAgentWiseExtraDetailsOwnerPanel: (state, extraDetails) =>
    (state.agentWiseExtraDetailsOwnerPanel = extraDetails),
  setCounterWiseGoodsDetailsOwner: (state, goodsDetails) =>
    (state.counterWiseGoodsDetailsOwner = goodsDetails),
  setFilterCounterWiseReportOWNER: (state, updateList) =>
    (state.counterWiseReportListOWNER = updateList),
  setCoachWiseGoodsDetailsOwner: (state, goodsDetails) =>
    (state.coachWiseGoodsDetailsOwner = goodsDetails),
  setCoachWiseExtraDetailsOwner: (state, extraDetails) =>
    (state.coachWiseExtraDetailsOwner = extraDetails),
  setCoachWiseSeatDetailsOwner: (state, seatDetails) =>
    (state.coachWiseSeatDetailsOwner = seatDetails),
  setCoachWiseListOwner: (state, coachWiseReport) =>
    (state.coachWiseReportListOwner = coachWiseReport), //
  setShipListInFilterOwner: (state, shipList) =>
    (state.filter.shipOwner = shipList),
  setCounterListInFilterOwner: (state, counterList) =>
    (state.filter.counterOwner = counterList),
  addChalanTripList: (state, chalanTripList) =>
    (state.chalanTripList = chalanTripList),
  setAgentWiseSeatDetailsOwner: (state, seatDetails) =>
    (state.agentWiseSeatDetailsOwner = seatDetails),
  setFilterAgentWiseReportOwner: (state, updateList) =>
    (state.agentWiseReportListOwner = updateList),
  setFilterCounterWiseReportOwner: (state, updateList) =>
    (state.counterWiseReportListOwner = updateList),
  setAllCounterListOwner: (state, counterWiseReport) =>
    (state.counterWiseReportListOwner = counterWiseReport),
  setAgentWiseListOwner: (state, agentWiseReport) =>
    (state.agentWiseReportListOwner = agentWiseReport),
  setShipWiseListOwner: (state, shipWiseReport) =>
    (state.shipWiseReportListOwner = shipWiseReport),
  setFilterShipWiseListOwner: (state, shipWiseReport) =>
    (state.shipWiseReportListOwner = shipWiseReport),
  setCounterWiseSeatDetailsOwner: (state, seatDetails) =>
    (state.counterWiseSeatDetailsOwner = seatDetails),
  setAgentWiseSeatDetailsOwnerPanel: (state, seatDetails) =>
    (state.agentWiseSeatDetailsOwnerPanel = seatDetails),
  setShipWiseDeckDetailsOwner: (state, deckDetails) =>
    (state.shipWiseDeckDetailsOwner = deckDetails),
  setShipWiseGoodDetailsOwner: (state, goodDetails) =>
    (state.shipWiseGoodDetailsOwner = goodDetails),
  addCoachwiseTripListOwner: (state, coachwiseTrip) =>
    (state.coachwiseTripOwner = coachwiseTrip),
  setShipWiseSeatDetailsOwner: (state, seatDetails) =>
    (state.shipWiseSeatDetailsOwner = seatDetails),
  setChalanDetails: (state, chalanReport) =>
    (state.chalanReport = chalanReport),
  setCoachListInFilterOwner: (state, coachList) =>
    (state.filter.coachOwner = coachList),
  setCounterListInFilterOwnerPanel: (state, counterList) =>
    (state.filter.counterOwnerPanel = counterList),
  setFilterAgentWiseReportOwnerPANEL: (state, updateList) =>
    (state.agentWiseReportListOWNERPanel = updateList),
  setCountReportOwnerPanel: (state, value) => {
    state.printTicketCountListOwner = [];
    state.printTripWiseCountListOwner = [];
    state.printDailySummaryWiseCountListOwner = [];
    if (value.payload.reportType === "ticket") {
      state.printTicketCountListOwner = value.data.ticket;
    } else if (value.payload.reportType === "tripWiseReport") {
      state.printTripWiseCountListOwner = value.data.report;
    } else if (value.payload.reportType === "dailySummaryReport") {
      state.printDailySummaryWiseCountListOwner = value.data.report;
    }
  },
  // setCountReportOwner: (state, value) => {
  //   if (value.payload.reportType === "ticket") {
  //     state.printTripWiseCountListOwner = [];
  //     state.printDailySummaryWiseCountListOwner = [];
  //     state.printTicketCountListOwner = value.data.ticket;
  //   } else if (value.payload.reportType === "tripWiseReport") {
  //     state.printTicketCountListOwner = [];
  //     state.printDailySummaryWiseCountListOwner = [];
  //     state.printTripWiseCountListOwner = value.data.report;
  //   } else if (value.payload.reportType === "dailySummaryReport") {
  //     state.printTicketCountListOwner = [];
  //     state.printTripWiseCountListOwner = [];
  //     state.printDailySummaryWiseCountListOwner = value.data.report;
  //   }
  // },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
