<template>
  <div v-if="reportActive">
    <FilterTab>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
            v-bind:id="'counter'"
            v-bind:label="'Counter'"
            v-bind:defaultOption="'--select counter--'"
            v-model="counterWiseReport.counter"
            v-bind:defaultValue="''"
            v-bind:dropdown="$store.getters.getCounterListForFilterOwnerPanel"
          />
        </div>
        <div class="col-sm-6 col-md-4">
          <DateTimeRangePicker v-model="date.range" />
        </div>
        <div
          class="col-sm-6 col-md-2 col-xxl-1"
        >
          <DropdownFilter
            v-bind:id="'status'"
            v-bind:label="'Status'"
            v-bind:defaultOption="'--select status--'"
            v-model="counterWiseReport.status"
            v-bind:defaultValue="''"
            v-bind:dropdown="dropdownVariables.reportStatus"
          />
        </div>
        <div
          class="col-sm-6 col-md-2 col-xxl-1"
        >
          <CheckboxSingle v-model="isDiscount" />
        </div>
<!--        <div class="col-md-2 mt-auto">-->
<!--          <button class="edit_table_data font_detail" @click="filterReport">-->
<!--            <i class="fas fa-filter"></i><span class="mx-1 bold">{{ t("Filter", {}, { locale: $store.getters.getLang }) }}</span>-->
<!--          </button>-->
<!--        </div>-->
      </template>
      <template v-slot:button2>
        <button class="edit_table_data font_detail" @click="filterReport">
          <i class="fas fa-filter"></i><span class="mx-1 bold">{{ t("Filter", {}, { locale: $store.getters.getLang }) }}</span>
        </button>
      </template>
      <template v-slot:button1>
        <button
          v-if="
            $store.getters.getCounterWiseReportOwner.length > 0 && filterActive
          "
          class="edit_table_data font_detail"
          @click="
            exportExcel(
              'xlsx',
              'Admin_Counter_Wise_Report.',
              undefined,
              'exportable_data'
            )
          "
        >
          <i class="fas fa-download"></i><span class="mx-1 bold">{{ t("Export", {}, { locale: $store.getters.getLang }) }}</span>
        </button>
      </template>
    </FilterTab>
    <Table
      v-if="$store.getters.getCounterWiseReportOwner.length > 0 && filterActive"
      id="exportable_data"
    >
      <template v-slot:header>
        <tr>
          <td class="font_detail">{{ t("SL", {}, { locale: $store.getters.getLang }) }}</td>
          <td class="font_detail">{{ t("Counterman", {}, { locale: $store.getters.getLang }) }}</td>
          <td class="font_detail">{{ t("Counter", {}, { locale: $store.getters.getLang }) }}</td>
          <td class="font_detail">{{ t("Seat Ticket", {}, { locale: $store.getters.getLang }) }}</td>
          <td class="font_detail">{{ t("Seat Amount", {}, { locale: $store.getters.getLang }) }}</td>
          <td class="font_detail">{{ t("Details", {}, { locale: $store.getters.getLang }) }}</td>
        </tr>
      </template>
      <template v-slot:body>
        <tr
          v-for="(item, index) in $store.getters.getCounterWiseReportOwner"
          :key="item._id"
        >
          <td class="font_detail">{{ index + 1 }}</td>
          <td class="font_detail">{{ item?.counterman }}</td>
          <td class="font_detail">{{ item?.counter }}</td>
          <td class="font_detail">{{ item?.seatTicket }}</td>
          <td class="font_detail">{{ item?.seatAmount }} {{ t("Taka", {}, { locale: $store.getters.getLang }) }}</td>
          <td class="font_detail">
            <button
              class="btn btn-primary m-1"
              @click="seatDetails(item.countermanId)"
            >
              {{ t("Seat", {}, { locale: $store.getters.getLang }) }}
            </button>
          </td>
        </tr>
        <tr class="bg-dark text-white">
          <td class="font_detail"></td>
          <td class="font_detail"></td>
          <td class="font_detail"></td>
          <td class="font_detail text-white">
            {{ t("Total Ticket", {}, { locale: $store.getters.getLang }) }}: {{ seatTicketTotal }}
          </td>
          <td class="font_detail text-white">
            {{ t("Total Amount", {}, { locale: $store.getters.getLang }) }}: {{ seatAmountTotal }} {{ t("Taka", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail"></td>
        </tr>
      </template>
    </Table>
  </div>

  <SeatDetails
    v-if="seatDetailsActive"
    :status="parseInt(counterWiseReport.status)"
    :fromDate="date.range.start"
    :toDate="date.range.end"
    :discount="counterWiseReport.discount"
    :countermanId="countermanIdPass"
    v-on:backToCounterWiseReport="onChildClick"
  />
  <router-view></router-view>
</template>

<script>
import DropdownFilter from "@/components/layouts/Filter/Inputs/Dropdown";
import dropdownVariables from "@/modules/dropdownVariables";
import { reactive } from "@vue/reactivity";
import moment from "moment";
import { watch, onBeforeMount, ref, inject } from "vue";
import store from "@/store";
import SeatDetails from "./SeatDetails.vue";
import CheckboxSingle from "@/components/layouts/Inputs/CheckboxSingle.vue";

import { useI18n } from "vue-i18n";
export default {
  name: "ShipReport",
  components: {
    DropdownFilter,
    SeatDetails,
    CheckboxSingle,
  },
  emits: ["backToCounterWiseReport"],

  setup() {
    let seatDetailsActive = ref(false);
    let reportActive = ref(true);
    let countermanIdPass = ref("");
    let filterActive = ref(false);
    let seatTicketTotal = ref(0);
    let seatAmountTotal = ref(0);
    let isDiscount = ref(false);
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const exportExcel = inject("exportExcel");
    const { t, locale } = useI18n();
    const fromDate = moment(new Date()).format("yyyy-MM-DD" + " 00:00:00");
    const toDate = moment(new Date()).format("yyyy-MM-DD" + " 23:59:59");

    let date = reactive({
      range: {
        start: fromDate,
        end: toDate,
      },
    });

    let counterWiseReport = reactive({
      counter: "",
      fromDate: "",
      toDate: "",
      discount: "",
      status: 1,
    });

    const options = {
      texts: {
        count: "",
      },
    };

    watch(
      () => isDiscount.value,
      () => {
        isDiscount.value
          ? (counterWiseReport.discount = 1)
          : (counterWiseReport.discount = "");
      }
    );
    watch(
      () => date.range.start,
      (value) => {
        counterWiseReport.fromDate = moment(value).format(
          "yyyy-MM-DD" + " 00:00:00"
        );
      }
    );
    watch(
      () => date.range.end,
      (value) => {
        counterWiseReport.toDate = moment(value).format(
          "yyyy-MM-DD" + " 23:59:59"
        );
      }
    );
    const getAllCounter = () => {
      showLoader();
      store
        .dispatch("getCounterListForFilterOwnerPanel", {})
        .then(() => hideLoader())
        .catch(() => hideLoader());
    };

    const getAllCounterWiseReport = () => {
      showLoader();
      store
        .dispatch("getfilterCounterWiseReportOwnerPanel", counterWiseReport)
        .then(() => {
          seatTicketTotal.value = 0;
          seatAmountTotal.value = 0;
          sumListData();
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getAllCounterWiseReport();
          }
        });
    };
    const filterReport = () => {
      filterActive.value = true;
      getAllCounterWiseReport();
    };

    const sumListData = () => {
      store.getters.getCounterWiseReportOwner.forEach((data) => {
        seatTicketTotal.value += data.seatTicket;
        seatAmountTotal.value += data.seatAmount;
      });
    };

    onBeforeMount(() => {
      getAllCounter()
      counterWiseReport.fromDate = fromDate;
      counterWiseReport.toDate = toDate;
    });

    const seatDetails = (countermanId) => {
      countermanIdPass.value = countermanId;
      seatDetailsActive.value = true;
      reportActive.value = false;
    };

    const onChildClick = (value) => {
      reportActive.value = value;
      seatDetailsActive.value = !value;
    };

    return {
      t,
      locale,
      dropdownVariables,
      date,
      counterWiseReport,
      filterReport,
      seatDetails,
      seatDetailsActive,
      reportActive,
      countermanIdPass,
      filterActive,
      onChildClick,
      seatAmountTotal,
      seatTicketTotal,
      exportExcel,
      options,
      isDiscount,
    };
  },
};
</script>

<style scoped></style>
