<template>
  <div>
    <FilterTab>
      <!-- <template v-slot:title><h6>Seat Fare</h6></template> -->
      <template v-slot:body>
        <div class="col-sm-6 col-md-2"></div>
      </template>
    </FilterTab>
    <div
      v-if="hasPermission(Owner.ownerCompanySetupWrite)"
      class="card container new-class"
    >
      <div class="card-body">
        <h6 class="card-title bold">
          {{ t("addFare", {}, { locale: $store.getters.getLang }) }}
        </h6>
        <span v-if="error" class="text-danger err_msg">* {{ error }}</span>
        <div class="row">
          <div class="col-md-4">
            <Dropdown
              v-if="status"
              v-model="seatPlan.routeId"
              v-bind:id="'route'"
              v-bind:label="'Route'"
              v-bind:dropdown="$store.getters.getRouteListForFilterOwner"
              v-bind:hideLabel="''"
              v-bind:defaultOption="'--select route--'"
              v-bind:defaultValue="''"
            />
          </div>
          <div class="col-md-4">
            <Dropdown
              v-if="status"
              v-model="seatPlan.status"
              v-bind:id="'status'"
              v-bind:label="'Status'"
              v-bind:dropdown="status"
              v-bind:hideLabel="''"
            />
          </div>
          <div class="col-md-4">
            <!-- <input
              type="submit"
              class="submit_button mt-1 w-50"
              @click.prevent="addNewSeatFare"
            /> -->
            <button
              class="submit_button mt-1 w-50"
              @click.prevent="addNewSeatFare"
            >
              {{ t("Submit", {}, { locale: $store.getters.getLang }) }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div id="accordion" v-if="$store.getters.getOwnerServicesFares">
      <div v-for="fare in $store.getters.getOwnerServicesFares" :key="fare._id">
        <SeatFareCard v-bind:company="fare.companyId" :seatPlanId="fare._id" @getAllSeatFare="getSeatFareList" />
      </div>
    </div>
    <div v-if="$store.getters.getOwnerTotalServicesFares" class="mx-5 my-2">
      <div class="float-end">
        <pagination
          v-model="payload.page"
          :records="$store.getters.getOwnerTotalServicesFares"
          :per-page="payload.limit"
          @paginate="getSeatFareList"
          :options="options"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {reactive} from "@vue/reactivity";
import useInputValidation from "@/modules/useInputValidations";
import dropdownVariables from "@/modules/dropdownVariables";
import {inject, onBeforeMount, ref} from "vue";
import SeatFareCard from "./SeatFareCard";
import store from "@/store";
import FilterTab from "@/components/layouts/Filter/FilterTab";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
const { hasPermission } = permission();
const { Owner } = roles();

export default {
  name: "SeatPlan",
  components: { FilterTab, SeatFareCard },
  setup(props) {
    let { errors } = useInputValidation();
    let shipId = ref(props.ship);
    let companyId = ref(props.company);
    let error = ref("");
    const route = useRoute();
    const status = dropdownVariables.status;
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const { t, locale } = useI18n();
    let seatPlan = reactive({
      companyId: route.query.id,
      routeId: "",
      fareModality: [],
      status: "1",
    });
    let pagination = reactive({
      page: 1,
      limit: 25,
    });
    let payload = reactive({
      page: 1,
      limit: 25,
      companyId: route.query.id,
    });
    const clear = () => {
      seatPlan.routeId = "";
      seatPlan.fareModality = [];
      seatPlan.status = "1";
    };
    const options = {
      texts: {
        count: "",
      },
    };

        const getSeatFareList = () => {
            store.dispatch("getOwnerServicesNewSeatFare", payload);//TODO make it function
            store.dispatch("getRouteListOwner", {companyId: route.query.id});
            store.dispatch("getOwnerServicesAllSeatClass", {
                companyId: route.query.id,
            });
        };
        getSeatFareList(); //TODO mode it to onbeforemount
        const addNewSeatFare = () => {
            showLoader();
            error.value = "";
            store
                .dispatch("addOwnerServicesNewSeatFare", {seatPlan, pagination})
                .then(() => {
                    hideLoader();
                    clear();
                })

                .catch((error) => {
                    hideLoader();
                    if (error.status === 401) {
                        addNewSeatFare();
                    }
                });
        };

        onBeforeMount(() => {
            if (route.query.id) {
                seatPlan.companyId = route.query.id;
            }
        });

    return {
      t,
      locale,
      seatPlan,
      errors,
      status,
      shipId,
      companyId,
      error,
      Owner,
      hasPermission,
      addNewSeatFare,
      getSeatFareList,
      options,
      payload,
    };
  },
};
</script>

<style scoped>
.new-class {
    background: #f3f3f3;
}

.bold {
    font-weight: bold;
}
</style>
