<template>
  <label :for="inputId" class="col-sm-3 col-form-label font_detail"
  >{{ t(inputLabel, {}, {locale: $store.getters.getLang}) }} :</label
  >
  <div class="col-sm-9">
    <input
        v-if="minNumberValidate"
        :id="inputId"
        v-model="input"
        :disabled="disabledProp"
        :placeholder="inputPlaceholder"
        :type="inputType"
        autocomplete="on"
        class="form-control font_detail"
        maxlength="255"
        min="0"
        oninput="this.value = Math.abs(this.value)"
    />
    <input
        v-else
        :id="inputId"
        v-model="input"
        :disabled="disabledProp"
        :placeholder="inputPlaceholder"
        :type="inputType"
        autocomplete="on"
        class="form-control font_detail"
        maxlength="255"
    />
    <slot name="error"></slot>
  </div>
</template>

<script>
import {ref, watch} from "vue";
import {useI18n} from "vue-i18n";

export default {
  name: "Input",
  props: {
    modelValue: [String, Number, Array],
    placeholder: String,
    label: String,
    id: String,
    type: String,
    minNumber: Boolean,
    disabled: Boolean,
  },
  setup(props, {emit}) {
    const inputPlaceholder = ref(props.placeholder);
    const inputLabel = ref(props.label);
    const inputId = ref(props.id);
    const inputType = ref(props.type);
    const input = ref(props.modelValue);
    const disabledProp = ref(props.disabled)
    const {t, locale} = useI18n();
    let minNumberValidate = ref(props.minNumber);
    watch(
        () => minNumberValidate.value,
        (value) => {
          minNumberValidate.value = value;
        }
    );
    watch(
        () => input.value,
        () => {
          emit("update:modelValue", input.value);
        }
    );
    watch(
        () => props.modelValue,
        (value) => {
          input.value = value;
        }
    );
    return {
      t,
      locale,
      inputPlaceholder,
      inputLabel,
      inputId,
      inputType,
      input,
      minNumberValidate,
      disabledProp,
    };
  },
};
</script>

<style scoped></style>
