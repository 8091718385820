import apiResponse from "@/modules/apiResponse";
import tokenCookie from "@/modules/tokenCookie";
import Apis from "@/urls/apis";
import axios from "axios";
import jwt_decode from "jwt-decode";
import SecureLS from "secure-ls";
import dropdownVariables from "../../modules/dropdownVariables";

const {setCookies, removeCookies} = tokenCookie();
const {successResponse, errorResponse} = apiResponse();
const ls = new SecureLS({isCompression: false});

const state = {
    lang: 'en',
    authentication: {
        isLoggedIn: false,
        // authToken: null,
        userType: null,
    },
    user: {},
    tracker: {
        activeTab: "",
    },
};
const getters = {
    isLoggedIn: (state) => state.authentication.isLoggedIn,
    getLang: (state) => state.lang,
    // authToken: (state) => state.authentication.authToken,
    getUserInfo: (state) => state.user,
    userType: (state) => state.authentication.userType,
    userName: (state) => state.user.name,
    userId: (state) => state.user._id,
    maxSeatPerTicket: (state) => state.user.company.maxSeatPerTicket,
    allowPass: (state) => state.user.company.allowPass,
    allowDue: (state) => state.user.company.allowDue,
    allowDiscount: (state) => state.user.company.allowDiscount,
    userCompany: (state) => state.user.company._id,
    userCompanyData: (state) => state.user.company,
    userCompanyCounter: (state) => state.user.counter,
    userRoles: (state) => state.user.roles,
    agentBalance: (state) => state.user.balance,
    getUser: (state) => state.user,
    getActiveTab: (state) => state.tracker.activeTab,
    userFareType: (state) => state.user.company.fareType,
    allowNetPayEdit: (state) => state.user.company.allowNetPayEdit,
    reservationStatus: (state) => state.user.company.reservationStatus,
    reservationCancelTime: (state) => state.user.company.reservationCancelTime,
};
const actions = {
    async login({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(Apis.LOGIN, payload)
                .then((response) => {
                    successResponse(response);
                    setCookies(response.data);
                    commit("setState", response.data);
                    resolve(response.data.message);
                })
                .catch((error) => {
                    reject(errorResponse(error));
                });
        });
    },
    
    async refreshToken({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(Apis.REFRESH, payload)
                .then(({data, status}) => {
                    if (status === 200) {
                        commit("setState", data);
                        setCookies(data);
                        resolve(data.message);
                    }
                })
                .catch((error) => {
                    errorResponse(error);
                    reject(false);
                });
        });
    },
    
    async logout() {
        ls.remove("intercity"); // TODO have to check a better solution
        removeCookies();
        this.reset();
    },
    
    // async logout({commit, rootGetters}) {
    //     let authToken = rootGetters.authToken
    //     const header = {
    //         headers: {
    //             'Authorization': 'Bearer ' + authToken,
    //         }
    //     }
    //     return new Promise((resolve, reject) => {
    //         axios
    //             .post(
    //                 Apis.LOGOUT, null,
    //                 header
    //             )
    //             .then(({data, status}) => {
    //                 if (status === 200) {
    //                     console.log(data);
    //                     commit("setIsLoggedIn",false);
    //                     resolve({'success': 'Successfully Logout'});
    //                 } else {
    //                     resolve({'error': status});
    //                 }
    //             })
    //             .catch(error => {
    //                 reject({'error': error});
    //             });
    //     });
    // },
};
const mutations = {
    setActiveTab: (state, tab) => (state.tracker.activeTab = tab),
    setState: (state, data) => {
        state.user = jwt_decode(data.token);
        state.authentication.isLoggedIn = true;
        // state.authentication.authToken = data.token
        state.authentication.userType =
            dropdownVariables.userType[state.user.userType];
    },
    updateBalance: (state, newBalance) => (state.user.balance = newBalance),
    setLang: (state, lang) => (state.lang = lang)
};

export default {
    state,
    getters,
    actions,
    mutations,
};
