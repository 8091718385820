<template>
  <div>
    <FilterTab>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2"></div>
      </template>
      <template v-slot:button2>
        <button class="edit_table_data font_detail" @click="goBack">
          <i class="fas fa-arrow-left"></i
          ><span class="mx-1 bold">Go Back</span>
        </button>
      </template>
      <template v-slot:button1>
        <button
          class="edit_table_data font_detail"
          @click="exportTable('xlsx')"
        >
          <i class="fas fa-download"></i><span class="mx-1 bold">Export</span>
        </button>
      </template>
    </FilterTab>
  </div>
  <div
    class="card h-100 mt-4"
    v-for="(itemValue, itemKey) in classWiseReport"
    :key="itemKey"
  >
    <div class="card-header text-center bg-white" v-if="classWiseReport">
      <div class="row">
        <div class="col-6">
          <h5 class="mb-0 text-uppercase">
            Trip Code: {{ itemValue.trip.tripCode }}
          </h5>
        </div>
        <div class="col-6">
          <h5 class="mb-0 text-uppercase">
            Trip Date Time: {{ tripDateFormat(itemValue.trip.tripDateTime) }}
          </h5>
        </div>
      </div>
    </div>

    <div class="card-body mt-0 py-0" v-if="classWiseReport">
      <table-simple :id="itemKey">
        <template v-slot:header>
          <tr>
            <td class="font_detail">SL</td>
            <td class="font_detail">Ticket Date Time</td>

            <td class="font_detail">Coach Name</td>
            <td class="font_detail">PNR Code</td>
            <td class="font_detail">Passenger Name</td>
            <td class="font_detail">Passenger Phone</td>
            <td class="font_detail">Boarding Place</td>
            <td class="font_detail">Dropping Place</td>
            <td class="font_detail">Seat Numbers</td>
            <td class="font_detail">Total Ticket</td>
            <td class="font_detail">Total Amount</td>
            <td v-if="coachWiseSeatReport.discount" class="font_detail">
              Discount
            </td>
            <td class="font_detail">Issue By</td>
            <td class="font_detail" v-if="cancelledBy === 0">Cancelled By</td>
            <td class="font_detail" v-if="cancelledBy === 0">
              Cancellation Cause
            </td>
          </tr>
        </template>
        <template v-slot:body>
          <tr v-for="(item, index) in itemValue.totalReport" :key="index">
            <td class="font_detail">
              {{ index + 1 }}
            </td>
            <td class="font_detail">{{ dateFormat(item?.ticketDateTime) }}</td>

            <td class="font_detail">{{ coachNameProps }}</td>
            <td class="font_detail">{{ item?.pnrCode }}</td>
            <td class="font_detail">{{ item?.passengerName }}</td>
            <td class="font_detail">{{ item?.passengerPhone }}</td>
            <td class="font_detail">{{ item?.boardingPlace }}</td>
            <td class="font_detail">{{ item?.droppingPoint }}</td>
            <td class="font_detail">
              {{ item?.seatNumbers.toString() }}
            </td>

            <td class="font_detail">{{ item?.totalSeat }}</td>
            <td class="font_detail">
              {{
                coachWiseSeatReport.discount === 1
                  ? item?.totalAmount
                  : item?.payable
              }}
            </td>
            <td v-if="coachWiseSeatReport.discount" class="font_detail">
              {{ item?.discount }}
            </td>
            <td class="font_detail">{{ item?.issuedBy?.name }}</td>
            <td class="font_detail" v-if="cancelledBy === 0">
              {{ item?.cancelledBy?.name }}
            </td>
            <td class="font_detail" v-if="cancelledBy === 0">
              {{ item?.cancellationCause }}
            </td>
          </tr>
          <tr class="bg-dark text-white">
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail text-white">
              Total Ticket: {{ itemValue.totalTicket }}
            </td>
            <td class="font_detail text-white">
              Total Amount: {{ itemValue.totalAmount }} Taka
            </td>
            <td
              v-if="coachWiseSeatReport.discount"
              class="font_detail text-white"
            >
              Total Discount: {{ itemValue.totalDiscount }} Taka
            </td>
            <td class="font_detail"></td>
            <td class="font_detail" v-if="cancelledBy === 0"></td>
            <td class="font_detail" v-if="cancelledBy === 0"></td>
          </tr>
        </template>
      </table-simple>
    </div>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { ref, onBeforeMount, inject } from "vue";
import store from "@/store";
import moment from "moment";
import TableSimple from "@/components/layouts/Tables/TableSimple.vue";

export default {
  name: "SeatDetails",
  components: { TableSimple },
  props: {
    coachId: String,
    coachName: String,
    status: Number,
    discount: [Number, String],
    fromDate: String,
    toDate: String,
  },
  emits: ["backToCoachWiseReport"],
  setup(props, { emit }) {
    let coach = ref(props.coachId);
    let coachNameProps = ref(props.coachName);
    let totalAmountSeat = ref(0);
    let totalTicket = ref(0);
    let totalDiscount = ref(0);
    let summary = ref(false);
    let cancelledBy = ref(props.status);
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const exportExcel = inject("exportExcel");

    let coachWiseSeatReport = reactive({
      ticketType: "SEAT",
      coach: coach.value,
      fromDate: props.fromDate,
      toDate: props.toDate,
      discount: props.discount,
      status: props.status,
    });

    let classWiseReport = reactive({});

    const options = {
      texts: {
        count: "",
      },
    };

    const dateFormat = (date) => {
      return moment(new Date(String(date)))
        .utcOffset(0, true)
        .format("DD-MMM-yyyy hh:mm A");
    };

		const tripDateFormat = (date) => {
      return moment(new Date(String(date)))
        .utcOffset(0, false)
        .format("DD-MMM-yyyy hh:mm A");
    };

    const getSeatDetails = () => {
      showLoader();
      store.dispatch("getSeatDetailsOwner", coachWiseSeatReport).then((response) => {
        hideLoader();
        if (response.length !== 0) {
          classifyByClassName(response);
        } else {
          summary.value = false;
        }
      }).catch((error) => {
          hideLoader();
          if (error.status === 401) {
              getSeatDetails();
          }
      });
    };

    const classifyByClassName = (reports) => {
      reports.forEach((report) => {
        if (report.trip._id in classWiseReport) {
          classWiseReport[report.trip._id].totalReport.push(report);
        } else {
          classWiseReport[report.trip._id] = {
            totalReport: [],
            totalTicket: 0,
            totalAmount: 0,
            totalDiscount: 0,
						trip: report.trip,
          };
          classWiseReport[report.trip._id].totalReport.push(report);
        }
      });
      seatDetailsdata();
      summary.value = true;
    };

    const seatDetailsdata = () => { //TODO typo
      for (const key in classWiseReport) {
        classWiseReport[key].totalReport.forEach((data) => {
          classWiseReport[key].totalAmount +=
            coachWiseSeatReport.discount === 1
              ? data.totalAmount
              : data.payable;
          classWiseReport[key].totalTicket += data.totalSeat;
          classWiseReport[key].totalDiscount += data.discount;
        });
      }
    };

    const goBack = () => {
      emit("backToCoachWiseReport", true);
    };

    const exportTable = (type) => {
      for (const key in classWiseReport) {
        exportExcel(
          type,
          key + "_Admin_CoachWise_Seat_Report.",
          undefined,
          key
        );
      }
    };

    onBeforeMount(() => {
      getSeatDetails();
    });

    return {
      coachWiseSeatReport,
      totalAmountSeat,
      goBack,
      exportTable,
      totalTicket,
      coachNameProps,
      summary,
      getSeatDetails,
      options,
      cancelledBy,
      totalDiscount,
      dateFormat,
      classWiseReport,
			tripDateFormat
    };
  },
};
</script>
<style scoped>
h3,
h5 {
  font-size: 36px;
  color: #f04935;
}
h5 {
  font-size: 18px;
}
</style>
