<template>
  <Modal @close="closeModal">
    <template v-slot:title>{{
      t(title, {}, { locale: $store.getters.getLang })
    }}</template>
    <template v-slot:body>
      <form>
        <div class="form-group row my-1">
          <Input
            v-model="seatPlanData.name"
            v-bind:placeholder="'Enter Name'"
            v-bind:label="'Name'"
            v-bind:id="'name'"
            v-bind:type="'text'"
          >
            <template v-slot:error>
              <Error v-if="errors.name" v-bind:error="errors.name" />
            </template>
          </Input>
        </div>
        <div class="form-group row my-1">
          <ModalDropdown
            v-if="status"
            v-model="seatPlanData.status"
            v-bind:id="'status'"
            v-bind:label="'Status'"
            v-bind:dropdown="status"
          ></ModalDropdown>
        </div>
        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <!-- <input
              type="submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="updateSeatPlan"
            /> -->
            <button
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="updateSeatPlan"
            >
              {{ t("Submit", {}, { locale: $store.getters.getLang }) }}
            </button>
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import Input from "@/components/layouts/Modals/Inputs/Input";
import dropdownVariables from "@/modules/dropdownVariables";
import Error from "@/components/layouts/Modals/Inputs/Error";
import Modal from "@/components/layouts/Modals/Modal";
import useInputValidation from "@/modules/useInputValidations";
import { inject, onBeforeMount, ref, watch } from "vue";
import { reactive } from "@vue/reactivity";
import store from "@/store";
// import Dropdown from "@/components/layouts/Modals/Inputs/Dropdown";
import { useI18n } from "vue-i18n";
export default {
  name: "SeatClassUpdateModal",
  components: { Modal, Input, Error },
  props: {
    ship: String,
    company: String,
    seatClass: String,
  },
  setup(props, { emit }) {
    let seatPlanData = reactive({
      id: "",
      companyId: "",
      name: "",
      seatList: [],
      status: 1,
    });
    let { requiredValidation, validNumberValidation, errors } =
      useInputValidation();
    const companyId = ref(props.company);
    const seatPlanId = ref(props.seatClass);
    const status = dropdownVariables.status;
    const title = ref("Update Seat Plan");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const { t, locale } = useI18n();
    watch(
      () => seatPlanData.name,
      () => {
        requiredValidation("name", seatPlanData.name);
      }
    );

    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      requiredValidation("name", seatPlanData.name);
      validNumberValidation("upFare", seatPlanData.upFare);
      validNumberValidation("downFare", seatPlanData.downFare);
      return !(
        Object.values(errors).length <= 0 ||
        !Object.values(errors).every((x) => x === null || x === "")
      );
    };
    const closeModal = () => {
      emit("update:modelValue", false);
    };
    const updateSeatPlan = () => {
      if (validate()) {
        showLoader();
        store
          .dispatch("updateSeatPlanOwnerPanel", seatPlanData)
          .then(() => {
            hideLoader();
            closeModal();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              updateSeatPlan();
            }
          });
      }
    };

    onBeforeMount(() => {
      Object.keys(errors).forEach(function (key) {
        delete errors[key];
      });
      const seatPlanDetails = store.getters.getSeatPlan(seatPlanId.value);
      seatPlanData.id = seatPlanDetails[0]._id;
      seatPlanData.name = seatPlanDetails[0].name;
      seatPlanData.status = seatPlanDetails[0].status ? 1 : 0;
      seatPlanData.companyId = companyId.value;
    });

    return {
      t,
      locale,
      seatPlanId,
      seatPlanData,
      errors,
      status,
      title,
      closeModal,
      updateSeatPlan,
    };
  },
};
</script>

<style scoped></style>
