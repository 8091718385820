import store from "@/store";
import web from "@/urls/web";
import roles from "./roles";
import tokenCookie from "@/modules/tokenCookie";
import SecureLS from "secure-ls";
const ls = new SecureLS({isCompression: false});

const {Admin, Owner, Counterman,Agent} = roles();
const { removeCookies } = tokenCookie();

export default function permission() {
    const init = () => {
        let routes = null;
        if (store.getters.userType === 'Admin') {
            routes = web.Admin;
        } else if (store.getters.userType === 'Owner') {
            routes = web.Owner;
        } else if (store.getters.userType === 'Counterman') {
            routes = web.Counterman;
        } else if (store.getters.userType === 'Agent') {
            routes = web.Agent;
        }
        return routes;
    };

    const getRoute = (role) => {
        if (init()) {
            for (const url of Object.values(init())) {
                if (url.permission && url.permission === role) {
                    return url.name;
                }
            }
        }
        return web.Default.ERROR_URL.name;
    };

    const hasPermission = (role) => {
        if (store.getters.userRoles) {
            for (const typeValues of Object.values(store.getters.userRoles)) {
                if (typeValues === role) {
                    return true;
                }
            }
        }
        return false;
    };

    const permissionGranted = (name) => {
        if (init()) {
            for (const url of Object.values(init())) {
                if (name.includes(url.name) && url.permission && hasPermission(url.permission)) {
                    return true;
                }
            }
        }
        return false;
    };

    const permittedRoute = () => {
        let supportedTypes = null;
        let routeFoundCheck = false;
        if (store.getters.userType === 'Admin') {
            supportedTypes = [Admin.dashboard, Admin.reports, Admin.adminRead, Admin.companyDeployRead];
        } else if (store.getters.userType === 'Owner') {
            supportedTypes = [Owner.ownerDashboard, Owner.ownerReports, Owner.ownerRead, Owner.ownerCompanySetupRead];
        } else if (store.getters.userType === 'Counterman') {
            supportedTypes = [Counterman.ticketBooking, Counterman.reports, Counterman.trips, Counterman.coach];
        } else if (store.getters.userType === 'Agent') {
            supportedTypes = [Agent.ticketBooking, Agent.reports, Agent.trips, Agent.coach];
        }
        if (supportedTypes) {
            for (let i = 0; i < supportedTypes.length; i++) {
                if (hasPermission(supportedTypes[i])) {
                    routeFoundCheck = true
                    return getRoute(supportedTypes[i]);
                }
            }
        }
        if(!routeFoundCheck){
            removeCookies()
            store.reset()
            ls.remove('intercity')
        }
        return web.Default.ERROR_URL.name;
    };

    return {
        hasPermission, permissionGranted, permittedRoute
    };
}