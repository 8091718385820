import apiResponse from "@/modules/apiResponse";
import tokenCookie from "@/modules/tokenCookie";
import Apis from "@/urls/apis";
import axios from "axios";

const {getToken} = tokenCookie();
const {errorResponse} = apiResponse();
const {hasValidToken} = tokenCookie();
const state = {
    dashboardTicketsOwner: [],
};
const getters = {
    getDashboardTicketsOwner: (state) => state.dashboardTicketsOwner,
};
const actions = {

    async getDashboardTicketsOwner({commit}, payload) {
        if (await hasValidToken()) {
            commit("setDashboardTicketsOwner", []);
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.get(Apis.OWNER_DASHBOARD, {
                    params: payload,
                    headers: header
                })
                    .then((response) => {
                        commit("setDashboardTicketsOwner", response.data.ticket);
                        resolve({'success': 'success'});
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },




};
const mutations = {
    setDashboardTicketsOwner: (state, tickets) => (state.dashboardTicketsOwner = tickets),
};

export default {
    state,
    getters,
    actions,
    mutations
};
