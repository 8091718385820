const API_URL = process.env.VUE_APP_API;

// Auth  start
const api = "/api";


const LOGIN = API_URL + api +"/auth/login"; // implemented tushar
const REFRESH = API_URL + api + "/auth/refresh-token"; // implemented tushar
const LOGOUT = API_URL + "/logout"; // implemented tushar
// Owner Start
const OWNER_PRINT_COUNT_REPORT =
    API_URL + api + "/owner/report/print-count-report"; // implemented tushar
const AGENT_WISE_REPORT_OWNER_PANEL_DETAILS_API =
    API_URL + api + "/owner/report/agent-wise-report-details"; // implemented tushar
const AGENT_WISE_REPORT_OWNER_PANEL =
    API_URL + api + "/owner/report/agent-wise-report"; // implemented tushar
const COUNTERMAN_WISE_REPORT_DETAILS_OWNER =
    API_URL + api + "/owner/report/counter-wise-report-details"; //arif
const GET_ALL_COUNTERS_IN_FILTER_OWNER_PANEL =
    API_URL + api + "/owner/services/counter/all/";
const COUNTER_WISE_REPORT_OWNER_PANEL =
    API_URL + api + "/owner/report/counter-wise-report";
const COACH_WISE_SEAT_REPORT_DETAILS_OWNER =
    API_URL + api + "/owner/report/coach-wise-report-details"; // implemented tushar
const COACH_WISE_REPORT_OWNER = API_URL + api + "/owner/report/coach-wise-report"; // implemented tushar
const OWNER_DASHBOARD = API_URL + api + "/owner/dashboard"; // implemented tushar
const CHALAN_TRIP_WISE_REPORT_OWNER =
    API_URL + api + "/owner/report/trip-wise-report"; // implemented tushar
const GET_ALL_COACHWISE_TRIP_OWNER =
    API_URL + api + "/owner/services/trip/coach-wise-trip"; // implemented tushar
const GET_ALL_OWNERS_PANEL = API_URL + api + "/owner/owner/"; //arif
const ADD_OWNER_PANEL = API_URL + api + "/owner/owner/"; //arif
const UPDATE_OWNER_PANEL = API_URL + api + "/owner/owner/"; //arif
const GET_OWNER_SERVICES_STOPAGE = API_URL + api + "/owner/services/stoppage"; //arif
const GET_OWNER_SERVICES_ROUTE = API_URL + api + "/owner/services/route"; //arif
const GET_ALL_OWNER_SERVICES_STOPPAGE =
    API_URL + api + "/owner/services/stoppage/all"; //arif
const GET_ALL_COUNTERS_OWNER = API_URL + api + "/owner/services/counter"; //arif
const GET_ALL_COUNTER_LIST_OWNER = API_URL + api + "/owner/services/counter/all"; //ARIF
const GET_ALL_STOPPAGES_OWNER = API_URL + api + "/owner/services/stoppage/all"; //arif
const ADD_COUNTER_OWNER = API_URL + api + "/owner/services/counter"; //arif
const UPDATE_COUNTER_OWNER = API_URL + api + "/owner/services/counter"; //arif
const GET_ALL_COUNTERMANS_OWNER = API_URL + api + "/owner/services/counterman"; //arif
const ADD_COUNTERMAN_OWNER = API_URL + api + "/owner/services/counterman"; //arif
const UPDATE_COUNTERMAN_OWNER = API_URL + api + "/owner/services/counterman"; //arif
const GET_ALL_ROUTES_OWNER = API_URL + api + "/owner/services/route/all"; //arif
const GET_ROUTES_OWNER = API_URL + api + "/owner/services/route"; //arif
const GET_SEAT_CLASS_OWNER = API_URL + api + "/owner/services/seat-class"; //arif
const ADD_SEAT_CLASS_OWNER = API_URL + api + "/owner/services/seat-class"; //arif
const UPDATE_SEAT_CLASS_OWNER = API_URL + api + "/owner/services/seat-class"; //arif
const ADD_SEAT_ROW_OWNER = API_URL + api + "/owner/services/seat-plan/seat-row"; //arif
const UPDATE_SEAT_ROW_OWNER =
    API_URL + api + "/owner/services/seat-plan/seat-row"; //arif
const DELETE_SEAT_ROW_OWNER =
    API_URL + api + "/owner/services/seat-plan/seat-row"; //arif
const GET_SEAT_PLAN_OWNER = API_URL + api + "/owner/services/seat-plan"; //arif
const ADD_SEAT_PLAN_OWNER = API_URL + api + "/owner/services/seat-plan"; //arif
const UPDATE_SEAT_PLAN_OWNER = API_URL + api + "/owner/services/seat-plan"; //arif
const GET_ALL_SEAT_PLAN_OWNER = API_URL + api + "/owner/services/seat-plan/all"; //arif
const GET_SEAT_FARE_OWNER = API_URL + api + "/owner/services/fare"; //arif
const DELETE_FARE_OWNER = API_URL + api + "/owner/services/fare"; //arif
const UPDATE_FARE_OWNER = API_URL + api + "/owner/services/fare/update"; //arif
const ADD_SEAT_FARE_OWNER = API_URL + api + "/owner/services/fare"; //arif
const SEQUENCE_ROUTE_FILTER_OWNER =
    API_URL + api + "/owner/services/route/route-sequence"; //arif
const ADD_FARE_MODALITY_OWNER = API_URL + api + "/owner/services/fare/fare-row"; //arif
const UPDATE_FARE_MODALITY_OWNER =
    API_URL + api + "/owner/services/fare/fare-row"; //arif
const DELETE_FARE_MODALITY_OWNER =
    API_URL + api + "/owner/services/fare/fare-row"; //arif
const GET_ALL_COACH_OWNER = API_URL + api + "/owner/services/coach"; //arif
const ADD_COACH_OWNER = API_URL + api + "/owner/services/coach"; //arif
const UPDATE_COACH_OWNER = API_URL + api + "/owner/services/coach"; //arif
const GET_ALL_ROUTES_NEW_OWNER = API_URL + api + "/owner/services/route"; //ARIF
const GET_TRIP_DATA_OWNER = API_URL + api + "/owner/services/trip"; // ARIF
const GET_ALL_STOPPAGES_OWNER_NEW = API_URL + api + "/owner/services/stoppage"; // ARIF
const GET_ALL_ROUTES_FILTER_OWNER = API_URL + api + "/owner/services/route/all"; // ARIF
const GET_ALL_STOPPAGE_OWNER = API_URL + api + "/owner/services/stoppage/all"; //ARIF
const GET_CHALAN_REPORT = API_URL + api + "/owner/report/chalan-report"; // implemented tushar
const GET_ALL_LIST_COACH_OWNER = API_URL + api + "/owner/services/coach/all"; // implemented tushar

// Owner end
const GET_ALL_DASHBOARD_TICKETS_OWNER = API_URL + api + "/owner/dashboard";
const SHIP_WISE_REPORT_OWNER =
    API_URL + api + "/owner/report/ship-wise-report";
const GET_ALL_SHIPS_OWNER = API_URL + api + "/owner/ship";
const GET_ALL_OWNER_SHIPS_BY_DECK = API_URL + api + "/owner/ship/by-deck";
const GET_ALL_COUNTER_OWNER = API_URL + api + "/owner/counter";
const SHIP_WISE_DECK_REPORT_DETAILS_OWNER =
    API_URL + api + "/owner/report/ship-wise-report-details";
const SHIP_WISE_GOOD_REPORT_DETAILS_OWNER =
    API_URL + api + "/owner/report/ship-wise-report-details";
const SHIP_WISE_SEAT_REPORT_DETAILS_OWNER =
    API_URL + api + "/owner/report/ship-wise-report-details";
const COUNTER_WISE_REPORT_OWNER =
    API_URL + api + "/owner/report/counter-wise-report";
const COUNTER_WISE_DETAILS_REPORT_OWNER =
    API_URL + api + "/owner/report/counter-wise-report-details";
const AGENT_WISE_REPORT_OWNER =
    API_URL + api + "/owner/report/agent-wise-report";
const AGENT_WISE_REPORT_DETAILS_OWNER =
    API_URL + api + "/owner/report/agent-wise-report-details";
const GET_OWNER_SERVICES_BOARDING_PLACE =
    API_URL + api + "/owner/services/route/boarding-point";
const GET_OWNER_SERVICES_DROPPING_PLACE =
    API_URL + api + "/owner/services/route/dropping-point";
const GET_OWNER_SERVICES_TRIPS = API_URL + api + "/owner/trip";
const GET_ALL_OWNER_SERVICES_SHIPS_BY_ROUTE =
    API_URL + api + "/owner/ship/by-route";
const GET_ALL_OWNER_SERVICES_SHIP_INFO = API_URL + api + "/owner/ship/info";
const AGENT_LIST_OWNER = API_URL + api + "/owner/agent";
const AGENT_ADD_OWNER = API_URL + api + "/owner/agent";
const GET_ALL_DECKMANS_OWNER = API_URL + api + "/owner/deckman";
const ADD_DECKMAN_OWNER = API_URL + api + "/owner/deckman";
const UPDATE_DECKMAN_OWNER = API_URL + api + "/owner/deckman";
const ADD_SHIP_OWNER = API_URL + api + "/owner/ship";
const UPDATE_SHIP_OWNER = API_URL + api + "/owner/ship";
const GET_ALL_SEAT_CLASS_OWNER = API_URL + api + "/owner/services/seat-class/all";
const DELETE_SEAT_CLASS_OWNER =
    API_URL + api + "/owner/seat-plan/delete-class";

const ADD_CUSTOM_SEAT_ROW_BY_OWNER =
    API_URL + api + "/owner/seat-plan/add-custom-seat";
const EDIT_CUSTOM_SEAT_ROW_BY_OWNER =
    API_URL + api + "/owner/seat-plan/edit-seat-row";
const SEAT_BLOCK_OWNER = API_URL + api + "/owner/seat-plan/seat-blocking";

const AGENT_RECHARGE_BALANCE =
    API_URL + api + "/owner/services/agent/balance-recharge";
const PRINT_COUNT_OWNER =
    API_URL + api + "/owner/report/print-count-report";
const GET_AGENTS_OWNER = API_URL + api + "/owner/services/agent";
const ADD_AGENT_OWNER = API_URL + api + "/owner/services/agent";
const UPDATE_AGENT_OWNER = API_URL + api + "/owner/services/agent";

const GET_SUPERVISORS_OWNER = API_URL + api + "/owner/services/supervisor";
const ADD_SUPERVISOR_OWNER = API_URL + api + "/owner/services/supervisor";
const UPDATE_SUPERVISOR_OWNER = API_URL + api + "/owner/services/supervisor";
const GET_DRIVERS_OWNER = API_URL + api + "/owner/services/driver";
const ADD_DRIVER_OWNER = API_URL + api + "/owner/services/driver";
const UPDATE_DRIVER_OWNER = API_URL + api + "/owner/services/driver";
const GET_TRIPS_OWNER = API_URL + api + "/owner/services/trip";
const CANCEL_TRIP_OWNER = API_URL + api + "/owner/services/trip/cancel";
const GET_FARE_ALL_BY_ROUTED_OWNER = API_URL + api + "/owner/services/fare";

const ADD_TRIP_OWNER = API_URL + api + "/owner/services/trip";
const UPDATE_TRIP_OWNER = API_URL + api + "/owner/services/trip";

const GET_FARE_ALL_BY_ROUTEID_OWNER = API_URL + api + "/owner/services/fare/all";
const ADD_TRIP_DATA_OWNER = API_URL + api + "/owner/services/trip";
const UPDATE_TRIP_DATA_OWNER = API_URL + api + "/owner/services/trip";
const ROUTE_FARE_DELETE_OWNER = API_URL + api + "/owner/services/fare/fare-row";

const GET_ALL_OWNER_SERVICES_BOARDING_PLACES_FOR_STOPPAGE =
    API_URL + api + "/owner/services/stoppage/boarding-point"; //arif
const ADD_OWNER_SERVICES_BOARDING_PLACE_FOR_STOPPAGE =
    API_URL + api + "/owner/services/stoppage/boarding-point"; //arif
const UPDATE_OWNER_SERVICES_BOARDING_PLACE_FOR_STOPPAGE =
    API_URL + api + "/owner/services/stoppage/boarding-point"; //arif
const DELETE_OWNER_SERVICES_BOARDING_PLACE_FOR_STOPPAGE =
    API_URL + api + "/owner/services/stoppage/boarding-point"; //arif
const GET_ALL_OWNER_SERVICES_DROPPING_PLACES_FOR_STOPPAGE =
    API_URL + api + "/owner/services/stoppage/dropping-point"; //arif
const ADD_OWNER_SERVICES_DROPPING_PLACE_FOR_STOPPAGE =
    API_URL + api + "/owner/services/stoppage/dropping-point"; //arif
const UPDATE_OWNER_SERVICES_DROPPING_PLACE_FOR_STOPPAGE =
    API_URL + api + "/owner/services/stoppage/dropping-point"; //arif

const DELETE_OWNER_SERVICES_DROPPING_PLACE_FOR_STOPPAGE =
    API_URL + api + "/owner/services/stoppage/dropping-point"; //arif

const GET_ALL_DEPOTS_OWNER = API_URL + "/api/owner/services/depot/all";

// Extra end

export default {
    LOGIN,
    LOGOUT,
    REFRESH,
    GET_ALL_STOPPAGES_OWNER_NEW,
    GET_ALL_DASHBOARD_TICKETS_OWNER,
    SHIP_WISE_REPORT_OWNER,
    GET_ALL_SHIPS_OWNER,
    SHIP_WISE_DECK_REPORT_DETAILS_OWNER,
    SHIP_WISE_GOOD_REPORT_DETAILS_OWNER,
    SHIP_WISE_SEAT_REPORT_DETAILS_OWNER,
    COUNTER_WISE_REPORT_OWNER,
    GET_ALL_OWNER_SHIPS_BY_DECK,
    GET_ALL_COUNTER_OWNER,
    COUNTER_WISE_DETAILS_REPORT_OWNER,
    AGENT_WISE_REPORT_OWNER,
    AGENT_WISE_REPORT_DETAILS_OWNER,
    GET_ALL_OWNERS_PANEL,
    ADD_OWNER_PANEL,
    UPDATE_OWNER_PANEL,
    AGENT_LIST_OWNER,
    AGENT_ADD_OWNER,
    GET_ALL_DECKMANS_OWNER,
    ADD_DECKMAN_OWNER,
    UPDATE_DECKMAN_OWNER,
    GET_ALL_COUNTERS_OWNER,
    GET_ALL_STOPPAGES_OWNER,
    ADD_COUNTER_OWNER,
    UPDATE_COUNTER_OWNER,
    GET_ALL_COUNTERMANS_OWNER,
    ADD_COUNTERMAN_OWNER,
    UPDATE_COUNTERMAN_OWNER,
    GET_ALL_ROUTES_OWNER,
    ADD_SHIP_OWNER,
    UPDATE_SHIP_OWNER,
    GET_SEAT_CLASS_OWNER,
    ADD_SEAT_CLASS_OWNER,
    ADD_SEAT_ROW_OWNER,
    UPDATE_SEAT_ROW_OWNER,
    ADD_CUSTOM_SEAT_ROW_BY_OWNER,
    EDIT_CUSTOM_SEAT_ROW_BY_OWNER,
    SEAT_BLOCK_OWNER,
    DELETE_SEAT_CLASS_OWNER,
    UPDATE_SEAT_CLASS_OWNER,
    GET_OWNER_SERVICES_STOPAGE,
    GET_OWNER_SERVICES_ROUTE,
    GET_OWNER_SERVICES_BOARDING_PLACE,
    GET_OWNER_SERVICES_DROPPING_PLACE,
    GET_OWNER_SERVICES_TRIPS,
    GET_ALL_OWNER_SERVICES_STOPPAGE,
    GET_ALL_OWNER_SERVICES_SHIPS_BY_ROUTE,
    GET_ALL_OWNER_SERVICES_SHIP_INFO,
    DELETE_SEAT_ROW_OWNER,
    AGENT_RECHARGE_BALANCE,
    PRINT_COUNT_OWNER,
    GET_ALL_STOPPAGE_OWNER,
    GET_SEAT_PLAN_OWNER,
    ADD_SEAT_PLAN_OWNER,
    UPDATE_SEAT_PLAN_OWNER,
    GET_ALL_SEAT_PLAN_OWNER,
    GET_SEAT_FARE_OWNER,
    ADD_SEAT_FARE_OWNER,
    DELETE_FARE_OWNER,
    UPDATE_FARE_OWNER,
    SEQUENCE_ROUTE_FILTER_OWNER,
    GET_ALL_SEAT_CLASS_OWNER,
    ADD_FARE_MODALITY_OWNER,
    UPDATE_FARE_MODALITY_OWNER,
    DELETE_FARE_MODALITY_OWNER,
    GET_AGENTS_OWNER,
    ADD_AGENT_OWNER,
    UPDATE_AGENT_OWNER,
    ADD_COACH_OWNER,
    UPDATE_COACH_OWNER,
    GET_ALL_COACH_OWNER,
    CANCEL_TRIP_OWNER,
    GET_ALL_COUNTER_LIST_OWNER,
    GET_SUPERVISORS_OWNER,
    ADD_SUPERVISOR_OWNER,
    UPDATE_SUPERVISOR_OWNER,
    GET_DRIVERS_OWNER,
    ADD_DRIVER_OWNER,
    UPDATE_DRIVER_OWNER,
    GET_TRIPS_OWNER,
    GET_ALL_LIST_COACH_OWNER,
    GET_FARE_ALL_BY_ROUTED_OWNER,
    ADD_TRIP_OWNER,
    UPDATE_TRIP_OWNER,
    GET_ROUTES_OWNER,
    GET_ALL_ROUTES_NEW_OWNER,
    GET_TRIP_DATA_OWNER,
    GET_ALL_ROUTES_FILTER_OWNER,
    GET_FARE_ALL_BY_ROUTEID_OWNER,
    ADD_TRIP_DATA_OWNER,
    UPDATE_TRIP_DATA_OWNER,
    ROUTE_FARE_DELETE_OWNER,
    GET_ALL_COACHWISE_TRIP_OWNER,
    CHALAN_TRIP_WISE_REPORT_OWNER,
    GET_CHALAN_REPORT,
    OWNER_DASHBOARD,
    COACH_WISE_REPORT_OWNER,
    COACH_WISE_SEAT_REPORT_DETAILS_OWNER,
    COUNTER_WISE_REPORT_OWNER_PANEL,
    GET_ALL_COUNTERS_IN_FILTER_OWNER_PANEL,
    COUNTERMAN_WISE_REPORT_DETAILS_OWNER,
    AGENT_WISE_REPORT_OWNER_PANEL,
    AGENT_WISE_REPORT_OWNER_PANEL_DETAILS_API,
    OWNER_PRINT_COUNT_REPORT,
    GET_ALL_OWNER_SERVICES_BOARDING_PLACES_FOR_STOPPAGE,
    ADD_OWNER_SERVICES_BOARDING_PLACE_FOR_STOPPAGE,
    UPDATE_OWNER_SERVICES_BOARDING_PLACE_FOR_STOPPAGE,
    DELETE_OWNER_SERVICES_BOARDING_PLACE_FOR_STOPPAGE,
    GET_ALL_OWNER_SERVICES_DROPPING_PLACES_FOR_STOPPAGE,
    ADD_OWNER_SERVICES_DROPPING_PLACE_FOR_STOPPAGE,
    UPDATE_OWNER_SERVICES_DROPPING_PLACE_FOR_STOPPAGE,
    DELETE_OWNER_SERVICES_DROPPING_PLACE_FOR_STOPPAGE,
    GET_ALL_DEPOTS_OWNER
};
