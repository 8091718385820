export default {
  "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ড্যাশবোর্ড"])},
  "From Stoppage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["যাত্রা শুরু"])},
  "To Stoppage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["যাত্রা শেষ"])},
  "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["তারিখ"])},
  "Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["রিপোর্ট"])},
  "Trips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ট্রিপ"])},
  "Coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কোচ"])},
  "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["লগ আউট"])},
  "Bangla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বাংলা"])},
  "Filter By": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বাছাই করুন"])},
  "Filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বাছাই"])},
  "--select stoppage--": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--যাত্রার স্টপেজ নির্বাচন--"])},
  "--select coach--": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--কোচ নির্বাচন--"])},
  "sl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["নং"])},
  "TRIP TIME & DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ট্রিপের সময় ও তারিখ"])},
  "TRIP CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ট্রিপের কোড"])},
  "COACH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কোচ"])},
  "COACH NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কোচের নাম"])},
  "ROUTE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["রুট"])},
  "ROUTE NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["রুটের নাম"])},
  "SEAT-PLAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সিটের ধরন"])},
  "SEATPLAN NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সিটের ধরনের নাম"])},
  "SEAT-CLASS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সিটের নাম"])},
  "SEATCLASS NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সিট ক্লাসের নাম"])},
  "CLASS NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ক্লাসের নাম"])},
  "DIRECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["দিক"])},
  "Direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["দিক"])},
  "STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["অবস্থা"])},
  "ACTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["একশন"])},
  "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["চালু"])},
  "View": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["দেখুন"])},
  "View Seat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["দেখুন"])},
  "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বন্ধ"])},
  "TICKET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["টিকেট"])},
  "Sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বিক্রি"])},
  "M": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["পুঃ"])},
  "F": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মঃ"])},
  "SEAT INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সিটের তথ্য"])},
  "Seat Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সিটের তথ্য"])},
  "SEATS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সিট"])},
  "TOTAL SEAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মোট সিট"])},
  "Total Seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মোট সিট"])},
  "FARE(TK)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ভাড়া(৳)"])},
  "DISCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ছাড়"])},
  "Discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ছাড়"])},
  "PAID(TK)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["দেয়া হইছে(৳)"])},
  "Paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["দেয়া হইছে"])},
  "PAID AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["দেয়া হইছে"])},
  "TOTAL AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মোট টাকা"])},
  "PAYABLE(TK)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["দিতে হবে(৳)"])},
  "Payable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["দিতে হবে"])},
  "DUE(TK)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বাকি(৳)"])},
  "DUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বাকি"])},
  "Dues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বাকি"])},
  "REFUND(TK)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ফেরত(৳)"])},
  "REFUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ফেরত"])},
  "Refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ফেরত"])},
  "EXTRA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["অতিরিক্ত"])},
  "GOODS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মালামাল"])},
  "PRINT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["প্রিন্ট"])},
  "Export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ডাউনলোড"])},
  "TRIP REPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ট্রিপ রিপোর্ট"])},
  "LAST TICKET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["শেষ টিকেট"])},
  "EXTRA TICKET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["অতিরিক্ত টিকেট"])},
  "GOODS TICKET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মালামালের টিকেট"])},
  "ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["একশন"])},
  "UPDATE TRIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ট্রিপ আপডেট"])},
  "TRIP PASS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ট্রিপ পাস"])},
  "RESET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মুছে ফেলুন"])},
  "REFRESH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["রিফ্রেশ"])},
  "Booking Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বুকিংয়ের তথ্য"])},
  "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["নাম"])},
  "Passenger Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["যাত্রীর নাম"])},
  "Mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মোবাইল"])},
  "Passenger Mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["যাত্রীর মোবাইল"])},
  "Gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["লিঙ্গ"])},
  "Sex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["লিঙ্গ"])},
  "--select gender --": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--লিঙ্গ নির্ধারন করুন--"])},
  "--select a driver --": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--ড্রাইভার নির্ধারন করুন--"])},
  "--select a supervisor --": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--সুপারভাইজর নির্ধারন করুন--"])},
  "Male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["পুরুষ"])},
  "Female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মহিলা"])},
  "NID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["পরিচয় পত্র"])},
  "Passenger NID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["যাত্রীর পরিচয় পত্র"])},
  "Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ঠিকানা"])},
  "Boarding place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["যাত্রা শুরুর স্থান"])},
  "---select a boarding place---": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["---শুরুর স্থান নির্বাচন করুন---"])},
  "---select a stoppage---": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["---স্থান নির্বাচন করুন---"])},
  "Dropping place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["গন্তব্য স্থান"])},
  "---select a dropping place---": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["---গন্তব্য স্থান নির্বাচন করুন---"])},
  "CONFIRM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["নিশ্চিত করুন"])},
  "Confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["নিশ্চিত করুন"])},
  "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বাতিল"])},
  "Seat Ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সিট টিকেট"])},
  "Seat Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সিটের ভাড়া"])},
  "Extra Ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["অতিরিক্ত টিকেট"])},
  "Extra Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["অতিরিক্ত ভাড়া"])},
  "Goods Ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মালামাল টিকেট"])},
  "Goods Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মালামালের ভাড়া"])},
  "Total Ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মোট টিকেট"])},
  "Total Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মোট ভাড়া"])},
  "Gross Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সর্বমোট ভাড়া"])},
  "Total Seat Ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মোট সিট টিকেট"])},
  "Total Seat Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মোট সিটের ভাড়া"])},
  "Total Extra Ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মোট অতিরিক্ত টিকেট"])},
  "Total Extra Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মোট অতিরিক্ত ভাড়া"])},
  "Total Goods Ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মোট মালামাল টিকেট"])},
  "Total Goods Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মোট মালামালের ভাড়া "])},
  "Coach Registration Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কোচ রেজিস্ট্রেশন নাম্বার"])},
  "Driver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ড্রাইভার"])},
  "Supervisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সুপারভাইজর"])},
  "SL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["নং"])},
  "Trip Code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ট্রিপের কোড"])},
  "From": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["হইতে"])},
  "To": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["পর্যন্ত"])},
  "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["অবস্থা"])},
  "FROM STOPPAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["যাত্রা শুরুর স্থান"])},
  "TO STOPPAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["যাত্রার গন্তব্য"])},
  "Ticket History": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["টিকেটের ইতিহাস"])},
  "Seat Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সিটের ধরন"])},
  "Go Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["পেছনে যান"])},
  "GO BACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["পেছনে যান"])},
  "STOPPAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["স্থান"])},
  "ISSUED BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ইস্যুকারক"])},
  "ISSUED ON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ইস্যুকৃত"])},
  "Issued By": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ইস্যুকারক"])},
  "COUNTER NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কাউন্টারের নাম"])},
  "TICKET TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["টিকেটের ধরন"])},
  "SEAT NUMBERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সিটের নাম্বার"])},
  "Cancellation Cause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বাদ দেয়ার কারন"])},
  "Cancel reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বাদ দেয়ার কারন"])},
  "Select Reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কারন নির্বাচন করুন"])},
  "SEAT CLASS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সিট ক্লাস"])},
  "Seat Class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সিট ক্লাস"])},
  "Mixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মিক্সড"])},
  "Goods Id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মালামালের আইডি"])},
  "Goods ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মালামালের আইডি"])},
  "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বাদ দিন"])},
  "PNR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["পিএনআর"])},
  "Pnr Ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["পিএনআর টিকেট"])},
  "PNR CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["পিএনআর কোড"])},
  "PNR_TICKET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["পিএনআর টিকেট"])},
  "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["নাম"])},
  "REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["রেজিস্ট্রেশন নাম্বার"])},
  "SEAT_PLAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সিট প্লান"])},
  "Summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সংক্ষিপ্ত বিবরন"])},
  "Seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সিট"])},
  "Class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class"])},
  "Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["পরিমান"])},
  "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সাবমিট"])},
  "Trip_Pass_Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আপনি কি নিশ্চিত?, আপনি ট্রিপটি পাস করতে চান তাহলে, আপনি কোনো টিকেট কাটতে পারবেন না!!"])},
  "GOODS_WEIGHT_IN_(KG)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মালামালের ওজন(কে.জি.)"])},
  "UPDATE_TRIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ট্রিপ আপডেট করুন"])},
  "Discount_Fare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ডিসকাউন্টের পরিমান মোট টাকার চেয়ে বেশি হতে পারবে না"])},
  "UP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আপ"])},
  "DOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ডাউন"])},
  "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সব সিলেক্ট করুন"])},
  "ticket_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["টিকেটের তথ্য"])},
  "Trip_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ট্রিপের তারিখ"])},
  "Goods_Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মালামালের ওজন"])},
  "Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মোবাইল"])},
  "Fare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ভাড়া"])},
  "Ticket_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["টিকেটের তারিখ"])},
  "The droppingPoint field is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["গন্তব্য স্থান পূরন করতেই হবে"])},
  "USER_TRIP_REPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["যাত্রীর ট্রিপ রিপোর্ট"])},
  "Trip_Pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ট্রিপ পাস"])},
  "Please insert fare and try again / May be, You did not Select any seat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["অনুগ্রহ করে ভাড়ার টাকা উল্লেখ্য করুন এবং আবার চেষ্টা করুন / হতে পারে, আপনি কোনো সিট নির্বাচন করেননি"])},
  "Owners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মালিক"])},
  "Services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সার্ভিস"])},
  "Seat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সিট"])},
  "NON AC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["নন এসি"])},
  "Non AC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["নন এসি"])},
  "AC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["এসি"])},
  "Goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মালামাল"])},
  "Extra_Seat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["অতিরিক্ত সিট"])},
  "Chalan Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["চালান রিপোর্ট"])},
  "Coach Wise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কোচ অনুসারে"])},
  "Counter Wise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কাউন্টার অনুসারে"])},
  "Agent Wise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["এজেন্ট অনুসারে"])},
  "Print Count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["প্রিন্ট গণনা"])},
  "SEAT_TICKET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সিট টিকেট"])},
  "SEAT_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সিটের টাকা"])},
  "GOODS_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মালামালের টাকা"])},
  "EXTRA_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["অতিরিক্ত টাকা"])},
  "DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বিবরন"])},
  "Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বিবরন"])},
  "Taka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["টাকা"])},
  "Serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সিরিয়াল"])},
  "Vehicle_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["গাড়ীর নাম্বার"])},
  "Seat_Ticket_Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সিট টিকেট ডিটেইলস"])},
  "Goods_Ticket_Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মালামালের টিকেট ডিটেইলস"])},
  "Passenger_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["যাত্রীর তথ্য"])},
  "Boarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["যাত্রা শুরু"])},
  "Dropping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["যাত্রা শেষ"])},
  "Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মুল্য"])},
  "Final_Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মোট টাকা"])},
  "SubTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সাবটোটাল"])},
  "Free_Seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ফ্রী সিট"])},
  "Seat-and-Fare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সিট & ফেয়ার"])},
  "Counter_Wise_Seat_Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কাউন্টার অনুসারে সিটের রিপোর্ট"])},
  "Counter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কাউন্টার"])},
  "User_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ব্যাবহারকারী"])},
  "Couch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কোচ"])},
  "User_Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ব্যবহারকারীর বৃত্যান্ত"])},
  "Sold_Seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বিক্রিত সিট"])},
  "Receive_Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["গৃহীত মূল্য"])},
  "Received_Amount_Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["গৃহীত মোট মূল্য"])},
  "Commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কমিশন"])},
  "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মোট"])},
  "Extra_Ticket_Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["অতিরিক্ত টিকেট রিপোর্ট"])},
  "Customer_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ক্রেতার তথ্য"])},
  "Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ওজন"])},
  "Charge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["চার্জ"])},
  "Balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ব্যালেন্স"])},
  "Signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["স্বাক্ষর"])},
  "Counter_Master": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কাউন্টার মাস্টার"])},
  "Guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["গাইড"])},
  "Checker_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["চেকার-১"])},
  "Checker_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["চেকার-২"])},
  "Extra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["অতিরিক্ত"])},
  "Ticket_Date_Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["টিকেটের তারিখ ও সময়"])},
  "Total_payable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মোট দিতে হবে"])},
  "Goods_tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মালামালের টিকেট"])},
  "Cancelled_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বাতিল কারক"])},
  "Counterman_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কাউন্টারম্যানের নাম"])},
  "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ইমেইল"])},
  "User_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ইউজারের ধরন"])},
  "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সংশোধন"])},
  "Update Owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আপডেট মালিক"])},
  "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["পাসওয়ার্ড"])},
  "Roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["দায়িত্ব"])},
  "Give_all_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সব অনুমোদন দেয়া হলো"])},
  "Company_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কোম্পানি সেটআপ"])},
  "Recharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["রিচার্জ"])},
  "Read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["পড়া"])},
  "Write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["লেখা"])},
  "Stoppage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["স্টপেজ"])},
  "Route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["রুট"])},
  "Seat-Plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সিট প্লান"])},
  "Agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["এজেন্ট"])},
  "Counterman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কাউন্টারম্যান"])},
  "Add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["নতুন এড করুন"])},
  "BoardingPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["শুরুর স্থান"])},
  "DroppingPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["গন্তব্য স্থান"])},
  "Sequence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ক্রম"])},
  "Add Stoppage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["স্টপেজ যোগ করুন"])},
  "Update Stoppage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["স্টপেজ আপডেট করুন"])},
  "Create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["তৈরী করুন"])},
  "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ডিলিট"])},
  "Add Boarding Place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["শুরুর স্থান যোগ করুন"])},
  "Add Dropping Place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["গন্তব্য স্থান যোগ করুন"])},
  "Update Boarding Place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["শুরুর স্থান আপডেট করুন"])},
  "Update Dropping Place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["গন্তব্য স্থান আপডেট করুন"])},
  "Do you want to delete boarding place?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আপনি কি শুরুর স্থান ডিলিট করতে চান?"])},
  "Do you want to delete dropping place?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আপনি কি গন্তব্য স্থান ডিলিট করতে চান?"])},
  "Update Counter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কাউন্টার আপডেট করুন"])},
  "Add Counter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কাউন্টার যোগ করুন"])},
  "Preferred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["পছন্দ"])},
  "--select preferred stoppage--": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--পছন্দের স্টপেজ নির্বাচন করুন--"])},
  "routeSequence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["রুট ক্রম"])},
  "--select direction--": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--দিক নির্বচন--"])},
  "Add Route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["রুট যোগ করুন"])},
  "Update Route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["রুট আপডেট করুন"])},
  "Update Seat Class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সিট ক্লাস আপডেট করুন"])},
  "Add Seat Class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সিট ক্লাস যোগ করুন"])},
  "addNewSeatPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["নতুন সিট প্লান যোগ করুন"])},
  "Show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["দেখুন"])},
  "Hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বন্ধ"])},
  "Select Class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ক্লাস নির্বচন করুন"])},
  "Update Seat Plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সিট প্লান আপডেট করুন"])},
  "Edit Seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সিট সংশোধন করুন"])},
  "removeSeatClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সিট ক্লাস মুছুন"])},
  "removeSeatClassConfirmMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আপনি কি নিশ্চিত, আপনি সিট ক্লাস মুছে ফেলতে চান?"])},
  "Add Coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কোচ এড করুন"])},
  "Update Coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কোচ আপডেট করুন"])},
  "Routes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["রুট"])},
  "Seat-Fare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সিটের ভাড়া"])},
  "Update Trip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ট্রিপ আপডেট করুন"])},
  "Add Trips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ট্রিপ যোগ করুন"])},
  "Trips Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ট্রিপের তারিখ"])},
  "Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সময়"])},
  "Seat Plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সিট প্লান"])},
  "Trip Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ট্রিপ বাদ দিন"])},
  "cancelTripMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আপনি কি নিশ্চিত যে, আপনি ট্রিপটি বাদ দিতে চান?"])},
  "coachWiseTrip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কোচ অনুসারে ট্রিপ"])},
  "tripRoute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ট্রিপ রুট"])},
  "tripTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ট্রিপের সময়"])},
  "Add Agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["এজেন্ট যোগ করুন"])},
  "ticketBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["টিকেট বুকিং"])},
  "fareDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ভাড়া ছাড়"])},
  "ticketCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["টিকেট বাদ"])},
  "extraTicketBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["অতিরিক্ত টিকেট বুকিং"])},
  "goodsTicketBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মালামালের টিকেট বুকিং"])},
  "tripPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ট্রিপ পাশ"])},
  "tripEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ট্রিপ সংশোধন"])},
  "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["হ্যাঁ"])},
  "Add Counterman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কাউন্টারম্যান যোগ করুন"])},
  "cancelOtherTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["অন্যান্য টিকেট বাদ"])},
  "Recharge Agent Balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["এজেন্টের ব্যালেন্স রিচার্জ"])},
  "Recharge Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["রিচার্জের পরিমান"])},
  "Update Agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আপডেট এজেন্ট"])},
  "Update Counterman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আপডেট কাউন্টারম্যান"])},
  "Add Supervisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সুপারভাইজর যোগ করুন"])},
  "Update Supervisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আপডেট সুপারভাইজর"])},
  "Add Driver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ড্রাইভার যোগ করুন"])},
  "Update Driver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আপডেট ড্রাইভার"])},
  "Edit Route Fare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["রুটের ভাড়া সংশোধন"])},
  "addFare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ভাড়া যোগ করুন"])},
  "--select route--": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--রুট নির্বচন করুন--"])},
  "--select sequence route--": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--রুট ক্রম নির্বচন করুন--"])},
  "Booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বুকিং করুন"])},
  "Departure Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ছাড়ার তারিখ"])},
  "Departure Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ছাড়ার সময়"])},
  "Seat Cancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আসন বাতিল"])},
  "Remaining Payable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["প্রদেয় অবশিষ্ট"])},
  "Cancel Ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["টিকিট বাতিল করুন"])},
  "Pay Reserved Ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সংরক্ষিত টিকিট ভাড়া প্রদান"])},
  "Pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["পেন্ডিং"])},
  "Log History": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["লগ বিবরণ"])},
  "Confirm Ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["টিকিট নিশ্চিত করুন"])},
  "STUFF INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["স্টাফ তথ্য"])}
}