<template>
  <label v-if="isModalActive" :for="inputId">{{ inputLabel }}</label>
  <label v-else :for="inputId" class="text-white">{{ inputLabel }} :</label>
  <div :class="isModalActive ? 'col-sm-12': 'col-12'">
    <v-date-picker v-model="time" :model-config="modelConfig" :timezone="timezone" class="inline-block h-full "
                   is-required
                   mode="time">
      <template v-slot="{ inputValue, togglePopover }">
        <input
            :value="inputValue"
            class="select_item form-select-sm font_detail w-100"
            readonly
            @click="togglePopover()"
        />
      </template>
    </v-date-picker>
    <slot name="error"></slot>
  </div>
</template>

<script>
import {ref, watch} from "vue";

export default {
  name: "TimePicker",
  props: {
    modelValue: String,
    label: String,
    id: String,
    isModal: Boolean,
  },
  setup(props, {emit}) {
    let time = ref(props.modelValue);
    const inputLabel = ref(props.label);
    const inputId = ref(props.id);
    const isModalActive = ref(props.isModal)
    const modelConfig = ref({
      type: 'string',
      mask: 'HH:mm:00'
    });
    let timezone = ref('');
    watch(() => time.value, () => {
      emit("update:modelValue", time.value);
    });
    watch(() => props.modelValue, (value) => {
      time.value = value;
    });
    return {
      time, timezone, inputLabel, inputId, modelConfig,
      isModalActive
    };
  }
}
</script>

<style scoped>

</style>