import roles from "@/modules/roles";

const {Owner: OwnerRoles} = roles()

const Default = {
  'LOGIN_URL': {
    "title": "Login",
    "path": "/",
    "name": "login",
  },
  'LOGOUT_URL': {
    "title": "Logout",
    "path": "/logout",
    "name": "logout",
  },
  'ERROR_URL': {
    "title": "Error",
    "path": "/:pathMatch(.*)*",
    "name": "error",
  }
}

const Owner = {
  'path': '/owner',
  'name': 'owner',
  'DASHBOARD_URL': {
    "title": "Dashboard",
    "path": "dashboard",
    "name": "owner-dashboard",
    "permission": OwnerRoles.ownerDashboard,
  },
  'REPORT_URL': {
    "title": "Report",
    "path": "report",
    "name": "owner-report",
    "permission": OwnerRoles.ownerReports,
    "children": {
      'CHALAN_REPORT_URL': {
        "title": "Chalan Report",
        "path": "chalan-report",
        "name": "owner-report-chalan-report",
      },
      'COACH_REPORT_URL': {
        "title": "Coach Wise",
        "path": "coach-report",
        "name": "owner-report-coach-report",
      },
      'COUNTER_REPORT_URL': {
        "title": "Counter Wise",
        "path": "counter-report",
        "name": "owner-report-counter-report",
      },
      'PRINT_COUNT_REPORT_URL': {
        "title": "Print Count",
        "path": "print-count",
        "name": "owner-report-print-count-report",
      },
    }
  },
  // 'OWNERS_URL': {
  //     "title": "Owners",
  //     "path": "owners",
  //     "name": "owner-owners",
  //     "permission": OwnerRoles.ownerRead,
  //     "children": {
  //         'LIST_URL': {
  //             "title": "List",
  //             "path": "list",
  //             "name": "admin-admins-list",
  //         },
  //         'ADD_URL': {
  //             "title": "Add",
  //             "path": "add",
  //             "name": "admin-admins-add",
  //         },
  //         'UPDATE_URL': {
  //             "title": "Update",
  //             "path": "update",
  //             "name": "admin-admins-update",
  //         }
  //     }
  // },
  'SERVICES_URL': {
    "title": "Services",
    "path": "services",
    "name": "owner-services",
    "permission": OwnerRoles.ownerCompanySetupRead,
    "children": {
      'STOPPAGE_URL': {
        "title": "Stoppage",
        "path": "stoppage",
        "name": "owner-services-stoppage",
      },
      'COUNTER_URL': {
        "title": "Counter",
        "path": "counter",
        "name": "owner-services-counter",
      },
      'ROUTE_URL': {
        "title": "Route",
        "path": "route",
        "name": "owner-services-route",
      },
      'SEAT_CLASS_URL': {
        "title": "Seat Class",
        "path": "seatClass",
        "name": "owner-services-seatClass",
      },
      'SEAT_PLAN_URL': {
        "title": "Seat-Plan",
        "path": "seatPlan",
        "name": "owner-services-seatPlan",
      },

      'SEAT_FARE_URL': {
        "title": "Seat-Fare",
        "path": "seatFare",
        "name": "owner-services-seatFare",
      },
      'COACH_URL': {
        "title": "Coach",
        "path": "coach",
        "name": "owner-services-coach",
      },
      'TRIPS_URL': {
        "title": "Trips",
        "path": "trips",
        "name": "owner-services-trips",
      },
      // 'AGENT_URL': {
      //     "title": "Agent",
      //     "path": "agent",
      //     "name": "owner-services-agent",
      // },
      'COUNTERMAN_URL': {
        "title": "Counterman",
        "path": "counterman",
        "name": "owner-services-counterman",
      },
      'SUPERVISOR_URL': {
        "title": "Supervisor",
        "path": "supervisor",
        "name": "owner-services-supervisor",
      },
      'DRIVER_URL': {
        "title": "Driver",
        "path": "driver",
        "name": "owner-services-driver",
      },


    }
  },
  'PROFILE_URL': {
    "title": "PROFILE",
    "path": "profile",
    "name": "owner-profile",
  }
}


export default {
  Default,
  Owner,
}
