<template>
  <Modal @close="closeModal">
    <template v-slot:title>{{
      t(title, {}, { locale: $store.getters.getLang })
    }}</template>
    <template v-slot:body>
      <form>
        <div class="form-group row my-1">
          <ModalInput
            v-model="seatArray"
            v-bind:placeholder="'Enter Seats'"
            v-bind:label="'Seats'"
            v-bind:id="'seats'"
            v-bind:type="'text'"
          >
            <template v-slot:error>
              <ModalError v-if="errors.seat" v-bind:error="errors.seat" />
              <span v-if="error" class="text-danger err_msg"
                >* {{ error }}</span
              >
            </template>
          </ModalInput>
        </div>

        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <!-- <input
              type="submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="updateSeatRow"
            /> -->
            <button
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="updateSeatRow"
            >
              {{ t("Submit", {}, { locale: $store.getters.getLang }) }}
            </button>
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import useInputValidation from "@/modules/useInputValidations";
import { inject, onBeforeMount, ref, watch } from "vue";
import { reactive } from "@vue/reactivity";
import store from "@/store";
import { useI18n } from "vue-i18n";
export default {
  name: "EditSeatRowModal",
  props: {
    seatList: Array,
    seatPlan: String,
    companyId: String,
    row: Number,
    seatLists: String,
    modelValue: Boolean,
  },
  setup(props, { emit }) {
    let { requiredValidation, errors } = useInputValidation();
    let seatArray = ref([]);
    seatArray.value.push(props.seatLists);
    let seatList = ref(props.seatList);
    let seat = reactive({
      id: props.seatPlan,
      companyId: props.companyId,
      rowIndex: props.row,
      seatRow: seatList.value,
    });
    const title = ref("Edit Seats");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const { t, locale } = useI18n();
    let error = ref("");

    watch(
      () => seat.seatRow,
      () => {
        requiredValidation("seat", seat.seatRow);
      }
    );
    watch(
      () => [...props.seatList],
      () => {
        seat.seatRow = [...props.seatList];
      }
    );

    const closeModal = () => {
      emit("update:modelValue", false);
    };

    // const validateCustomSeatPlan = () => {
    //    error.value = "";
    //    Object.keys(errors).forEach(function (key) {
    //       errors[key] = ""
    //    });
    //    requiredValidation("seats", seat.seatRow.trim());
    //    if (Object.values(errors).length <= 0 || !Object.values(errors).every(x => x === null || x === '')) {
    //       return false;
    //    } else {
    //       if (/\s/g.test(seat.seatRow.trim())) {
    //          error.value = "Space not allowed";
    //          return false
    //       } else if (!/^([A-Za-z0-9]+,?)+$/.test(seat.seatRow.trim())) {
    //          error.value = "Invalid seats";
    //          return false
    //       } else {
    //          return true
    //       }
    //    }
    // };

    const getSeatPlan = () => {
      emit("getSeatPlan");
    };
    const updatedSeat = () => {
      let updateArray = seatArray.value.split(",");
      seat.seatRow.forEach((seat, index) => {
        seat.seat_no = updateArray[index];
      });
      return seat;
    };
    const updateSeatRow = () => {
      if (updatedSeat()) {
        if (updatedSeat()) {
          // seat.seatRow = seat.seatRow.trim()
          showLoader();
          store
            .dispatch("editOwnerServicesCustomSeatRow", seat)
            .then(() => {
              hideLoader();
              getSeatPlan();
              closeModal();
            })
            .catch((error) => {
              hideLoader();
              if (error.status === 401) {
                updateSeatRow();
              }
            });
        }
      }
    };

    onBeforeMount(() => {
      Object.keys(errors).forEach(function (key) {
        delete errors[key];
      });
    });

    return {
      t,
      locale,
      seat,
      errors,
      error,
      title,
      closeModal,
      updateSeatRow,
      seatArray,
    };
  },
};
</script>

<style scoped></style>
