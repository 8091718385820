<template>
  <loading-spinner/>
  <Nav v-if="!$store.getters.isLoggedIn"/>
  <main>
    <router-view></router-view>
  </main>
</template>

<script>
import Nav from '@/components/layouts/Nav/Nav.vue'

export default {
  name: 'App',
  components: {
    Nav
  },


}
</script>

<style>
html,
body,
#app {
  height: 100vh;
  margin: 0;
  padding: 0;
  background-color: #F7F7F7;
}
</style>

<style src="./assets/css/color.css"></style>
<style src="./assets/css/responsive.css"></style>
<style src="./assets/css/style.css"></style>
<style src="./assets/css/ticket.css"></style>