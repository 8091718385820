import { createRouter, createWebHistory } from "vue-router";
import store from "../store";
import web from "../urls/web";
import Login from "../views/Authentication/Login";
import Error from "../views/Default/Error";
import Profile from "../views/Default/Profile";
import Owner from "../views/Owner/Owner";
import OwnerDashboard from "../views/Owner/Dashboard/Dashboard";
// import OwnersList from "../views/Owner/Owners/OwnersList";
import OwnerReport from "../views/Owner/Report/Report";
import OwnerServices from "../views/Owner/Services/CompanyManage";
import middlewarePipeline from "./middlewarePipeline";
import auth from "./middlewares/auth";
import guest from "./middlewares/guest";
import hasAccess from "./middlewares/hasAccess";
import owner from "./middlewares/owner";






const routes = [
    {
        path: web.Default.LOGIN_URL.path,
        name: web.Default.LOGIN_URL.name,
        component: Login,
        meta: {
            title: web.Default.LOGIN_URL.title,
            middleware: [
                guest
            ]
        }
    },
    {
        path: web.Owner.path,
        component: Owner,
        // meta:{
        //     middleware: [
        //         auth
        //     ]
        // },
        children: [
            {
                path: web.Owner.DASHBOARD_URL.path,
                name: web.Owner.DASHBOARD_URL.name,
                component: OwnerDashboard,
                meta: {
                    title: web.Owner.DASHBOARD_URL.title,
                    middleware: [
                        auth,
                        owner,
                        hasAccess
                    ]
                }
            },
            {
                path: web.Owner.REPORT_URL.path,
                name: web.Owner.REPORT_URL.name,
                component: OwnerReport,
                meta: {
                    title: web.Owner.REPORT_URL.title,
                    middleware: [
                        auth,
                        owner,
                        hasAccess
                    ]
                }
            },
            // {
            //     path: web.Owner.OWNERS_URL.path,
            //     name: web.Owner.OWNERS_URL.name,
            //     component: OwnersList,
            //     meta: {
            //         title: web.Owner.OWNERS_URL.title,
            //         middleware: [
            //             auth,
            //             owner,
            //             hasAccess
            //         ]
            //     }
            // },
            {
                path: web.Owner.SERVICES_URL.path,
                name: web.Owner.SERVICES_URL.name,
                component: OwnerServices,
                meta: {
                    title: web.Owner.SERVICES_URL.title,
                    middleware: [
                        auth,
                        owner,
                        hasAccess
                    ]
                }
            },
            {
                path: web.Owner.PROFILE_URL.path,
                name: web.Owner.PROFILE_URL.name,
                component: Profile,
                meta: {
                    middleware: [
                        auth,
                        owner
                    ]
                }
            },
        ]
    },
    {
        path: web.Default.ERROR_URL.path,
        name: web.Default.ERROR_URL.name,
        component: Error,
        // meta:{
        //     middleware: [
        //         guest
        //     ]
        // }
    }

]

const router = createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active",
})

router.beforeEach((to, from, next) => {
    document.title = `${process.env.VUE_APP_TITLE} - ${to.meta.title}`;
    if (!to.meta.middleware) {
        return next()
    }
    const middleware = to.meta.middleware
    const context = {
        to,
        from,
        next,
        store
    }


    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    })

})
export default router;
