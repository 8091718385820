<template>
  <div>
    <FilterTab>
      <!-- <template v-slot:title><h6>Coach</h6></template> -->
      <template v-slot:body> </template>
      <template
          v-if="hasPermission(Owner.ownerCompanySetupWrite)"
          v-slot:button1
      >
        <button class="edit_table_data font_detail" @click="addModal">
          <i class="fas fa-plus"></i
          ><span class="mx-1 bold">{{
            t("Add_new", {}, { locale: $store.getters.getLang })
          }}</span>
        </button>
      </template>
    </FilterTab>
    <div v-if="addUpdate">
      <CoachAddUpdateModal
          @close="close"
          v-bind:coach="coachId"
          @getAllCoach="getAllCoach"
      />
    </div>
    <div>
      <Table v-if="$store.getters.getOwnerServicesCoachs">
        <template v-slot:header>
          <tr>
            <td class="font_detail">
              {{ t("SL", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td class="font_detail">
              {{ t("NAME", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td
                class="font_detail"
            >
              {{
                t("REGISTRATION_NUMBER", {}, { locale: $store.getters.getLang })
              }}
            </td>
            <td
                class="font_detail"
            >
              {{
                t("DEPOT", {}, { locale: $store.getters.getLang })
              }}
            </td>
            <td
                class="font_detail"
            >
              {{ t("SEAT_PLAN", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td
                class="font_detail"
            >
              {{ t("STATUS", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td
                v-if="hasPermission(Owner.ownerCompanySetupWrite)"
                class="font_detail"
            >
              {{ t("ACTION", {}, { locale: $store.getters.getLang }) }}
            </td>
          </tr>
        </template>
        <template v-slot:body>
          <tr
              v-for="(coach, index) in $store.getters.getOwnerServicesCoachs"
              :key="coach._id"
          >
            <td class="font_detail">
              {{ index + 1 + (pagination.page - 1) * 25 }}
            </td>
            <td class="font_detail">{{ coach?.name }}</td>
            <td
                class="font_detail"
            >
              {{ coach?.registrationNumber }}
            </td>
            <td
                class="font_detail"
            >
              {{ coach?.depot?.name }}
            </td>
            <td
                class="font_detail"
            >
              {{ coach?.seatPlanId?.name }}
            </td>
            <td>
              <span v-if="coach.status" class="badge bg-success">{{
                  dropdownVariables.status[1]
                }}</span>
              <span v-else class="badge bg-danger">{{
                  dropdownVariables.status[0]
                }}</span>
            </td>
            <td v-if="hasPermission(Owner.ownerCompanySetupWrite)">
              <button
                  class="edit_table_data font_detail edit_table_button"
                  @click="updateModal(coach._id)"
              >
                <i class="fas fa-edit"></i
                ><span class="mx-1">{{
                  t("Edit", {}, { locale: $store.getters.getLang })
                }}</span>
              </button>
            </td>
          </tr>
        </template>
        <template v-slot:pagination>
          <pagination
              v-model="pagination.page"
              :records="$store.getters.getOwnerServicesTotalCoachs"
              :per-page="pagination.limit"
              @paginate="getAllCoach"
              :options="options"
          />
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { reactive } from "@vue/reactivity";
import { inject, onBeforeMount, ref } from "vue";
import store from "@/store";
import dropdownVariables from "@/modules/dropdownVariables";
import CoachAddUpdateModal from "./CoachAddUpdateModal";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import { useI18n } from "vue-i18n";
const { hasPermission } = permission();
const { Owner } = roles();

export default {
  name: "Coach",
  components: { CoachAddUpdateModal },
  setup() {
    let pagination = reactive({
      page: 1,
      limit: 25,
    });
    const addUpdate = ref(false);
    const coachId = ref("");
    const options = {
      texts: {
        count: "",
      },
    };
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const { t, locale } = useI18n();
    const close = () => {
      addUpdate.value = false;
    };
    const addModal = () => {
      coachId.value = "";
      addUpdate.value = true;
    };
    const updateModal = (id) => {
      coachId.value = id;
      addUpdate.value = true;
    };
    const dateFormat = (date) => {
      return moment(String(date))
          .utcOffset(0, true)
          .format("DD-MMM-yyyy hh:mm:ss A");
    };
    const getAllCoach = () => {
      showLoader();
      store
          .dispatch("getOwnerServicesAllCoachByPage", pagination)
          .then(() => {
            getAllDepots()
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              getAllCoach();
            }
          });
    };
    const getAllDepots = () => {
      store
          .dispatch("getOwnerServicesDepotListInFilter", pagination)
          .then(() => {
            hideLoader();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              getAllDepots();
            }
          });
    };

    onBeforeMount(() => {
      getAllCoach();
    });

    return {
      t,
      locale,
      options,
      pagination,
      dropdownVariables,
      addUpdate,
      coachId,
      Owner,
      hasPermission,
      getAllCoach,
      addModal,
      updateModal,
      close,
      dateFormat,
      status,
    };
  },
};
</script>

<style scoped>
.bold {
  font-weight: bold;
}
</style>
