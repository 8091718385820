<template>
  <div>
    <FilterTab>
      <!-- <template v-slot:title><h6>Seat Plan</h6></template> -->
      <template v-slot:body>
        <div class="col-sm-6 col-md-2"></div>
      </template>
    </FilterTab>
    <div
      v-if="hasPermission(Owner.ownerCompanySetupWrite)"
      class="card container new-class"
    >
      <div class="card-body">
        <h6 class="card-title bold">
          {{ t("addNewSeatPlan", {}, { locale: $store.getters.getLang }) }}
        </h6>
        <span v-if="error" class="text-danger err_msg">* {{ error }}</span>
        <div class="row">
          <div class="col-md-3">
            <Dropdown
              v-model="seatPlan.seatClass"
              v-bind:id="''"
              v-bind:label="''"
              v-bind:defaultOption="''"
              v-bind:defaultValue="''"
              v-bind:dropdown="seatClassMain"
              v-bind:hideLabel="''"
            />
          </div>
          <div class="col-md-3">
            <Input
              v-model="seatPlan.name"
              v-bind:placeholder="'Enter Seat Plan Name'"
              v-bind:id="'name'"
              v-bind:type="'text'"
            />
            <Error v-if="errors.name" v-bind:error="errors.name" />
          </div>
          <div class="col-md-3">
            <Dropdown
              v-if="status"
              v-model="seatPlan.status"
              v-bind:id="'status'"
              v-bind:label="'Status'"
              v-bind:dropdown="status"
              v-bind:hideLabel="''"
            />
          </div>
          <div class="col-md-3">
            <!-- <input
              type="submit"
              class="submit_button mt-1 w-50"
              @click.prevent="addSeatClass"
            /> -->
            <button
              class="submit_button mt-1 w-50"
              @click.prevent="addSeatClass"
            >
              {{ t("Submit", {}, { locale: $store.getters.getLang }) }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div id="accordion" v-if="$store.getters.getOwnerServicesSeatPlans">
      <div
        v-for="seatPlan in $store.getters.getOwnerServicesSeatPlans"
        :key="seatPlan._id"
      >
        <SeatPlanCard
          v-bind:company="seatPlan.companyId"
          :seatPlanId="seatPlan._id"
        />
      </div>
      <div class="d-flex justify-content-end mx-5 my-2">
        <pagination
          v-model="payload.page"
          :records="$store.getters.getTotalOwnerServicesSeatPlans"
          :per-page="payload.limit"
          @paginate="getNewSeatPlan"
          :options="options"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import Input from "@/components/layouts/Inputs/Input";
import Error from "@/components/layouts/Inputs/Error";
import useInputValidation from "@/modules/useInputValidations";
import dropdownVariables from "@/modules/dropdownVariables";
import { inject, onBeforeMount, ref, watch } from "vue";
import SeatPlanCard from "./SeatPlanCard.vue";
import store from "@/store";
import FilterTab from "@/components/layouts/Filter/FilterTab";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
const { hasPermission } = permission();
const { Owner } = roles();

export default {
  name: "SeatPlan",
  components: { Input, Error, FilterTab, SeatPlanCard },
  setup(props) {
    let seatPlan = reactive({
      seatClass: "Mixed",
      seatClassId: null,
      companyId: "",
      name: "",
      seatList: [],
      status: "1",
    });

    let seatClassMain = reactive({
      Mixed: "Mixed",
    });

    let { requiredValidation, errors } = useInputValidation();
    let shipId = ref(props.ship);
    let companyId = ref(props.company);
    let error = ref("");
    const route = useRoute();
    const status = dropdownVariables.status;
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const { t, locale } = useI18n();
    let pagination = reactive({
      page: 1,
      limit: 25,
    });
    let payload = reactive({
      page: 1,
      limit: 25,
      companyId: route.query.id,
    });
    const clear = () => {
      seatPlan.name = "";
      seatPlan.seatList = [];
      seatPlan.status = "1";
    };
    const options = {
      texts: {
        count: "",
      },
    };

    watch(
      () => seatPlan.seatClass,
      (value) => {
        let demoValue = null;
        if (value !== "Mixed") {
          for (let [seatClasskey, seatClassvalue] of Object.entries(
            store.getters.getAllSeatClassOwner
          )) {
            if (value === seatClassvalue) {
              demoValue = seatClasskey;
              break;
            }
          }
        }
        seatPlan.seatClassId = demoValue;
      }
    );
    // watch(
    //   () => seatPlan.name,
    //   () => {
    //     requiredValidation("name", seatPlan.name);
    //   }
    // );
    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      requiredValidation("name", seatPlan.name);
      return !(
        Object.values(errors).length <= 0 ||
        !Object.values(errors).every((x) => x === null || x === "")
      );
    };

    const getAllSeatClass = () => {
      showLoader();
      store
        .dispatch("getOwnerServicesAllSeatClass", payload)
        .then(() => {
          if (store.getters.getAllSeatClassOwner) {
            let busClass = store.getters.getAllSeatClassOwner;
            for (const key in busClass) {
              seatClassMain[busClass[key]] = busClass[key];
            }
          }

          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getAllSeatClass();
          }
        });
    };

    const getNewSeatPlan = () => {
      store
        .dispatch("getOwnerServicesNewSeatPlan", payload)
        .then(() => {})
        .catch((error) => {
          if (error.status === 401) {
            getNewSeatPlan();
          }
        });
    };

    const addSeatClass = () => {
      if (validate()) {
        if (seatPlan.seatClass === "" || seatPlan.seatClass === undefined) {
          seatPlan.seatClass = "Mixed";
        }
        showLoader();
        error.value = "";
        store
          .dispatch("addOwnerServicesNewSeatPlan", { seatPlan, pagination })
          .then(() => {
            // seatPlan.seatClass = "";
            hideLoader();
            clear();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              addSeatClass();
            } else {
              error.value = error;
            }
          });
      }
    };

    onBeforeMount(() => {
      getAllSeatClass();
      getNewSeatPlan();
      seatPlan.companyId = store.getters.userCompany;
    });

    return {
      t,
      locale,
      seatPlan,
      errors,
      status,
      shipId,
      companyId,
      error,
      Owner,
      hasPermission,
      addSeatClass,
      seatClassMain,
      options,
      getNewSeatPlan,
      payload,
    };
  },
};
</script>

<style scoped>
.new-class {
  background: #f3f3f3;
}

.bold {
  font-weight: bold;
}
</style>
