<template>
  <Table
    v-if="$store.getters.getPrintTripWiseCountListOwner.length > 0"
    id="exportable_data"
  >
    <template v-slot:header>
      <tr>
        <td class="font_detail">{{ t("SL", {}, { locale: $store.getters.getLang }) }}</td>
        <td class="font_detail">{{ t("Counterman_name", {}, { locale: $store.getters.getLang }) }}</td>
        <td class="font_detail">{{ t("Trip_Date_Time", {}, { locale: $store.getters.getLang }) }}</td>
        <td class="font_detail">{{ t("Print Count", {}, { locale: $store.getters.getLang }) }}</td>
      </tr>
    </template>
    <template v-slot:body>
      <tr v-for="(item, index) in tripWisePrintList" :key="index">
        <td class="font_detail">{{ index + 1 }}</td>
        <td class="font_detail">
          {{ item.counterman[0] }}
        </td>
        <td class="font_detail">
          {{ dateFormat(item.tripDateTime) }}
        </td>
        <td class="font_detail">
          {{ item.printCount }}
        </td>
      </tr>
      <!-- <tr class="bg-dark text-white"  >
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td class="font_detail"></td>
      </tr> -->
    </template>
    <!-- <template v-slot:pagination>
      <Pagination
        v-model="shipWiseSeatReport.page"
        :records="$store.getters.getShipWiseSeatDetailsOwner.length"
        :per-page="shipWiseSeatReport.limit"
        @paginate="getSeatDetails"
        :options="options"
      />
    </template> -->
  </Table>
</template>

<script>
import moment from "moment";
import store from "@/store";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
export default {
  name: "tripWiseTicketPrintCountDetails",
  setup() {
    const { t, locale } = useI18n();
    const dateFormat = (date) => {
      return moment(String(date))
        .utcOffset(0, false)
        .format("DD-MMM-yyyy hh:mm:ss A");
    };
    let tripWisePrintList = computed(() => {
      return store.getters.getPrintTripWiseCountListOwner.filter((item) => {
        return item.printCount > 0;
      });
    });
    return {
      t,
      locale,
      dateFormat,
      tripWisePrintList,
    };
  },
};
</script>

<style></style>
