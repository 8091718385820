<template>
  <label v-if="isModalActive" :for="inputId"> {{
      t(inputLabel, {}, {locale: $store.getters.getLang})
    }} </label>
  <label v-else :for="inputId" class="text-white"> {{ t(inputLabel, {}, {locale: $store.getters.getLang}) }}: </label>

  <v-date-picker
      v-model="date"
      :masks="masks"
      :model-config="modelConfig"
      class="inline-block h-full"
      color="teal"
      is-dark
      mode="date"
  >
    <template v-slot="{ inputValue, togglePopover }">
      <input
          :value="inputValue"
          class="select_item form-select-sm font_detail w-100"
          readonly
          @click="togglePopover()"
      />
    </template>
  </v-date-picker>
</template>

<script>
import {ref, watch} from "vue";
import {useI18n} from "vue-i18n";

export default {
  name: "DatePickerFilter",
  props: {
    modelValue: String,
    label: String,
    id: String,
    isModal: Boolean
  },
  setup(props, {emit}) {
    let date = ref(props.modelValue);
    const inputLabel = ref(props.label);
    const inputId = ref(props.id);
    const isModalActive = ref(props.isModal)
    const {t, locale} = useI18n();
    const modelConfig = ref({
      type: "string",
      mask: "YYYY-MM-DD",
    });
    const masks = ref({
      input: "DD-MMM-YYYY",
    });
    watch(
        () => date.value,
        () => {
          emit("update:modelValue", date.value);
        }
    );
    watch(
        () => props.modelValue,
        (value) => {
          date.value = value;
        }
    );
    return {
      t,
      locale,
      inputLabel,
      inputId,
      date,
      modelConfig,
      masks,
      isModalActive,
    };
  },
};
</script>

<style scoped></style>
