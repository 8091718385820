<template>
  <Modal @close="closeModal">
    <template v-slot:title> Delete dropping point </template>
    <template v-slot:body>
      <form>
        <div class="form-group row my-1">
          Do you want to delete dropping point?
          <!-- <ModalInput
            v-model="dPlaceName"
            v-bind:placeholder="'Enter Name'"
            v-bind:label="'Name'"
            v-bind:id="'name'"
            v-bind:type="'text'"
          >
          </ModalInput> -->
        </div>
        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <!-- <input
              type="submit"
              value="Delete"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="deleteDroppingPlace"
            /> -->
            <button
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="deleteDroppingPlace"
            >
              {{ t("Delete", {}, { locale: $store.getters.getLang }) }}
            </button>
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import { onBeforeMount, inject, ref } from "vue";

import store from "@/store";
import { useI18n } from "vue-i18n";
export default {
  name: "DroppingPlaceDeleteModal",
  props: {
    title: String,
    stoppageId: [String, Number],
    index: Number,
    droppingPlaceName: String,
  },
  setup(props, { emit }) {
    const deleteTitle = ref(props.title);
    const dPlaceName = ref(props.droppingPlaceName);

    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const { t, locale } = useI18n();
    const dPlace = {
      stoppageId: props.stoppageId,
      rowIndex: props.index,
    };
    const closeModal = () => {
      emit("getAllDroppingPlaces");
      emit("update:modelValue", false);
    };
    const deleteDroppingPlace = () => {
      showLoader();

      store
        .dispatch("deleteOwnerServicesDroppingPlaceForStoppage", { dPlace })
        .then(() => {
          hideLoader();
          closeModal();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            deleteDroppingPlace();
          }
        });
    };

    onBeforeMount(() => {});

    return {
      t,
      locale,
      deleteTitle,
      deleteDroppingPlace,
      closeModal,
      dPlaceName,
    };
  },
};
</script>

<style scoped></style>
