<template>
  <div>
    <FilterTab>
      <!-- <template v-slot:title><h6>Counter Man</h6></template> -->
      <template v-slot:body> </template>
      <template v-slot:button1>
        <button
          v-if="hasPermission(Owner.ownerCompanySetupWrite)"
          class="edit_table_data font_detail"
          @click="addModal"
        >
          <i class="fas fa-plus"></i
          ><span class="mx-1 bold">{{
            t("Add_new", {}, { locale: $store.getters.getLang })
          }}</span>
        </button>
      </template>
    </FilterTab>
    <Table v-if="$store.getters.getOwnerServicesCounterMans">
      <template v-slot:header>
        <tr>
          <td class="font_detail">
            {{ t("SL", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("NAME", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("Mobile", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("Email", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("Counter", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("STATUS", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td
            v-if="hasPermission(Owner.ownerCompanySetupWrite)"
            class="font_detail"
          >
            {{ t("ACTION", {}, { locale: $store.getters.getLang }) }}
          </td>
        </tr>
      </template>
      <template v-slot:body>
        <tr
          v-for="(counterman, index) in $store.getters
            .getOwnerServicesCounterMans"
          :key="counterman._id"
        >
          <td class="font_detail">
            {{ index + 1 + (pagination.page - 1) * 25 }}
          </td>
          <td class="font_detail">{{ counterman.name }}</td>
          <td class="font_detail">{{ counterman.phone }}</td>
          <td class="font_detail">{{ counterman.email }}</td>
          <td class="font_detail">{{ counterman.counterId.name }}</td>

          <td>
            <span v-if="counterman.status" class="badge bg-success">{{
              dropdownVariables.status[1]
            }}</span>
            <span v-else class="badge bg-danger">{{
              dropdownVariables.status[0]
            }}</span>
          </td>
          <td v-if="hasPermission(Owner.ownerCompanySetupWrite)">
            <button
              class="edit_table_data font_detail edit_table_button"
              @click="updateModal(counterman._id)"
            >
              <i class="fas fa-edit"></i
              ><span class="mx-1">{{
                t("Edit", {}, { locale: $store.getters.getLang })
              }}</span>
            </button>
          </td>
        </tr>
      </template>
      <template v-slot:pagination>
        <pagination
          v-model="pagination.page"
          :records="$store.getters.getOwnerServicesTotalCounterMan"
          :per-page="pagination.limit"
          @paginate="getAllCounterMans"
          :options="options"
        />
      </template>
    </Table>
    <div v-if="showModal">
      <CountermanAddUpdateModal
        v-model="showModal"
        @getAllCounterMans="getAllCounterMans"
        v-bind:company="companyId"
        v-bind:counterMan="counterManId"
      />
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { useRoute } from "vue-router";
import { onBeforeMount, reactive, ref, inject } from "vue";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import dropdownVariables from "@/modules/dropdownVariables";
import CountermanAddUpdateModal from "./CountermanAddUpdateModalSeperate.vue";
import { useI18n } from "vue-i18n";
const { hasPermission } = permission();
const { Owner } = roles();
export default {
  name: "CountermanSeperate",
  components: {
    CountermanAddUpdateModal,
  },
  setup() {
    const companyId = ref("");
    const counterManId = ref("");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const showModal = ref(false);
    const { t, locale } = useI18n();
    const pagination = reactive({
      companyId,
      page: 1,
      limit: 25,
    });
    const options = {
      texts: {
        count: "",
      },
    };

    const getAllCounterMans = () => {
      showLoader();
      store
        .dispatch("getOwnerServicesCounterMansByPage", pagination)
        .then(() => {
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getAllCounterMans();
          }
        });
    };

    const updateModal = (value) => {
      counterManId.value = value;
      showModal.value = true;
    };
    const addModal = () => {
      counterManId.value = "";
      showModal.value = true;
    };

    const getCounterList = () => {
      showLoader();
      pagination.companyId = useRoute().query.id;
      store
        .dispatch("getOwnerServicesCounterListInFilter", pagination)
        .then(() => {
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getCounterList();
          }
        });
    };

    onBeforeMount(() => {
      companyId.value = useRoute().query.id;
      getAllCounterMans();
      getCounterList();
    });
    return {
      t,
      locale,
      companyId,
      counterManId,
      pagination,
      getAllCounterMans,
      updateModal,
      hasPermission,
      Owner,
      options,
      dropdownVariables,
      showModal,
      addModal,
      getCounterList,
    };
  },
};
</script>

<style scoped>
.bold {
  font-weight: bold;
}
</style>
