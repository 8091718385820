<template>
  <div v-if="$store.getters.getChalanReportDetails">
    <div>
      <FilterTab>
        <template v-slot:body>
          <div class="col-sm-6 col-md-2"></div>
        </template>
        <template v-slot:button2>
          <button class="edit_table_data font_detail" @click="goBack">
            <i class="fas fa-arrow-left"></i
            ><span class="mx-1 bold">{{
              t("Go Back", {}, {locale: $store.getters.getLang})
            }}</span>
          </button>
        </template>
        <template v-slot:button1>
          <button class="edit_table_data font_detail" @click="print">
            <i class="fas fa-print"></i
            ><span class="mx-1 bold">{{
              t("PRINT", {}, {locale: $store.getters.getLang})
            }}</span>
          </button>
        </template>
      </FilterTab>
    </div>
    <div class="m-2 p-5" id="chalanReport" style="background-color: #ddd">
      <div class="p-2" style="background-color: white">
        <table
            width="100%"
            cellspacing="0"
            cellpadding="0"
            style="border-collapse: collapse; border: 1px solid black"
        >
          <tr>
            <th
                colspan="12"
                style="text-align: center; border: 1px solid black; padding: 4px"
            >
              <h3 style="color: green">
                {{ $store.getters.userCompanyData?.name }}
              </h3>
            </th>
          </tr>
          <tr style="border: 1px solid black">
            <td colspan="6" style="padding: 10px">
              <table width="100%">
                <tr>
                  <td colspan="3">
                    {{ t("Serial", {}, {locale: $store.getters.getLang}) }}:#
                    {{ $store.getters.getChalanReportDetails?.trip?.tripCode }}
                  </td>
                  <td colspan="3" style="font-weight: 600; font-size: large">
                    {{
                      dateFormat(
                          $store.getters.getChalanReportDetails?.trip
                              ?.tripDateTime
                      )
                    }}
                  </td>
                </tr>
                <tr>
                  <td colspan="6">
                    {{ t("Coach", {}, {locale: $store.getters.getLang}) }}# :{{
                      $store.getters.getChalanReportDetails?.trip?.coach?.name
                    }}/{{
                      $store.getters.getChalanReportDetails?.trip?.route?.name
                    }}
                  </td>
                </tr>
                <tr>
                  <td colspan="3">
                    {{ t("Driver", {}, {locale: $store.getters.getLang}) }}:{{
                      $store.getters.getChalanReportDetails?.trip?.driver?.name
                    }}
                    <br/>
                    {{ t("Mobile", {}, {locale: $store.getters.getLang}) }}:{{
                      $store.getters.getChalanReportDetails?.trip?.driver?.phone
                    }}
                  </td>
                  <td colspan="3">
                    {{ t("Supervisor", {}, {locale: $store.getters.getLang}) }}:
                    {{
                      $store.getters.getChalanReportDetails?.trip?.supervisor
                          ?.name
                    }}
                    <br/>
                    {{ t("Mobile", {}, {locale: $store.getters.getLang}) }}:{{
                      $store.getters.getChalanReportDetails?.trip?.supervisor
                          ?.phone
                    }}
                  </td>
                </tr>
              </table>
            </td>
            <td
                colspan="6"
                style="
                text-align: end;
                padding: 10px;
                border-right: 1px solid black;
              "
            >
              <table width="100%">
                <tr>
                  <td colspan="6">
                    {{ t("Vehicle_Number", {}, {locale: $store.getters.getLang}) }}#:
                    {{
                      $store.getters.getChalanReportDetails?.trip?.coach
                          ?.registrationNumber
                    }}
                  </td>
                </tr>
                <tr>
                  <!-- <td colspan="6">Helper : Mr Akbar</td> -->
                </tr>
              </table>
            </td>
          </tr>
          <tr
              style="
              border: 1px solid black;
              background-color: black;
              color: white;
              font-weight: bold;
            "
          >
            <td colspan="12" style="border: 1px solid black">
              <h5 style="text-align: center; padding: 5px; margin-bottom: 0">
                {{ t("Seat_Ticket_Details", {}, {locale: $store.getters.getLang}) }}
              </h5>
            </td>
          </tr>
          <tr
              style="border: 1px solid black; font-size: 14px; font-weight: bold"
          >
            <td
                colspan="2"
                style="
                border-right: 1px solid black;
                text-align: center;
                padding: 0 2px;
              "
            >
              {{ t("Seat", {}, {locale: $store.getters.getLang}) }}
            </td>
            <td
                colspan="1"
                style="
                border-right: 1px solid black;
                text-align: center;
                padding: 0 2px;
              "
            >
              {{ t("Issued By(Verified By)", {}, {locale: $store.getters.getLang}) }}
            </td>
            <td
                colspan="1"
                style="border-right: 1px solid black; text-align: center"
            >
              {{ t("PNR", {}, {locale: $store.getters.getLang}) }}
            </td>
            <td style="border-right: 1px solid black; text-align: center">
              <table
                  width="100%"
                  style="border-collapse: collapse; text-align: center"
              >
                <th
                    style="text-align: center; border-bottom: 1px solid black"
                    colspan="3"
                >
                  {{ t("Passenger_info", {}, {locale: $store.getters.getLang}) }}
                </th>
                <tr width="100%">
                  <td style="border-right: 1px solid black" width="40%">
                    {{ t("Name", {}, {locale: $store.getters.getLang}) }}
                  </td>
                  <td style="border-right: 1px solid black" width="20%">
                    {{ t("Sex", {}, {locale: $store.getters.getLang}) }}
                  </td>
                  <td width="40%">{{ t("Mobile", {}, {locale: $store.getters.getLang}) }}</td>
                </tr>
              </table>
            </td>

            <td style="border-right: 1px solid black; text-align: center">
              {{ t("Boarding", {}, {locale: $store.getters.getLang}) }}
            </td>
            <td style="border-right: 1px solid black; text-align: center">
              {{ t("Dropping", {}, {locale: $store.getters.getLang}) }}
            </td>

            <td
                colspan="3"
                style="
                border-right: 1px solid black;
                text-align: center;
                padding: 0 2px;
              "
            >
              {{ t("Price", {}, {locale: $store.getters.getLang}) }}
            </td>
            <td
                style="
                border-right: 1px solid black;
                text-align: center;
                padding: 0 2px;
              "
            >
              {{ t("Discount", {}, {locale: $store.getters.getLang}) }}
            </td>
            <td
                style="
                border-right: 1px solid black;
                text-align: center;
                padding: 0 2px;
              "
            >
              {{ t("Final_Amount", {}, {locale: $store.getters.getLang}) }}
            </td>
          </tr>

          <tr
              style="border: 1px solid black; font-size: 12px"
              v-for="seat in seats"
              :key="seat.index"
          >
            <td
                colspan="2"
                style="
                border-right: 1px solid black;
                text-align: center;
                padding: 0 2px;
              "
            >
              {{ seat?.seat }}
            </td>
            <td
                style="
                border-right: 1px solid black;
                text-align: center;
                padding: 0 2px;
              "
                v-if="seat?.userType && seat?.userType === 'ONLINE'"
            >
              <!--              {{ seat?.issuedBy?.name }}-->
              ONLINE
              {{ seat?.isVerified ? '(' + seat?.issuedBy?.name + ')' : '(not verified)' }}
            </td>
            <td
                style="
                border-right: 1px solid black;
                text-align: center;
                padding: 0 2px;
              "
                v-else
            >
              {{ seat?.issuedBy?.name }}
<!--              {{ seat?.issuedBy ? seat?.issuedBy?.name : seat?.userType === 'ONLINE' ? seat?.userType : '' }}-->
            </td>
            <td
                style="
                border-right: 1px solid black;
                text-align: center;
                padding: 0 2px;
              "
            >
              {{ seat?.pnrCode }}
            </td>
            <td style="border-right: 1px solid black; text-align: center">
              <table
                  width="100%;"
                  style="border-collapse: collapse; text-align: center"
              >
                <tr>
                  <td style="border-right: 1px solid black" width="40%">
                    {{ seat?.passengerName }}
                  </td>
                  <td style="border-right: 1px solid black" width="20%">
                    {{ seat?.passengerGender === "Male" ? "M" : "F" }}
                  </td>
                  <td width="40%">{{ seat?.passengerPhone }}</td>
                </tr>
              </table>
            </td>
            <td
                style="
                border-right: 1px solid black;
                text-align: center;
                padding: 0 2px;
              "
            >
              {{ seat?.boardingPlace }}
            </td>
            <td
                style="
                border-right: 1px solid black;
                text-align: center;
                padding: 0 2px;
              "
            >
              {{ seat?.droppingPoint }}
            </td>

            <td
                colspan="3"
                style="
                border-right: 1px solid black;
                text-align: center;
                padding: 0 2px;
              "
            >
              {{ seat?.price }}
            </td>
            <td
                style="
                border-right: 1px solid black;
                text-align: center;
                padding: 0 2px;
              "
            >
              {{ seat?.discount }}
            </td>
            <td
                style="
                border-right: 1px solid black;
                text-align: center;
                padding: 0 2px;
              "
            >
              {{ seat?.price - seat?.discount }}
            </td>
          </tr>

          <tr>
            <td
                colspan="11"
                style="
                border: 1px solid black;
                padding: 0 5px;
                padding: 5px;
                font-weight: bold;
              "
            >
              {{ t("SubTotal", {}, {locale: $store.getters.getLang}) }}
            </td>
            <td
                style="
                border-right: 1px solid black;
                border-bottom: 1px solid black;
                padding: 0 5px;
                text-align: center;
                padding: 5px;
                font-weight: bold;
              "
            >
              {{ subTotal }}
            </td>
          </tr>

          <tr style="height: 30px" v-if="freeSeats.length !== 0">
            <td colspan="14" style="border: 1px solid black"></td>
          </tr>

          <tr
              style="
              border: 1px solid black;
              background-color: black;
              color: white;
              font-weight: bold;
            "
              v-if="freeSeats.length !== 0"
          >
            <td colspan="12" style="border: 1px solid black">
              <h5 style="text-align: center; padding: 5px; margin-bottom: 0">
                {{ t("Free_Seats", {}, {locale: $store.getters.getLang}) }}
              </h5>
            </td>
          </tr>

          <tr style="border: 1px solid black" v-if="freeSeats.length !== 0">
            <td colspan="12">
              <table
                  style="
                  width: 100%;
                  border-collapse: collapse;
                  border-right: 1px solid black;
                "
              >
                <tr
                    v-for="value in freeSeats"
                    :key="value[0]"
                    style="text-align: center; padding: 10px 15px"
                >
                  <td
                      v-for="seat in value"
                      :key="seat.index"
                      style="
                      border-right: 1px solid black;
                      border-bottom: 1px solid black;
                    "
                  >
                    {{ seat }}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr
              style="height: 30px"
              v-if="
              $store.getters.getChalanReportDetails.countermanSeatReport &&
              countermanSeatReportData.fare !== 0
            "
          >
            <td colspan="14" style="border: 1px solid black"></td>
          </tr>

          <tr
              style="
              border: 1px solid black;
              background-color: black;
              color: white;
              font-weight: bold;
            "
              v-if="
              $store.getters.getChalanReportDetails.countermanSeatReport &&
              countermanSeatReportData.fare !== 0
            "
          >
            <td colspan="12">
              <h5 style="text-align: center; padding: 5px; margin-bottom: 0">
                {{ t("Counter_Wise_Seat_Report", {}, {locale: $store.getters.getLang}) }}
              </h5>
            </td>
          </tr>

          <tr
              style="border: 1px solid black"
              v-if="
              $store.getters.getChalanReportDetails.countermanSeatReport &&
              countermanSeatReportData.fare !== 0
            "
          >
            <td colspan="12">
              <table width="100%" style="border-collapse: collapse">
                <tr>
                  <th
                      style="
                      text-align: center;
                      border-right: 1px solid black;
                      font-weight: 600;
                    "
                  >
                    {{ t("Counter", {}, {locale: $store.getters.getLang}) }}
                  </th>
                  <th
                      style="
                      text-align: center;
                      border-right: 1px solid black;
                      font-weight: 600;
                    "
                  >
                    {{ t("User_Name", {}, {locale: $store.getters.getLang}) }}
                  </th>
                  <th
                      style="
                      text-align: center;
                      border-right: 1px solid black;
                      font-weight: 600;
                    "
                  >
                    {{ t("Sold_Seats", {}, {locale: $store.getters.getLang}) }}
                  </th>
                  <td
                      style="
                      text-align: center;
                      border-right: 1px solid black;
                      font-weight: 600;
                    "
                  >
                    {{ t("Fare", {}, {locale: $store.getters.getLang}) }}
                  </td>
                  <th
                      style="
                      text-align: center;
                      border-right: 1px solid black;
                      font-weight: 600;
                    "
                  >
                    {{ t("Receive_Amount", {}, {locale: $store.getters.getLang}) }}
                  </th>
                  <th
                      style="
                      text-align: center;
                      border-right: 1px solid black;
                      font-weight: 600;
                    "
                  >
                    {{ t("Discount", {}, {locale: $store.getters.getLang}) }}
                  </th>

                  <th
                      style="
                      text-align: center;
                      border-right: 1px solid black;
                      font-weight: 600;
                    "
                  >
                    {{ t("Commission", {}, {locale: $store.getters.getLang}) }}
                  </th>
                  <th
                      style="
                      text-align: center;
                      font-weight: 600;
                      border-right: 1px solid black;
                    "
                  >
                    {{ t("Final_Amount", {}, {locale: $store.getters.getLang}) }}
                  </th>
                </tr>

                <tr
                    v-for="item of Object.values(
                    $store.getters.getChalanReportDetails.countermanSeatReport
                  )"
                    :key="item"
                >
                  <td
                      style="
                      text-align: center;
                      border-right: 1px solid black;
                      border-top: 1px solid black;
                    "
                  >
                    {{ item?.counter }}
                  </td>
                  <td
                      style="
                      text-align: center;
                      border-right: 1px solid black;
                      border-top: 1px solid black;
                    "
                  >
                    {{ item?.name }}
                  </td>
                  <td
                      style="
                      text-align: center;
                      border-right: 1px solid black;
                      border-top: 1px solid black;
                    "
                  >
                    {{ item?.soldSeats }}
                  </td>
                  <td
                      style="
                      text-align: center;
                      border-right: 1px solid black;
                      border-top: 1px solid black;
                    "
                  >
                    {{ item?.fare }}
                  </td>
                  <td
                      style="
                      text-align: center;
                      border-right: 1px solid black;
                      border-top: 1px solid black;
                    "
                  >
                    {{ item?.receiveAmount }}
                  </td>
                  <td
                      style="
                      text-align: center;
                      border-right: 1px solid black;
                      border-top: 1px solid black;
                    "
                  >
                    {{ item?.discount }}
                  </td>

                  <td
                      style="
                      text-align: center;
                      border-right: 1px solid black;
                      border-top: 1px solid black;
                    "
                  >
                    {{ item?.commission }}
                  </td>
                  <td
                      style="
                      text-align: center;
                      border-right: 1px solid black;
                      border-top: 1px solid black;
                    "
                  >
                    {{
                      item?.receiveAmount - (item?.discount + item?.commission)
                    }}
                  </td>
                </tr>

                <tr style="font-weight: bold">
                  <td
                      colspan="2"
                      style="
                      border-right: 1px solid black;
                      border-top: 1px solid black;
                      padding: 5px;
                    "
                  >
                    {{ t("Total", {}, {locale: $store.getters.getLang}) }}
                  </td>

                  <td
                      style="
                      text-align: center;
                      border-right: 1px solid black;
                      border-top: 1px solid black;
                    "
                  >
                    {{ countermanSeatReportData.soldSeats }}
                  </td>
                  <td
                      style="
                      text-align: center;
                      border-right: 1px solid black;
                      border-top: 1px solid black;
                    "
                  >
                    {{ countermanSeatReportData.fare }}
                  </td>
                  <td
                      style="
                      text-align: center;
                      border-top: 1px solid black;
                      padding: 5px;
                      border-right: 1px solid black;
                    "
                  >
                    {{ countermanSeatReportData.receiveAmount }}
                  </td>
                  <td
                      style="
                      text-align: center;
                      border-right: 1px solid black;
                      border-top: 1px solid black;
                    "
                  >
                    {{ countermanSeatReportData.discount }}
                  </td>

                  <td
                      style="
                      text-align: center;
                      border-right: 1px solid black;
                      border-top: 1px solid black;
                    "
                  >
                    {{ countermanSeatReportData.commission }}
                  </td>
                  <td
                      style="
                      text-align: center;
                      border-right: 1px solid black;
                      border-top: 1px solid black;
                    "
                  >
                    {{ countermanSeatReportData.finalAmount }}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr
              style="height: 30px"
              v-if="
              $store.getters.getChalanReportDetails.countermanExtraReport &&
              countermanExtraReportData.fare !== 0
            "
          >
            <td colspan="14" style="border: 1px solid black"></td>
          </tr>

          <tr
              style="
              border: 1px solid black;
              background-color: black;
              color: white;
              font-weight: bold;
            "
              v-if="
              $store.getters.getChalanReportDetails.countermanExtraReport &&
              countermanExtraReportData.fare !== 0
            "
          >
            <td colspan="12">
              <h5 style="text-align: center; padding: 5px; margin-bottom: 0">
                {{ t("Extra_Ticket_Report", {}, {locale: $store.getters.getLang}) }}
              </h5>
            </td>
          </tr>

          <tr
              v-if="
              $store.getters.getChalanReportDetails.countermanExtraReport &&
              countermanExtraReportData.fare !== 0
            "
          >
            <td colspan="12">
              <table width="100%" style="border-collapse: collapse">
                <tr>
                  <td
                      style="
                      text-align: center;
                      border-right: 1px solid black;
                      font-weight: 600;
                    "
                  >
                    {{ t("Counter", {}, {locale: $store.getters.getLang}) }}
                  </td>
                  <td
                      style="
                      text-align: center;
                      border-right: 1px solid black;
                      font-weight: 600;
                    "
                  >
                    {{ t("User_Name", {}, {locale: $store.getters.getLang}) }}
                  </td>
                  <td
                      style="
                      text-align: center;
                      border-right: 1px solid black;
                      font-weight: 600;
                    "
                  >
                    {{ t("Sold_Seats", {}, {locale: $store.getters.getLang}) }}
                  </td>

                  <td
                      style="
                      text-align: center;
                      border-right: 1px solid black;
                      font-weight: 600;
                    "
                  >
                    {{ t("Fare", {}, {locale: $store.getters.getLang}) }}
                  </td>
                  <td style="text-align: center; font-weight: 600">
                    {{ t("Receive_Amount", {}, {locale: $store.getters.getLang}) }}
                  </td>
                  <td
                      style="
                      text-align: center;
                      border-right: 1px solid black;
                      font-weight: 600;
                    "
                  >
                    {{ t("Discount", {}, {locale: $store.getters.getLang}) }}
                  </td>
                  <td
                      style="
                      text-align: center;
                      border-right: 1px solid black;
                      font-weight: 600;
                    "
                  >
                    {{ t("Commission", {}, {locale: $store.getters.getLang}) }}
                  </td>
                  <td
                      style="
                      text-align: center;
                      border-right: 1px solid black;
                      font-weight: 600;
                    "
                  >
                    {{ t("Final_Amount", {}, {locale: $store.getters.getLang}) }}
                  </td>
                </tr>

                <tr
                    v-for="item of Object.values(
                    $store.getters.getChalanReportDetails.countermanExtraReport
                  )"
                    :key="item"
                >
                  <td
                      style="
                      text-align: center;
                      border-right: 1px solid black;
                      border-top: 1px solid black;
                    "
                  >
                    {{ item?.counter }}
                  </td>
                  <td
                      style="
                      text-align: center;
                      border-right: 1px solid black;
                      border-top: 1px solid black;
                    "
                  >
                    {{ item?.name }}
                  </td>
                  <td
                      style="
                      text-align: center;
                      border-right: 1px solid black;
                      border-top: 1px solid black;
                    "
                  >
                    {{ item?.soldSeats }}
                  </td>
                  <td
                      style="
                      text-align: center;
                      border-right: 1px solid black;
                      border-top: 1px solid black;
                    "
                  >
                    {{ item?.fare }}
                  </td>
                  <td
                      style="
                      text-align: center;
                      border-top: 1px solid black;
                      border-right: 1px solid black;
                    "
                  >
                    {{ item?.receiveAmount }}
                  </td>
                  <td
                      style="
                      text-align: center;
                      border-right: 1px solid black;
                      border-top: 1px solid black;
                    "
                  >
                    {{ item?.discount }}
                  </td>

                  <td
                      style="
                      text-align: center;
                      border-right: 1px solid black;
                      border-top: 1px solid black;
                    "
                  >
                    {{ item?.commission }}
                  </td>
                  <td
                      style="
                      text-align: center;
                      border-right: 1px solid black;
                      border-top: 1px solid black;
                    "
                  >
                    {{
                      item?.receiveAmount - (item?.discount + item?.commission)
                    }}
                  </td>
                </tr>

                <tr style="border-bottom: 1px solid black; font-weight: bold">
                  <td
                      colspan="2"
                      style="
                      border-right: 1px solid black;
                      border-top: 1px solid black;
                      padding: 5px;
                      font-weight: bold;
                    "
                  >
                    {{ t("Total", {}, {locale: $store.getters.getLang}) }}
                  </td>

                  <td
                      style="
                      text-align: center;
                      border-right: 1px solid black;
                      border-top: 1px solid black;
                    "
                  >
                    {{ countermanExtraReportData.soldSeats }}
                  </td>

                  <td
                      style="
                      text-align: center;
                      border-top: 1px solid black;
                      border-right: 1px solid black;
                    "
                  >
                    {{ countermanExtraReportData.fare }}
                  </td>
                  <td
                      style="
                      text-align: center;
                      border-top: 1px solid black;
                      border-right: 1px solid black;
                      padding: 5px;
                      font-weight: bold;
                    "
                  >
                    {{ countermanExtraReportData.receiveAmount }}
                  </td>
                  <td
                      style="
                      text-align: center;
                      border-right: 1px solid black;
                      border-top: 1px solid black;
                    "
                  >
                    {{ countermanExtraReportData.discount }}
                  </td>
                  <td
                      style="
                      text-align: center;
                      border-right: 1px solid black;
                      border-top: 1px solid black;
                    "
                  >
                    {{ countermanExtraReportData.commission }}
                  </td>
                  <td
                      style="
                      text-align: center;
                      border-right: 1px solid black;
                      border-top: 1px solid black;
                    "
                  >
                    {{ countermanExtraReportData.finalAmount }}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <!-- <tr
            style="height: 30px"
            v-if="
              $store.getters.getChalanReportDetails.countermanGoodsReport &&
              countermanGoodsReportData.fare !== 0
            "
          >
            <td colspan="14" style="border: 1px solid black"></td>
          </tr> -->

          <!-- <tr
            style="
              border: 1px solid black;
              background-color: black;
              color: white;
              font-weight: bold;
            "
            v-if="
              $store.getters.getChalanReportDetails.countermanGoodsReport &&
              countermanGoodsReportData.fare !== 0
            "
          >
            <td colspan="12">
              <h5 style="text-align: center; padding: 5px; margin-bottom: 0">
                Goods Report
              </h5>
            </td>
          </tr> -->

          <!-- <tr
            style="border: 1px solid black"
            v-if="
              $store.getters.getChalanReportDetails.countermanGoodsReport &&
              countermanGoodsReportData.fare !== 0
            "
          >
            <td colspan="12">
              <table width="100%" style="border-collapse: collapse">
                <tr>
                  <td
                    style="
                      text-align: center;
                      border-right: 1px solid black;
                      font-weight: 600;
                    "
                  >
                    Counter
                  </td>
                  <td
                    style="
                      text-align: center;
                      border-right: 1px solid black;
                      font-weight: 600;
                    "
                  >
                    User Name
                  </td>
                  <td
                    style="
                      text-align: center;
                      border-right: 1px solid black;
                      font-weight: 600;
                    "
                  >
                    Sold Seats
                  </td>
                  <td
                    style="
                      text-align: center;
                      border-right: 1px solid black;
                      font-weight: 600;
                    "
                  >
                    Fare
                  </td>
                  <td
                    style="
                      text-align: center;
                      font-weight: 600;
                      border-right: 1px solid black;
                    "
                  >
                    Receive Amount
                  </td>
                  <td
                    style="
                      text-align: center;
                      border-right: 1px solid black;
                      font-weight: 600;
                    "
                  >
                    Discount
                  </td>

                  <td
                    style="
                      text-align: center;
                      border-right: 1px solid black;
                      font-weight: 600;
                    "
                  >
                    Commission
                  </td>
                  <td
                    style="
                      text-align: center;
                      border-right: 1px solid black;
                      font-weight: 600;
                    "
                  >
                    final Amount
                  </td>
                </tr>

                <tr
                  v-for="item of Object.values(
                    $store.getters.getChalanReportDetails.countermanGoodsReport
                  )"
                  :key="item"
                >
                  <td
                    style="
                      text-align: center;
                      border-right: 1px solid black;
                      border-top: 1px solid black;
                    "
                  >
                    {{ item?.counter }}
                  </td>
                  <td
                    style="
                      text-align: center;
                      border-right: 1px solid black;
                      border-top: 1px solid black;
                    "
                  >
                    {{ item?.name }}
                  </td>
                  <td
                    style="
                      text-align: center;
                      border-right: 1px solid black;
                      border-top: 1px solid black;
                    "
                  >
                    {{ item?.soldSeats }}
                  </td>
                  <td
                    style="
                      text-align: center;
                      border-right: 1px solid black;
                      border-top: 1px solid black;
                    "
                  >
                    {{ item?.fare }}
                  </td>
                  <td
                    style="
                      text-align: center;
                      border-top: 1px solid black;
                      border-right: 1px solid black;
                    "
                  >
                    {{ item?.receiveAmount }}
                  </td>
                  <td
                    style="
                      text-align: center;
                      border-right: 1px solid black;
                      border-top: 1px solid black;
                    "
                  >
                    {{ item?.discount }}
                  </td>

                  <td
                    style="
                      text-align: center;
                      border-right: 1px solid black;
                      border-top: 1px solid black;
                    "
                  >
                    {{ item?.commission }}
                  </td>
                  <td
                    style="
                      text-align: center;
                      border-right: 1px solid black;
                      border-top: 1px solid black;
                    "
                  >
                    {{
                      item?.receiveAmount - (item?.discount + item?.commission)
                    }}
                  </td>
                </tr>

                <tr style="font-weight: bold">
                  <td
                    colspan="2"
                    style="
                      border-right: 1px solid black;
                      border-top: 1px solid black;
                      padding: 5px;
                    "
                  >
                    Total
                  </td>

                  <td
                    style="
                      text-align: center;
                      border-right: 1px solid black;
                      border-top: 1px solid black;
                    "
                  >
                    {{ countermanGoodsReportData.soldSeats }}
                  </td>
                  <td
                    style="
                      text-align: center;
                      border-right: 1px solid black;
                      border-top: 1px solid black;
                    "
                  >
                    {{ countermanGoodsReportData.fare }}
                  </td>
                  <td
                    style="
                      text-align: center;
                      border-top: 1px solid black;
                      border-right: 1px solid black;
                      padding: 5px;
                      font-weight: bold;
                    "
                  >
                    {{ countermanGoodsReportData.receiveAmount }}
                  </td>
                  <td
                    style="
                      text-align: center;
                      border-right: 1px solid black;
                      border-top: 1px solid black;
                    "
                  >
                    {{ countermanGoodsReportData.discount }}
                  </td>

                  <td
                    style="
                      text-align: center;
                      border-right: 1px solid black;
                      border-top: 1px solid black;
                    "
                  >
                    {{ countermanGoodsReportData.commission }}
                  </td>

                  <td
                    style="
                      text-align: center;
                      border-top: 1px solid black;
                      border-right: 1px solid black;
                      padding: 5px;
                      font-weight: bold;
                    "
                  >
                    {{ countermanGoodsReportData.finalAmount }}
                  </td>
                </tr>
              </table>
            </td>
          </tr> -->

          <tr v-if="goodsTicket && goodsTicket.length > 0">
            <!-- <td>{{goodsTicket}}</td> -->
            <td colspan="12">
              <table style="width: 100%; border-collapse: collapse">
                <tr style="height: 30px">
                  <td colspan="14" style="border: 1px solid black"></td>
                </tr>

                <tr
                    style="
                    border: 1px solid black;
                    background-color: black;
                    color: white;
                    font-weight: bold;
                  "
                >
                  <td colspan="12" style="border: 1px solid black">
                    <h5
                        style="text-align: center; padding: 5px; margin-bottom: 0"
                    >
                      {{ t("Goods_Ticket_Details", {}, {locale: $store.getters.getLang}) }}
                    </h5>
                  </td>
                </tr>

                <tr
                    style="
                    border: 1px solid black;
                    font-size: 14px;
                    font-weight: bold;
                  "
                >
                  <td
                      colspan="2"
                      style="
                      border-right: 1px solid black;
                      text-align: center;
                      padding: 0 2px;
                    "
                  >
                    {{ t("Goods Id", {}, {locale: $store.getters.getLang}) }}
                  </td>
                  <td
                      colspan="2"
                      style="
                      border-right: 1px solid black;
                      text-align: center;
                      padding: 0 2px;
                    "
                  >
                    {{ t("Issued By", {}, {locale: $store.getters.getLang}) }}
                  </td>

                  <td style="border-right: 1px solid black; text-align: center">
                    <table width="100%" style="border-collapse: collapse">
                      <th
                          style="
                          text-align: center;
                          border-bottom: 1px solid black;
                        "
                          colspan="3"
                      >
                        {{ t("Customer_info", {}, {locale: $store.getters.getLang}) }}
                      </th>
                      <tr width="100%">
                        <td width="50%" style="border-right: 1px solid black">
                          {{ t("Name", {}, {locale: $store.getters.getLang}) }}
                        </td>
                        <td width="50%">{{ t("Mobile", {}, {locale: $store.getters.getLang}) }}</td>
                      </tr>
                    </table>
                  </td>

                  <td style="border-right: 1px solid black; text-align: center">
                    {{ t("Boarding", {}, {locale: $store.getters.getLang}) }}
                  </td>
                  <td style="border-right: 1px solid black; text-align: center">
                    {{ t("Dropping", {}, {locale: $store.getters.getLang}) }}
                  </td>
                  <td
                      colspan="3"
                      style="
                      border-right: 1px solid black;
                      text-align: center;
                      padding: 0 2px;
                    "
                  >
                    {{ t("Weight", {}, {locale: $store.getters.getLang}) }}
                  </td>
                  <td
                      colspan="3"
                      style="
                      border-right: 1px solid black;
                      text-align: center;
                      padding: 0 2px;
                    "
                  >
                    {{ t("Charge", {}, {locale: $store.getters.getLang}) }}
                  </td>
                </tr>

                <tr
                    style="border: 1px solid black; font-size: 12px"
                    v-for="item in goodsTicket"
                    :key="item.index"
                >
                  <td
                      colspan="2"
                      style="
                      border-right: 1px solid black;
                      text-align: center;
                      padding: 0 2px;
                    "
                  >
                    {{ item?.goodsId }}
                  </td>
                  <td
                      colspan="2"
                      style="
                      border-right: 1px solid black;
                      text-align: center;
                      padding: 0 2px;
                    "
                  >
                    {{ item?.issuedBy?.name }}
                  </td>
                  <td
                      style="
                      border-right: 1px solid black;
                      text-align: center;
                      padding: 0 2px;
                    "
                  >
                    <table width="100%">
                      <tr>
                        <td width="50%" style="border-right: 1px solid black">
                          {{ item?.passengerName }}
                        </td>

                        <td width="50%">{{ item?.passengerPhone }}</td>
                      </tr>
                    </table>
                  </td>
                  <td
                      style="
                      border-right: 1px solid black;
                      text-align: center;
                      padding: 0 2px;
                    "
                  >
                    {{ item?.boardingPlace }}
                  </td>
                  <td
                      style="
                      border-right: 1px solid black;
                      text-align: center;
                      padding: 0 2px;
                    "
                  >
                    {{ item?.droppingPoint }}
                  </td>
                  <td
                      colspan="3"
                      style="
                      border-right: 1px solid black;
                      text-align: center;
                      padding: 0 2px;
                    "
                  >
                    {{ item?.goodsWeight }}
                  </td>
                  <td
                      colspan="2"
                      style="
                      border-right: 1px solid black;
                      text-align: center;
                      padding: 0 2px;
                    "
                  >
                    {{ item?.price }}
                  </td>
                </tr>
                <tr style="border: 1px solid black; font-size: 12px">
                  <td
                      colspan="10"
                      style="
                      border-right: 1px solid black;
                      text-align: center;
                      padding: 0 2px;
                    "
                  >
                    <div
                        style="
                        text-align: start;
                        font-weight: bold;
                        padding: 5px;
                        font-size: 16px;
                      "
                    >
                      {{ t("Total", {}, {locale: $store.getters.getLang}) }}
                    </div>
                  </td>
                  <td
                      colspan="2"
                      style="
                      border-right: 1px solid black;
                      text-align: center;
                      padding: 0 2px;
                    "
                  >
                    <div
                        style="
                        text-align: center;
                        padding: 5px;
                        font-weight: bold;
                        font-size: 16px;
                      "
                    >
                      {{ goodsTicketPrice }}
                    </div>
                  </td>
                </tr>
              </table>
            </td>
          </tr>

          <tr>
            <td colspan="14">
              <div class="p-3" style="text-align: center">
                <!-- <div style="display: flex; justify-content: space-around"> -->
                <h5 style="padding: 5px">{{ t("Balance", {}, {locale: $store.getters.getLang}) }}</h5>
                <!-- </div> -->
                <!-- <div style="text-align: center"> -->
                <h6 style="font-weight: bold; color: green">
                  {{ t("Received_Amount_Total", {}, {locale: $store.getters.getLang}) }}: {{ receivedAmount }}
                  {{ t("Taka", {}, {locale: $store.getters.getLang}) }}
                </h6>
                <!-- </div> -->
              </div>
              <!-- <table width="100%">
                <tr>
                  <td>
                    <table width="100%" style="border: 1px solid black;">
                      <tr>
                        <td
                          style="border-right: 1px solid black; text-align: center;"
                        >
                          Receive Amount
                        </td>
                        <td style="text-align: center;">
                          1860.00
                        </td>
                      </tr>
                      <tr>
                        <td
                          style="border-right: 1px solid black; border-top: 1px solid black; text-align: center;"
                        >
                          Goods/Luggage
                        </td>
                        <td
                          style="border-top: 1px solid black; text-align: center;"
                        >
                          0.00
                        </td>
                      </tr>
                      <tr>
                        <td
                          style="border-right: 1px solid black; border-top: 1px solid black; text-align: center;"
                        >
                          Expenses
                        </td>
                        <td
                          style="border-top: 1px solid black; text-align: center;"
                        >
                          0.00
                        </td>
                      </tr>

                      <tr>
                        <td
                          style="border-right: 1px solid black; border-top: 1px solid black; text-align: center;"
                        >
                          Balance
                        </td>
                        <td
                          style="border-top: 1px solid black; text-align: center;"
                        >
                          11850.00
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table> -->
            </td>
          </tr>

          <tr style="border: 1px solid black">
            <td colspan="12" style="border: 1px solid black">
              <div
                  class="mx-2"
                  style="
                  display: flex;
                  justify-content: space-between;
                  margin-top: 30px;
                  line-height: 5px;
                "
              >
                <div class="mt-5">
                  <div
                      style="width: 100px; height: 1px; background-color: black"
                  ></div>
                  <h4 style="margin-top: 5px"> {{ t("Signature", {}, {locale: $store.getters.getLang}) }}</h4>
                  <p>({{ t("Counter_Master", {}, {locale: $store.getters.getLang}) }})</p>
                </div>

                <div class="mt-5">
                  <div
                      style="width: 100px; height: 1px; background-color: black"
                  ></div>
                  <h4 style="margin-top: 5px">{{ t("Signature", {}, {locale: $store.getters.getLang}) }}</h4>
                  <p>({{ t("Guide", {}, {locale: $store.getters.getLang}) }})</p>
                </div>

                <div class="mt-5">
                  <div
                      style="width: 100px; height: 1px; background-color: black"
                  ></div>
                  <h4 style="margin-top: 5px">{{ t("Signature", {}, {locale: $store.getters.getLang}) }}</h4>
                  <p>({{ t("Checker_1", {}, {locale: $store.getters.getLang}) }})</p>
                </div>

                <div class="mt-5">
                  <div
                      style="width: 100px; height: 1px; background-color: black"
                  ></div>
                  <h4 style="margin-top: 5px">{{ t("Signature", {}, {locale: $store.getters.getLang}) }}</h4>
                  <p>({{ t("Checker_2", {}, {locale: $store.getters.getLang}) }})</p>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import {onBeforeMount, inject, ref, reactive} from "vue";
import moment from "moment";
import {useI18n} from "vue-i18n";

export default {
  props: {
    tripId: String,
  },
  setup(props, {emit}) {
    let tripID = ref(props.tripId);
    let countermanSeatReport = ref({});
    let seats = ref([]);
    let subTotal = ref(0);
    let goodsTicket = ref([]);
    let receivedAmount = ref(0);
    let goodsTicketPrice = ref(0);
    let freeSeats = ref([]);
    let countermanSeatReportData = reactive({
      soldSeats: 0,
      discount: 0,
      fare: 0,
      commission: 0,
      receiveAmount: 0,
      finalAmount: 0,
    });
    let countermanExtraReport = ref({});
    let countermanExtraReportData = reactive({
      soldSeats: 0,
      discount: 0,
      fare: 0,
      commission: 0,
      receiveAmount: 0,
      finalAmount: 0,
    });
    let countermanGoodsReport = ref({});
    let countermanGoodsReportData = reactive({
      soldSeats: 0,
      discount: 0,
      fare: 0,
      commission: 0,
      receiveAmount: 0,
      finalAmount: 0,
    });
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const {t, locale} = useI18n();
    const goBack = () => {
      emit("backToChalanWiseReport", true);
    };

    const dateFormat = (date) => {
      return moment(new Date(String(date)))
          .utcOffset(0, false)
          .format("DD-MMM-yyyy hh:mm:ss A");
    };
    const dataCalculate = () => {
      freeSeats.value = store.getters.getChalanReportDetails.freeSeats;

      const newArr = [];
      while (freeSeats.value.length) newArr.push(freeSeats.value.splice(0, 20));
      freeSeats.value = newArr;

      countermanSeatReport.value = Object.entries(
          store.getters.getChalanReportDetails.countermanSeatReport
      );
      countermanSeatReport.value.forEach((item) => {
        countermanSeatReportData.soldSeats += item[1]?.soldSeats;
        countermanSeatReportData.discount += item[1]?.discount;
        countermanSeatReportData.fare += item[1]?.fare;
        countermanSeatReportData.commission += item[1]?.commission;
        countermanSeatReportData.receiveAmount += item[1]?.receiveAmount;
      });

      countermanSeatReportData.finalAmount =
          countermanSeatReportData.receiveAmount -
          (countermanSeatReportData.discount +
              countermanSeatReportData.commission);

      countermanExtraReport.value = Object.entries(
          store.getters.getChalanReportDetails.countermanExtraReport
      );
      countermanExtraReport.value.forEach((item) => {
        countermanExtraReportData.soldSeats += item[1]?.soldSeats;
        countermanExtraReportData.discount += item[1]?.discount;
        countermanExtraReportData.fare += item[1]?.fare;
        countermanExtraReportData.commission += item[1]?.commission;
        countermanExtraReportData.receiveAmount += item[1]?.receiveAmount;
      });

      countermanExtraReportData.finalAmount =
          countermanExtraReportData.receiveAmount -
          (countermanExtraReportData.discount +
              countermanExtraReportData.commission);

      countermanGoodsReport.value = Object.entries(
          store.getters.getChalanReportDetails.countermanGoodsReport
      );
      countermanGoodsReport.value.forEach((item) => {
        countermanGoodsReportData.soldSeats += item[1]?.soldSeats;
        countermanGoodsReportData.discount += item[1]?.discount;
        countermanGoodsReportData.fare += item[1]?.fare;
        countermanGoodsReportData.commission += item[1]?.commission;
        countermanGoodsReportData.receiveAmount += item[1]?.receiveAmount;
      });

      countermanGoodsReportData.finalAmount =
          countermanGoodsReportData.receiveAmount -
          (countermanGoodsReportData.discount +
              countermanGoodsReportData.commission);

      Object.keys(store.getters.getChalanReportDetails.seatTickets).forEach(
          (key) => {
            store.getters.getChalanReportDetails.seatTickets[key].forEach(
                (seat) => {
                  seats.value.push(seat);
                }
            );
          }
      );

      // sorted by seat (string value)
      seats.value.sort((i1, i2) => {
        var m = i1.seat.toLowerCase();
        var n = i2.seat.toLowerCase();
        return m < n ? -1 : m > n ? 1 : 0;
      });

      seats.value.forEach((price) => {
        subTotal.value += price.price - price.discount;
      });

      goodsTicket.value = store.getters.getChalanReportDetails.goodsTickets;

      goodsTicket.value.forEach((price) => {
        goodsTicketPrice.value += price.price;
      });

      receivedAmount.value =
          countermanSeatReportData.finalAmount +
          countermanExtraReportData.finalAmount +
          countermanGoodsReportData.finalAmount;
    };

    const print = () => {
      let divContents =
          window.document.getElementById("chalanReport").innerHTML;
      var printWindow = window.open();
      var is_chrome = Boolean(window.chrome);
      printWindow.document.write("<html>");
      printWindow.document.write("<head>");
      printWindow.document.write(`<style>
							table {
								page-break-after: auto;
							}
							tr {
								page-break-inside: avoid;
								page-break-after: auto;
							}
							td {
								page-break-inside: avoid;
								page-break-after: auto;
							}
							thead {
								display: table-header-group;
							}
							tfoot {
								display: table-footer-group;
							}
							@media print {
								* {
									-webkit-print-color-adjust: exact !important;
									color-adjust: exact !important;
								}
								@page {
									size: auto;
									margin: 0 20mm 0 20mm;
									padding: 0;
								}

								body {
									padding: 0;
									margin: 0;
									-webkit-print-color-adjust: exact;
								}
							}
							</style>`);
      printWindow.document.write("</head>");
      printWindow.document.write("<body>");
      printWindow.document.write(divContents);
      printWindow.document.write("</body></html>");

      printWindow.document.close();
      printWindow.focus();
      if (is_chrome) {
        printWindow.onload = function () {
          setTimeout(function () { // wait until all resources loaded
            printWindow.print();  // change window to winPrint
            printWindow.close();// change window to winPrint
          }, 500);
        };
      } else {
        printWindow.print()
        printWindow.close()
      }
      // printWindow.print();
      // printWindow.close();
    };

    const getChalanDetails = () => {
      showLoader();
      store
          .dispatch("getChalanDetails", {tripId: tripID.value})
          .then(() => {
            dataCalculate();
            hideLoader();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              getChalanDetails();
            }
          });
    };

    onBeforeMount(() => {
      getChalanDetails();
    });
    return {
      t,
      locale,
      goBack,
      dateFormat,
      countermanSeatReportData,
      countermanExtraReportData,
      countermanGoodsReportData,
      seats,
      subTotal,
      goodsTicket,
      receivedAmount,
      goodsTicketPrice,
      print,
      freeSeats,
    };
  },
};
</script>

<style scoped>
table {
  page-break-after: auto;
}

tr {
  page-break-inside: avoid;
  page-break-after: auto;
}

td {
  page-break-inside: avoid;
  page-break-after: auto;
}

thead {
  display: table-header-group;
}

tfoot {
  display: table-footer-group;
}

@media print {
  * {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }

  @page {
    /* size: A4; */
    size: auto;
    margin: 0 20mm 0 20mm;
    padding: 0;
  }

  body {
    padding: 0;
    margin: 0;
    -webkit-print-color-adjust: exact;
  }
}
</style>
