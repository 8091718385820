<template>
  <Modal @close="closeModal">
    <template v-slot:title>{{
        t(title, {}, {locale: $store.getters.getLang})
      }}
    </template>
    <template v-slot:body>
      <form>
        <!-- <div class="form-group row my-1">
          <ModalInput
            v-model="routeData.name"
            v-bind:placeholder="'Enter Name'"
            v-bind:label="'Name'"
            v-bind:id="'name'"
            v-bind:type="'text'"
          >
            <template v-slot:error>
              <ModalError v-if="errors.name" v-bind:error="errors.name" />
            </template>
          </ModalInput>
        </div> -->
        <div class="form-group row my-1">
          <ModalDropdown
              v-if="$store.getters.getStoppagesOwner"
              v-bind:id="'fromStoppageId'"
              v-model="routeData.fromStoppageId"
              v-bind:defaultOption="'--select stoppage--'"
              v-bind:defaultValue="''"
              v-bind:dropdown="$store.getters.getStoppagesOwner"
              v-bind:label="'From Stoppage'"
          >
            <template v-slot:error>
              <ModalError
                  v-if="errors.fromStoppageId"
                  v-bind:error="errors.fromStoppageId"
              />
            </template>
          </ModalDropdown>
        </div>
        <div class="form-group row my-1">
          <ModalDropdown
              v-if="$store.getters.getStoppagesOwner"
              v-bind:id="'toStoppageId'"
              v-model="routeData.toStoppageId"
              v-bind:defaultOption="'--select stoppage--'"
              v-bind:defaultValue="''"
              v-bind:dropdown="$store.getters.getStoppagesOwner"
              v-bind:label="'To Stoppage'"
          >
            <template v-slot:error>
              <ModalError
                  v-if="errors.toStoppageId"
                  v-bind:error="errors.toStoppageId"
              />
            </template>
          </ModalDropdown>
        </div>
        <div class="form-group row my-1">
          <ModalInput
              v-bind:id="'routename'"
              v-model="routeData.name"
              v-bind:label="'Name'"
          ></ModalInput>
        </div>
        <div class="form-group row my-1">
          <ModalDropdown
              v-if="direction"
              v-bind:id="'direction'"
              v-model="routeData.direction"
              v-bind:defaultOption="'--select direction--'"
              v-bind:defaultValue="''"
              v-bind:dropdown="direction"
              v-bind:label="'Direction'"
          >
            <template v-slot:error>
              <ModalError
                  v-if="errors.direction"
                  v-bind:error="errors.direction"
              />
            </template
            >
          </ModalDropdown>
        </div>
        <div class="form-group row my-1">
          <ModalDropdown
              v-if="status"
              v-bind:id="'status'"
              v-model="routeData.status"
              v-bind:dropdown="status"
              v-bind:label="'Status'"
          ></ModalDropdown>
        </div>
        <div class="form-group row my-1">
          <div class="col-sm-3 col-form-label font_detail">
            <label class="label_font">{{
                t("routeSequence", {}, {locale: $store.getters.getLang})
              }}</label>
          </div>
          <div class="col-sm-9">
            <Multiselect
                id="multiselectId"
                v-model="routeData.routeSequence"
                :createTag="true"
                :options="$store.getters.getStoppagesOwner"
                :searchable="true"
                class="col font_detail w-100"
                mode="tags"
                placeholder="Select Route Sequence"
            />
            <Error
                v-if="errors.routeSequence"
                v-bind:error="errors.routeSequence"
            />
          </div>
        </div>

        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <!-- <input
              v-if="!routesId"
              type="submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="addRoute"
            />
            <input
              v-else
              type="submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="updateRoute"
            /> -->

            <button
                v-if="!routesId"
                class="mt-3 py-2 submit_button font_detail float-end"
                @click.prevent="addRoute"
            >
              {{ t("Submit", {}, {locale: $store.getters.getLang}) }}
            </button>
            <button
                v-else
                class="mt-3 py-2 submit_button font_detail float-end"
                @click.prevent="updateRoute"
            >
              {{ t("Submit", {}, {locale: $store.getters.getLang}) }}
            </button>
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import dropdownVariables from "@/modules/dropdownVariables";
import useInputValidation from "@/modules/useInputValidations";
import {inject, onBeforeMount, ref, watch} from "vue";
import {reactive} from "@vue/reactivity";
import store from "@/store";
import {useRoute} from "vue-router";
import Multiselect from "@vueform/multiselect";
import {useI18n} from "vue-i18n";

export default {
  name: "RoutesAddUpdateModal",
  props: {
    route: String,
    company: String,
  },
  components: {
    Multiselect,
  },
  setup(props, {emit}) {
    const route = useRoute();
    let {requiredValidation, compareRouteSequenceValidation, errors} =
        useInputValidation();
    let routeData = reactive({
      id: "",
      companyId: route.query.id,
      name: "",
      fromStoppageId: "",
      toStoppageId: "",
      status: 1,
      direction: "",
      routeSequence: [],
    });
    const routesId = ref(props.route);
    const {t, locale} = useI18n();
    // const companyId = ref(props.company);
    const title = ref("Add Route");
    const status = dropdownVariables.status;
    const direction = dropdownVariables.direction;
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    let allStoppage = ref({});
    let startPlace = ref("");
    let endPlace = ref("");

    let pagination = reactive({
      page: 1,
      limit: 25,
    });

    // watch(
    //   () => routeData.name,
    //   () => {
    //     requiredValidation("name", routeData.name);
    //   }
    // );
    watch(
        () => routeData.routeSequence,
        () => {
          compareRouteSequenceValidation(
              "routeSequence",
              routeData.fromStoppageId,
              routeData.toStoppageId,
              routeData.routeSequence[0],
              routeData.routeSequence.slice(-1)[0]
          );
        }
    );
    watch(
        () => routeData.fromStoppageId,
        () => {
          if (routeData.fromStoppageId && routeData.toStoppageId) {
            initRouteName()
          }
          requiredValidation("fromStoppageId", routeData.fromStoppageId);
        }
    );
    watch(
        () => routeData.toStoppageId,
        () => {
          if (routeData.fromStoppageId && routeData.toStoppageId) {
            initRouteName()
          }
          requiredValidation("toStoppageId", routeData.toStoppageId);
        }
    );
    watch(
        () => routeData.direction,
        () => {
          requiredValidation("direction", routeData.direction);
        }
    );

    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      // requiredValidation("name", routeData.name);
      requiredValidation("fromStoppageId", routeData.fromStoppageId);
      requiredValidation("toStoppageId", routeData.toStoppageId);
      requiredValidation("direction", routeData.direction);
      compareRouteSequenceValidation(
          "routeSequence",
          routeData.fromStoppageId,
          routeData.toStoppageId,
          routeData.routeSequence[0],
          routeData.routeSequence.slice(-1)[0]
      );
      return !(
          Object.values(errors).length <= 0 ||
          !Object.values(errors).every((x) => x === null || x === "")
      );
    };
    const closeModal = () => {
      emit("update:modelValue", false);
    };
    const initRouteName = () => {
      allStoppage.value = store.getters.getStoppagesOwner;
      for (let [key, value] of Object.entries(allStoppage.value)) {
        if (key === routeData.fromStoppageId) {
          startPlace.value = value;
        }
        if (key === routeData.toStoppageId) {
          endPlace.value = value;
        }
      }
      routeData.name = startPlace.value + "-" + endPlace.value;
      routeData.name.trim()
    }
    const addRoute = () => {
      if (routeData.routeSequence.length === 0) {
        routeData.routeSequence.push(routeData.fromStoppageId);
        routeData.routeSequence.push(routeData.toStoppageId);
      }
      if (validate()) {
        initRouteName()
        showLoader();
        store
            .dispatch("addOwnerServicesRoute", {routeData, pagination})
            .then(() => {
              hideLoader();
              closeModal();
            })
            .catch((error) => {
              hideLoader();
              if (error.status === 401) {
                addRoute();
              }
            });
      }
    };
    const updateRoute = () => {
      if (validate()) {
        showLoader();
        store
            .dispatch("updateOwnerServicesRoute", {routeData, pagination})
            .then(() => {
              hideLoader();
              closeModal();
            })
            .catch((error) => {
              hideLoader();
              if (error.status === 401) {
                updateRoute();
              }
            });
      }
    };

    onBeforeMount(() => {
      Object.keys(errors).forEach(function (key) {
        delete errors[key];
      });
      // Object.entries(props.stoppageList).forEach(el => {
      //   stoppages[el[0]] = el[1];
      // });
      if (routesId.value !== "") {
        const routeDetails = store.getters.getOwnerServicesRoute(
            routesId.value
        );
        title.value = "Update Route";
        routeData.id = routeDetails[0]._id;
        routeData.fromStoppageId = routeDetails[0].fromStoppageId;
        routeData.toStoppageId = routeDetails[0].toStoppageId;
        routeData.name = routeDetails[0].name;
        routeData.direction = routeDetails[0].direction;
        routeData.routeSequence = routeDetails[0].routeSequence;
        routeData.status = routeDetails[0].status ? 1 : 0;
      }
      // routeData.companyId = companyId.value;
    });

    return {
      t,
      locale,
      routesId,
      routeData,
      errors,
      status,
      title,
      direction,
      addRoute,
      updateRoute,
      closeModal,
    };
  },
};
</script>

<style scoped></style>
